import React from 'react'
import styled from 'styled-components'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { Center } from '../../../style/basic/commonStyle';

const localizer = momentLocalizer(moment);
export default function CalendarPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const eventStyleGetter = (event:any) =>{
        let style = {}
        if(selectItem?.item?.list){
            const item = selectItem?.item?.list.filter((data:any)=>data.title === event.title);
            if(item.length !== 0){
                style = item[0].style
            }
        }
        
        return {
            style:{
                ...style,
            }
        }
    }

    const CustomToolbar = (toolbar:any) => {
        return (
            <div>
                <Center>{moment(toolbar.date).format('YYYY MM')}</Center>
            </div>
        );
    };

    const MyDateHeader = ({label,...data}:any) =>{
        return(
            <Center style={{justifyContent:selectItem?.item?.dateStyle?.justifyContent}}>
                <button className="rbc-button-link" role="cell" style={{...selectItem?.item?.dateStyle, fontSize:`${selectItem?.item?.dateStyle?.fontSize ?? 16}px`, opacity: data.isOffRange ? 0.5 : 1}}>{label}</button>
            </Center>
        )
    }
    
    return (
        <Wrap className='no-item-drag'>
            <Calendar 
                localizer={localizer}
                views={['month']}
                events={selectItem?.item?.list ?? []}
                eventPropGetter={eventStyleGetter}
                components={{
                    toolbar: CustomToolbar,
                    month: {
                        dateHeader: MyDateHeader,
                    },
                }}
            ></Calendar>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;