import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import GalleryListItem from '../item/GalleryListItem';
import { LIST_ITEM } from './listData';
import _ from 'lodash';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function GalleryListPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setList([LIST_ITEM]);
        }else{
            setList(selectItem?.item?.list)
        }
    },[selectItem?.item?.list])
    
    return (
        <Wrap>
            <ContentWrap style={{...selectItem?.item?.listStyle}}>
                {list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <GalleryListItem 
                                key={`list2-${index}`} 
                                data={data} 
                                imgStyle={selectItem?.item?.imgStyle}
                                titleStyle={selectItem?.item?.titleStyle}
                                contentStyle={selectItem?.item?.contentStyle}
                                timeStyle={selectItem?.item?.timeStyle}
                            />
                        )
                    })
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    gap: 8px;
`;