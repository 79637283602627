import React from 'react'
import styled from 'styled-components'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { addTheme } from '../../../style/addTheme';

export default function SelectItem({value,setValue,children,margin,none}:any) {

    const handleChange = (e:SelectChangeEvent) =>{
        setValue(e.target.value);
    }
    
    return (
        <Wrap margin={margin}>
            <Select
                value={value}
                onChange={handleChange}
                displayEmpty
                size='small'
                sx={{
                    '.MuiSelect-select':{color:'#111',border:'1px solid #111'},
                    '.MuiInputBase-input':{padding:'4px 32px 4px 6px !important'}
                }}
                fullWidth

            >
                {none === undefined ? 
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>:
                    none
                }
                {children !== undefined && children.length !== 0 &&
                    children
                }
            </Select>
        </Wrap>
    )
}

const Wrap = styled.div<{margin?:string}>`
    /* & svg{
        color: #fff;
    } */
    margin: ${props => props.margin ?? 0};
    /* max-width: 280px;
    @media ${addTheme.media.m}{
        max-width: 100%;
    } */
`;
