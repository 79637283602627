import React, { useState } from 'react'
import styled from 'styled-components'
import { fileTypeRes } from '../../../utils/format/string';

const FILE_INIT = {file:null,preview:'',fileData:''};

export default function ImgFormItem({data,valChange}:any) {
    const [isActive,setIsActive] = useState(false);
    const [file,setFile] = useState<any>(FILE_INIT);
    const handleDragStart = () => setIsActive(true);
    const handleDragEnd = () => setIsActive(false);

    const handleDragOver = (event:any) => {
        event.preventDefault();  // 필수 1
    };

    const handleDrop = (event:any) =>{
        event.preventDefault();
        const fileItem = event.dataTransfer.files[0];
        if(fileItem){
            const fileType = fileTypeRes(fileItem.name);
            if(fileType === 'png' || fileType === 'jpg' || fileType === 'gif'){
                const preImg = URL.createObjectURL(fileItem);
                const reader = new FileReader();
                setFile((obj:any)=>({...obj,file:fileItem,preview:preImg}))
                reader.onload = () => {
                    const result = reader.result;
                    setFile((obj:any)=>({...obj,fileData:result}))
                };
    
                reader.readAsDataURL(fileItem);
            }else{
                alert('이미지를 선택해주세요.')
            }
        }
        setIsActive(false);
    }

    const fileChange = (e:any) =>{
        const fileItem = e.target.files[0];
        if(fileItem){
            const fileType = fileTypeRes(fileItem.name);
            if(fileType === 'png' || fileType === 'jpg' || fileType === 'gif'){
                const preImg = URL.createObjectURL(fileItem);
                const reader = new FileReader();
                setFile((obj:any)=>({...obj,file:fileItem,preview:preImg}))
                reader.onload = () => {
                    const result = reader.result;
                    setFile((obj:any)=>({...obj,fileData:result}))
                };
                reader.readAsDataURL(fileItem);
            }else{
                alert('이미지를 선택해주세요.')
            }
        }
        setIsActive(false);
    }
    
    return (
        <Wrap 
            isActive={isActive}
            onDragEnter={handleDragStart}
            onDragLeave={handleDragEnd}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {file?.preview === undefined || file?.preview === '' ?
                '파일을 올려주세요.':
                <img src={file.preview}></img>
            }
            <input type='file' accept='.png, .jpg, .gif' onChange={fileChange}></input>
        </Wrap>
    )
}

const Wrap = styled.label<{isActive:boolean}>`
    width: 150px;
    height: 150px;
    border: 1px dotted #000;
    background-color: ${({isActive}) => isActive ? '#bebebe' : '#fff'};
    display: flex;
    align-items: center;
    justify-content: center;
    & input{
        display: none;
    }
    & img{
        max-width: 100%;
        max-height: 100%;
    }
`;