import React from 'react'
import styled from 'styled-components'
import RectItem from '../../common/item/RectItem';
import { Center, Row } from '../../../style/basic/commonStyle';

export default function GalleryListItem({data,imgStyle,titleStyle,contentStyle,timeStyle}:any) {
    return (
        <Wrap className='no-drag'>
            <ImgWrap align={imgStyle?.justifyContent}>
                <ImgInnerWrap style={{...imgStyle,width:imgStyle?.width === undefined ? '100%' : `${imgStyle.width}%`}}>
                    <RectItem imgUrl={data.img === '' ? 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png' : data.img}></RectItem>
                </ImgInnerWrap>
            </ImgWrap>
            <TextWrap>
                <TitleItem style={{...titleStyle}}>{data.title ?? ''}</TitleItem>
                <ContentItem style={{...contentStyle}}>{data.content ?? ''}</ContentItem>
                <TimeItem style={{...timeStyle}}>{data.sub ?? ''}</TimeItem>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;
const ImgWrap = styled(Center)<{align?:string}>`
    justify-content: ${({align}) => align ?? 'center'};
`;
const ImgInnerWrap = styled(Center)`
    overflow: hidden;
`;
const TextWrap = styled(Row)`
    margin-top: 6px;
    flex-direction: column;
    gap: 6px;
    justify-content: start;
    align-items: start;
`;

const TitleItem = styled.div`
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    width: 100%;
`;

const ContentItem = styled.div`
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: #636363;
    width: 100%;
`;

const TimeItem = styled.div`
    font-size: 14px;
    width: 100%;
`;