import React from 'react'
import styled from 'styled-components'
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { Center } from '../../../style/basic/commonStyle';

export default function ImgItem({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    return (
        <Wrap>
            {selectItem?.item?.img === undefined || selectItem?.item?.img === '' ?
                <Center>img</Center> :
                <img src={selectItem?.item?.img} style={{...selectItem?.style[breakpoints ?? 'lg']}}></img>
            }
        </Wrap>
    )
}

const Wrap = styled(Center)`
    width: 100%;
    height: 100%;
    & img{
        width: 100%;
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
`;