import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import RectItem from '../../common/item/RectItem';

export default function WebzineListItem({data,titleStyle,imgStyle,contentStyle,timeStyle}:any) {
    return (
        <Wrap className='no-drag'>
            <ContentWrap>
                <ImgWrap style={{...imgStyle,width:imgStyle===undefined ? '30%': `${imgStyle.width}%`}}>
                    <RectItem imgUrl={data.img === '' ? 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png' : data.img} />
                </ImgWrap>
                <TextWrap>
                    <TitleItem style={{...titleStyle}}>{data.title ?? ''}</TitleItem>
                    <ContentItem style={{...contentStyle}}>{data.content ?? ''}</ContentItem>
                    <TimeItem style={{...timeStyle}}>{data.sub ?? ''}</TimeItem>
                </TextWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;
const ContentWrap =styled(Row)`
    gap: 10px;
    align-items: center;
`;
const ImgWrap = styled.div`
    width: 25%;
    overflow: hidden;
    flex-shrink: 0;
`;
const TextWrap = styled(Row)`
    flex-direction: column;
    gap: 6px;
    justify-content: start;
    align-items: start;
`;

const TitleItem = styled.div`
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
`;

const ContentItem = styled.div`
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: #636363;
`;

const TimeItem = styled.div`
    font-size: 14px;
`;