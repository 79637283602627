import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { MdOutlineFullscreen,MdOutlineFullscreenExit, MdMonitor, MdTabletMac, } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import AddListPart from '../part/AddListPart';
import SettingPart from '../part/SettingPart';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router';
import LayoutPart from '../part/LayoutPart';
import { ACCORDION, DUMMY_TABLE, DUMMY_TABLE02, DUMMY_TIMELINE, FOOTER_01_ITEMS, FOOTER_01_LAYOUT, HEADER_01_ITEMS, HEADER_01_LAYOUT, HEADER_02_ITEMS, HEADER_02_LAYOUT, MAP, TEMP_01_ITEM_LIST, TEMP_01_LAYOUT, TEMP_02_ITEM_LIST, TEMP_02_LAYOUT, WRAP1_ITEMS, WRAP1_LAYOUT } from '../../../data/layout';
import { apiGetTempDetail, apiSetTempSave } from '../../../api/api_temp';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useDispatch } from 'react-redux';
import { globalSelectItemResetCreator } from '../../../store/common/globalSelectItem';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import useAction from '../../../utils/custom-hooks/common/useAction';
import OpenTempSavePart from '../part/OpenTempSavePart';

const ONE_ACTION = [{name:"이미지",module:'update',type:'img'},{name:"텍스트",module:'update',type:'text'},{name:"비디오",module:'update',type:'video'},{name:"버튼",module:'update',type:'button'},];
const MULTI_ACTION = [{name:"배너",module:'update',type:'banner'},{name:"리스트1",module:'update',type:'list1'},{name:"리스트2",module:'update',type:'list2'},{name:"리스트3",module:'update',type:'list3'},{name:"리스트4",module:'update',type:'list4'},{name:'플레이어',module:'update',type:'player'},{name:'플레이어02',module:'update',type:'player02'},{name:'폼',module:'update',type:'form'},{name:'차트-line',module:'update',type:'chartLine'},{name:'차트-area',module:'update',type:'chartArea'},{name:'차트-bar',module:'update',type:'chartBar'},{name:'차트-pie',module:'update',type:'chartPie'},{name:'차트-radar',module:'update',type:'chartRadar'},{name:'countdown',module:'update',type:'countdown'},{name:'캘린더',module:'update',type:'calendar'},{name:'table01',module:'update',type:'table01',table:DUMMY_TABLE},{name:'table02',module:'update',type:'table02',table:DUMMY_TABLE02},{name:'accordion',type:'accordion',list:ACCORDION},{name:'map',type:'map',map:MAP},{name:'timeline',type:'timeline',list:DUMMY_TIMELINE},{name:'slider01',type:'slider01',}];
const SIZE:any = {lg: 1000, md: 768, xs: 480};

export default function HomePage() {
    const layRef = useRef<any>(null);
    const {code} = useParams();
    const userInfo = useUserInfo();
    const {selectItemSave} = useAction();
    const dispatch = useDispatch();
    const [full,setFull] = useState(false);
    const [itemList,setItemList] = useState<any>();
    const [addList,setAddList] = useState<any>([
        {name:'wrap',list:[
            {name:'헤더01',module:undefined,type:'header01',layout:HEADER_01_LAYOUT, items: HEADER_01_ITEMS,style:{"lg":{},"md":{},"xs":{}},},
            {name:'헤더02',module:undefined,type:'header02',layout:HEADER_02_LAYOUT, items: HEADER_02_ITEMS,style:{"lg":{background:"#33d2ff"},"md":{},"xs":{}},},
            {name:'본문01',module:undefined,type:'wrap', layout: WRAP1_LAYOUT, items: WRAP1_ITEMS, style:{"lg":{background:'#fff'},"md":{},"xs":{}}},
            {name:'풋터01',module:undefined,type:'footer01',layout: FOOTER_01_LAYOUT, items: FOOTER_01_ITEMS,style:{"lg":{background:'#000',paddingTop:9},"md":{},"xs":{}}}
        ],},
        {name:'단일기능',list:ONE_ACTION},
        {name:'멀티기능',list:MULTI_ACTION},
    ])
    
    const [layouts,setLayouts] = useState<any>({
        breakpoints: 'lg',
        layouts: {},
        new: 1,
    })
    const [size,setSize] = useState('');
    const selectItem = useGlobalSelectItem();
    const [copyItem,setCopyItem] = useState<any>();
    const [open,setOpen] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.key === 'Delete') {
                event.preventDefault();
                if(window.confirm('삭제하시겠습니까?')){
                    dispatch(globalSelectItemResetCreator());
                    if(selectItem.parent === ''){
                        setItemList((obj:any)=>{
                            const newObj = obj;
                            delete newObj[selectItem.i];
                            return newObj
                        })
                        setLayouts((obj:any)=>{
                            return {
                                ...obj,
                                layouts: {
                                    ...obj.layouts,
                                    [obj.breakpoints]: obj.layouts[obj.breakpoints].filter((item:any)=>item.i !== selectItem.i)
                                }
                            }
                        })
                    }else{
                        setItemList((obj:any)=>{
                            return {
                                ...obj,
                                [selectItem.parent]:{
                                    ...obj[selectItem.parent],
                                    item:{
                                        ...obj[selectItem.parent].item,
                                        // items: newItems,
                                        layout:{
                                            ...obj[selectItem.parent].item.layout,
                                            [layouts.breakpoints]: obj[selectItem.parent].item.layout[layouts.breakpoints].filter((item:any) => item.i !== selectItem.i)
                                        }
                                    },
                                }
                            }
                        })
                    }
                }
            }
            if (event.ctrlKey) {
                event.preventDefault();
                if(event.key === 's'){
                    if(selectItem.item === undefined){
                        if(window.confirm('템플릿을 저장하시겠습니까?')){
                            tempSaveAction();
                        }
                    }else{
                        if(window.confirm('설정을 저장하시겠습니까?')){
                            selectItemSave(selectItem,setItemList);
                            dispatch(globalSelectItemResetCreator());
                        }
                    }
                }else if (event.key === 'c') {
                    // 복사
                    setCopyItem(selectItem);
                    dispatch(globalSelectItemResetCreator());
                } else if (event.key === 'v') {
                    if(copyItem === undefined) return
                    // 붙여넣기
                    if(copyItem.parent ===''){
                        const item = itemList[copyItem.i];
                        const layoutList = layouts.layouts[layouts.breakpoints]
                        const layout = layoutList[copyItem.i];
                        const newI = Number(layoutList[layoutList.length-1].i) + 1;
                        const newItem = {
                            i: String(newI),
                            x: layout.x,
                            y: layout.y,
                            w: layout.w,
                            h: layout.h,
                        }
                        setItemList((obj:any)=>{
                            return {
                                ...obj,
                                [newI]:{item:{...item.item},style:{...item.style}}
                            }
                        })
                        setLayouts((obj:any)=>{
                            return {
                                ...obj,
                                layouts: {
                                    ...obj.layouts,
                                    [obj.breakpoints]: [...obj.layouts[obj.breakpoints],newItem],
                                }
                            }
                        })
                    }else{
                        const item = itemList[copyItem.parent];
                        const basicItem = item.item.items[copyItem.i];
                        const obj = itemList[copyItem.parent].item;
                        const keys = Object.keys(obj.items); 
                        const itemLast = keys.length;
                        const itemName = obj.name;
                        const layoutArr = obj.layout[layouts.breakpoints].filter((data:any)=>data.i === copyItem.i);
                        if(layoutArr.length === 0) return;
                        const layout = layoutArr[0]
                        const newI = `${itemName}-${itemLast}`
                        const newItem = {
                            i: newI,
                            x: layout.x+layout.w,
                            y: layout.y,
                            w: 1,
                            h: 10,
                        }
                        setItemList((obj:any)=>{
                            return{
                                ...obj,
                                [copyItem.parent]: {
                                    ...obj[copyItem.parent],
                                    item:{
                                        ...obj[copyItem.parent].item,
                                        items: {
                                            ...obj[copyItem.parent].item.items,
                                            [newI]: {
                                                style:{...basicItem?.style},
                                                item: {...basicItem?.item},
                                            }
                                        },
                                        layout: {
                                            ...obj[copyItem.parent].item.layout,
                                            [layouts.breakpoints]: [...obj[copyItem.parent].item.layout[layouts.breakpoints],{...newItem}]
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            }
        };

        // 이벤트 리스너 등록
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectItem,layouts.breakpoints,copyItem,itemList]);

    useEffect(()=>{
        dispatch(globalSelectItemResetCreator());
    },[])
    
    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        if(code === undefined || code === '1'){
            setItemList(TEMP_01_ITEM_LIST);
            setLayouts((obj:any)=>({...obj,layouts:TEMP_01_LAYOUT}));
        }else if(code === '2'){
            setItemList(TEMP_02_ITEM_LIST);
            setLayouts((obj:any)=>({...obj,layouts:TEMP_02_LAYOUT}));
        }else{
            getDetail();
        }
    },[userInfo.user_id,code]);
    
    // 템플릿 가져오기
    const getDetail = async() =>{
        const res = await apiGetTempDetail(userInfo.user_id,code);
        if(res.result){
            const tempData = res.data.template_data;
            if(tempData.length !== 0){
                setLayouts((obj:any)=>({...obj,layouts:tempData.layouts.layouts}));
                setItemList(tempData.itemList);
            }
        }else{
            alert(res.error);
        }
    }

    // 템플릿 저장
    const tempSaveAction = async() =>{
        if(code === undefined || code === '1' || code === '2') {
            setOpen(true);
            return
        };
        const res = await apiSetTempSave(userInfo.user_id,code,{layouts,itemList});
        if(res.result){
            alert('저장 했습니다.');
        }else{
            alert(res.error);
        }
        
    }

    // useEffect(()=>{
    //     // 전체화면 상태 변경 감지
    //     const handleFullscreenChange = (e:any) => {
    //         setFull(!full);
    //     };

    //     document.addEventListener('fullscreenchange', handleFullscreenChange);

    //     return () => {
    //         document.removeEventListener('fullscreenchange', handleFullscreenChange);
    //     };
    // },[]);
    // useEffect(()=>{
    //     if(full){
    //         fullOn();
    //     }else{
    //         fullOff();
    //     }
    // },[full])

    // 전체화면
    const fullOn = () =>{
        if(layRef?.current === undefined) return;
        const lay = layRef.current;
        if (lay?.requestFullscreen) {
            lay?.requestFullscreen();
        } else if (lay?.mozRequestFullScreen) { // Firefox 지원
            lay.mozRequestFullScreen();
        } else if (lay?.webkitRequestFullscreen) { // Chrome, Safari, Opera 지원
            lay.webkitRequestFullscreen();
        } else if (lay?.msRequestFullscreen) { // IE/Edge 지원
            lay.msRequestFullscreen();
        }
        layRef.current.style.overflowY = 'auto';
        setFull(true);
    }
    
    // 이미 전체 화면 모드인 경우, 나가기
    const fullOff = () =>{
        if (document.fullscreenElement) {
            document.exitFullscreen();
            layRef.current.style.overflowY = 'hidden';
            setFull(false);
        }
    }

    // 버튼으로 width 변경
    const sizeChange = (val:string) =>{
        if(size === val){
            setSize('auto');
        }else{
            setSize(val);
        }
    }
    
    return (
        <Wrap>
            <InnerWrap>
                <ItemSettingWrap>
                    <SettingPart layouts={layouts} itemList={itemList} setItemList={setItemList} breakpoints={layouts.breakpoints} />
                </ItemSettingWrap>
                <ContentWrap ref={layRef}>
                    <IconListWrap>
                        <IconItemWrap>
                            {full ?
                                <MdOutlineFullscreenExit color='#000' size={24} onClick={fullOff}/> :
                                <MdOutlineFullscreen color='#000' size={24} onClick={fullOn}/>
                            }
                        </IconItemWrap>
                        <IconItemWrap>
                            <MdMonitor color='#000' size={26} onClick={()=>sizeChange('lg')}></MdMonitor>
                        </IconItemWrap>
                        <IconItemWrap>
                            <MdTabletMac color='#000' size={22} onClick={()=>sizeChange('md')}></MdTabletMac>
                        </IconItemWrap>
                        <IconItemWrap>
                            <FaMobileAlt color='#000' size={22} onClick={()=>sizeChange('xs')}></FaMobileAlt>
                        </IconItemWrap>
                    </IconListWrap>
                    <ContentInnerWrap size={size === '' || size === 'auto' ? 'auto' : SIZE[size]}>
                        <LayoutPart 
                            layouts={layouts} 
                            setLayouts={setLayouts}
                            itemList={itemList}
                            setItemList={setItemList }
                        />
                    </ContentInnerWrap>
                </ContentWrap>
                <ItemListWrap>
                    <div style={{position:'sticky', top: '0', width: '100%', padding: '5px'}}>
                        <ItemListInnerWrap>
                            {addList === undefined || addList.length === 0 ?
                                <></> :
                                addList.map((data:any,index:any)=>{
                                    return(
                                        <AddListPart key={`addPart-${index}`} data={data} setLayouts={setLayouts} />
                                    )
                                })
                            }
                        </ItemListInnerWrap>
                    </div>
                </ItemListWrap>
            </InnerWrap>
            <OpenTempSavePart open={open} setOpen={setOpen} data={{layouts,itemList}}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const IconListWrap = styled(Row)`
    border-bottom: 1px solid #eee;
    height: 48px;
    padding: 0 10px;
    gap: 8px;
`;
const IconItemWrap = styled(Center)`
    cursor: pointer;
`;

const InnerWrap = styled(Row)`
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: stretch;
`;

const ContentWrap = styled.div`
    width: 100%;
    max-width: 1280px;
    min-height: 100vh;
    position: relative;
    background-color: #fff;
    & .react-grid-item > .react-resizable-handle {
        width: 12px;
        height: 12px;
        background-color: rgba(255,255,255,0.6);
    }
`;

const ContentInnerWrap = styled.div<{size:any}>`
    width: ${({size}) => typeof size === 'number' ? `${size+10}px`:size};
    margin: 0 auto;
    padding-bottom: 40px;
`;

const ItemSettingWrap = styled(Row)`
    width: 18%;
    border-right: 1px solid #eee;
    flex-direction: column;
    align-items: start;
    max-width: 280px;
    flex-shrink: 0;
`;

const ItemListWrap = styled(Row)`
    width: 18%;
    border-left: 1px solid #eee;
    flex-direction: column;
    align-items: start;
    max-width: 280px;
    flex-shrink: 0;
`;

const ItemListInnerWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
`;