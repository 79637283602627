import { Dialog, DialogContent, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { BtnItem, Center } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { apiGetTempCateList, apiSetNewTemplate, apiSetTempSave } from '../../../api/api_temp';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useNavigate } from 'react-router';

export default function OpenTempSavePart({open,setOpen,data}:any) {
    const ref = useRef<any>(null);
    const userInfo = useUserInfo();
    const navigate = useNavigate()
    const [cateList,setCateList] = useState<any>([]);
    const [cate,setCate] = useState('');
    const [name,setName] = useState('');
    
    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        getCateList();
    },[userInfo.user_id])

    const getCateList = async() =>{
        const res = await apiGetTempCateList(userInfo.user_id,1,50);
        if(res.result){
            setCateList(res.data);
            setCate(res.data[0].template_category_code);
        }
    }

    const createBtn = async() =>{
        const res = await apiSetNewTemplate(userInfo.user_id,cate,name);
        if(res.result){
            const saveRes = await apiSetTempSave(userInfo.user_id,res.data.template_code,data);
            if(saveRes.result){
                alert('저장 했습니다.');
                close();
            }else{
                alert(res.error);
            }
            navigate(`/home/${res.data.template_code}`);
        }else{
            alert(res.error);
        }
    }

    const close = () =>{
        setOpen(false);
        setName('');
        if(cateList === undefined || cateList.length === 0) return;
        setCate(cateList[0].template_category_code);
    }

    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                // '.MuiDialog-container':{alignItems:mediaQuery ? 'end' :'center',},
                '.MuiPaper-root':{margin:'8px', maxWidth:'460px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <BasicText align='center' fontSize={17}>템플릿 저장</BasicText>
                    {cateList === undefined || cateList.length === 0 ?
                        <></> :
                        <SelectItem value={cate} setValue={setCate} none='no' margin='20px 0 0'>
                            <MenuItem value='' disabled>카테고리를 선택해주세요</MenuItem>
                            {cateList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`product-cate-${index}`} value={data.template_category_code}>{data.template_category_name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                    <input value={name} onChange={(e:any)=>setName(e.target.value)} placeholder='템플릿 제목을 입려해주세요.'></input>
                    <Center style={{marginTop:'80px',gap:'10px'}}>
                        <BtnItem bg={addTheme.color} onClick={createBtn}>등록하기</BtnItem>
                        <BtnItem bg={addTheme.color} onClick={close}>취소</BtnItem>
                    </Center>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div`
    padding: 10px 0;
    & input{
        padding: 6px;
        width: 100%;
        border: 1px solid #000;
        margin-top: 15px;
        min-height: 42px;
        border-radius: 4px;
    }
`;
