import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

const Item = ({data,item,valChange}:any) =>{
    const change = (e:any) =>{
        const val = e.target.value;
        valChange(data,val);
    }
    return(
        <ItemWrap>
            <input type='radio' name={`radio-${data.id}`} value={item.id} checked={Number(data.val) === item.id} onChange={change}></input>
            <BasicText>{item.name}</BasicText>
        </ItemWrap>
    )
}

export default function RadioListFromItem({data,valChange}:any) {
    return (
        <Wrap>
            <ListWrap type={data?.type}>
                {data?.list === undefined || data?.list.length === 0 ?
                    <></> :
                    data.list.map((item:any,index:any)=>{
                        return(
                            <Item key={`chk-${data.id}-${index}`} data={data} item={item} valChange={valChange}></Item>
                        )
                    })
                }
            </ListWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ListWrap = styled(Row)<{type?:string}>`
    flex-direction: ${({type}) => type === 'radioList' ? 'column' : 'row'} ;
    gap: 10px;
    align-items: start;
`;

const ItemWrap = styled.label`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
`;