import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';
import { TEMP_01, TEMP_01_ITEM_LIST } from '../../data/layout';

const SET_TEMP= 'SUBOX/SET_TEMP';

export const globalTempCreator = createAction(SET_TEMP);

export function setGlobalLayout(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalTempCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalTempCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface LayoutState {
  layouts: any;
  breakpoints: string;
  itemList: any;
}

const initialState: LayoutState = {
  breakpoints: 'lg',
  layouts: TEMP_01,
  itemList: TEMP_01_ITEM_LIST,
};

export const globalTemp = handleActions<LayoutState, any>({
  [SET_TEMP]: (state, action: ReturnType<typeof globalTempCreator>) => ({
    breakpoints: action.payload.data.breakpoints ?? state.breakpoints,
    layouts: action.payload.data.layouts ?? state.layouts,
    itemList: action.payload.data.itemList ?? state.itemList,
  }),
}, initialState);
