import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_DRAG= 'SUBOX/SET_DRAG';
const SET_DRAG_RESET = 'SUBOX/SET_DRAG_RESET';

export const globalDragCreator = createAction(SET_DRAG);
export const globalDragResetCreator = createAction(SET_DRAG_RESET);

export function setGlobalDrag(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalDragCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalDragCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface DragState {
  drag: boolean;
  item: any;
  style: any;
}

const initialState: DragState = {
  drag: false,
  item: undefined,
  style: undefined,
};

export const globalDrag = handleActions<DragState, any>({
  [SET_DRAG]: (state, action: ReturnType<typeof globalDragCreator>) => ({
    drag: action.payload.data.darg ?? state.drag,
    item: action.payload.data.item ?? state.item,
    style: action.payload.data.style ?? state.style,
  }),
  [SET_DRAG_RESET]: () => ({
    drag: false,
    item: undefined,
    style: undefined,
  })
}, initialState);
