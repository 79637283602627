import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import { apiGetTempList } from '../../../api/api_temp';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { Pagination } from '@mui/material';
import { addTheme } from '../../../style/addTheme';
import OpenTempCreatePart from '../part/OpenTempCreatePart';

export default function MainPage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const [list,setList] = useState<any>([]);
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);
    const [open,setOpen] = useState(false);

    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        getTempList();
    },[userInfo.user_id])

    const tempClick = (code:string) =>{
        navigate(`/home/${code}`);
    }   
    const getTempList = async() =>{
        const res = await apiGetTempList(userInfo.user_id,userInfo.user_id,1,50);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <RowBetween>
                    <div></div>
                    <BtnItem onClick={()=>setOpen(true)}>템플릿 생성</BtnItem>
                </RowBetween>
                <BasicText>템플릿 선택</BasicText>
                <ContentWrap>
                    <ItemWrap onClick={()=>tempClick('1')}>
                        <img src='/img/temp/temp01.png'></img>
                    </ItemWrap>
                    <ItemWrap onClick={()=>tempClick('2')}>
                        <img src='/img/temp/temp02.png'></img>
                    </ItemWrap>
                </ContentWrap>
                <BasicText marginTop={5}>유저의 템플릿 리스트</BasicText>
                <TemListWrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        list.map((data:any,index:any)=>{
                            return(
                                <TemItemWrap key={`temp-${index}`} onClick={()=>tempClick(data.template_code)}>
                                    <BasicText>[{data.template_category_name}] {data.template_name}</BasicText>
                                </TemItemWrap>
                            )
                        })
                    }
                </TemListWrap>
                <Center>
                    <Pagination count={total} page={page} onChange={(e:any,newPage:any)=>setPage(newPage)}></Pagination>
                </Center>
            </MaxWidthWrap>
            <OpenTempCreatePart open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    gap: 20px;
`;
const ItemWrap = styled(Center)`
    width: 50%;
    max-width: 120px;
    overflow: hidden;
    &>img{
        width: 100%;
    }
`;

const TemListWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
`;
const TemItemWrap = styled.div`
    margin: 6px 0;
    cursor: pointer;
`;
const BtnItem = styled.button`
    background-color: ${addTheme.color};
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
`;