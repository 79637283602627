import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useDispatch } from 'react-redux';
import BasicText from '../../basic/BasicText';
import { apiLogin } from '../../../api/login';
import { setUserInfo } from '../../../store/common/user';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id,setId] = useState('');
    const [pw,setPw] = useState('');
    const userInfo = useUserInfo();

    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        navigate('/main');
    },[userInfo.user_id])
    
    const loginAction = async(e:any) =>{
        e.preventDefault();
        if(id === ''){
            alert('이메일을 입력해주세요.');
            return;
        }
        if(pw === ''){
            alert('비밀번호를 입력해주세요.');
            return;
        }
        // dispatch(setUserInfo({
        //     "user_id": 3,
        //     "user_code": "ab-Ha9NzRaGn0",
        //     "user_name": "김준성",
        //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6MywidXNlcl9jb2RlIjoiYWItSGE5TnpSYUduMCIsImlhdCI6MTcyNzY1OTE4NywiZXhwIjoxNzU5MTk1MTg3LCJpc3MiOiJqd3QtaXNzdWVyLW5ldCJ9.Vn0ipxGn96SVLvZnzGPIyflMC0WCOlQ6cOb3Y7Ma3Us"
        // }))
        // return;
        const res = await apiLogin(id,pw);
        if(res.result){
            dispatch(setUserInfo({...res.data}));
        }else{
            alert(res.error);
        }
    }

    return (
        <Wrap>
            <LoginWrap onSubmit={loginAction}>
                <AdminIconItem>SUBOX</AdminIconItem>
                <Center style={{marginBottom:'60px'}}>
                    <BasicText fontSize={28}>로그인</BasicText>
                </Center>
                <InputItem>
                    <input placeholder='이메일' value={id} onChange={(e:any) => setId(e.target.value)} required/>
                </InputItem>
                <InputItem>
                    <input type='password' placeholder='비밀번호' value={pw} onChange={(e:any) => setPw(e.target.value)} required/>
                </InputItem>
                <BtnWrap type='submit'>로그인</BtnWrap>
                <BtnWrap type='button' onClick={()=>navigate('/join')}>회원가입</BtnWrap>
            </LoginWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    width: 100%;
    height: 100vh;
`;

const AdminIconItem = styled(Center)`
    background-color: ${addTheme.color};
    color: #fff;
    font-size: 15px;
    padding: 6px 20px;
    border-radius: 30px;
`;

const LoginWrap = styled.form`
    border-radius: 10px;
    width: 100%;
    max-width: 410px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

const InputItem = styled(Row)`
    &>input{
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: ${addTheme.round}px;
        width: 100%;
        font-size: 18px;
    }
`;

const BtnWrap = styled.button`
    color: #fff;
    background-color: ${addTheme.color};
    width: 100%;
    height: 45px;
    border-radius: ${addTheme.round}px;
    font-size: 18px;
    &:nth-of-type(1){
        margin-top: 30px;
    }
`;