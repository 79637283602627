import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { fileTypeRes, inputResNumber } from '../../../utils/format/string';
import { Tab, Tabs } from '@mui/material';
import useAction from '../../../utils/custom-hooks/common/useAction';

function CustomTabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{width: '100%'}}
      >
        {value === index && children}
      </div>
    );
  }

const BannerSelectWrap = ({selectItem,setSelectItem,style,breakpoints,index,banner}:any) =>{
    const fileRef = useRef<any>(null);
    const [item,setItem] = useState<any>({
        fileType: '1',
        linkUrl: '',
        fileData: '',
        fileName: '',
        bgColor: '#ffffff',
    });
    const [txt,setTxt] = useState('');
    const {imgGetUrl} = useAction();

    useEffect(()=>{
        let val = '';
        if(item.fileType === '1' ){
            val = item.linkUrl === '' ? '' : `url(${item.linkUrl})`;
        }else if(item.fileType === '2'){
            val = item.fileData === '' ? '' : `url(${item.fileData})`;
        }else if(item.fileType === '3'){
            val = item.bgColor;
        }
        if(val === '' || val === undefined) return;
        const newItem = {...banner,file:{...item}, style: {...banner.style,[breakpoints]:{...banner.style[breakpoints],background: val}}}
        const newBannerList = selectItem?.item?.list.map((data:any,idx:any)=>(idx === index ? newItem : data))
        setSelectItem('item','newList',newBannerList);
    },[item.fileType,item.linkUrl,item.fileData,item.bgColor])

    // 타입 변경
    const fileSelectTypeChange = (e:any) =>{
        const val = e.target.value
        setItem((obj:any)=>({
            ...obj,fileType: val
        }))
    }

    // 링크로 이미지 입력
    const urlLinkImgChange = (e:any) =>{
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '1',
            linkUrl: val, 
        }))
    }

    // 파일 선택
    const fileImgChange = (e:any) =>{
        const file = e.target.files[0];
        if(file === undefined) return;
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg' || fileType === 'gif'){
            if (file.size > 10 * 1024 * 1024) {
                alert('파일의 용량이 10MB를 초과하였습니다.')
                fileRef.current.value = null;
            }else{
                const reader = new FileReader();
                reader.onloadend = async() => {
                    const img = await imgGetUrl(reader.result);
                    if(img.file_path === '') return;
                    setItem((obj:any)=>({
                        ...obj,
                        file: file,
                        fileType: '2',
                        fileData: img.file_path, 
                        fileName: file.name
                    }))
                };
                reader.readAsDataURL(file);
            }
        }else{
            alert('파일은 이미지 확장자인 png, jpg, gif 만 가능합니다.');
            fileRef.current.value = null;
        }
    }

    // 배경색 변경
    const bgColorChange = (e:any) => {
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '3',
            bgColor: val, 
        }))
    }

    const txtChange = (e:any) =>{
        const val = e.target.value;
        setTxt(val);
    }

    const styleChange = (e:any,type?:string)=>{
        const val = e.target.value;
        const valRes = inputResNumber(e);
        const name = e.target.name;
        const newItem = {...selectItem?.item?.list[index],style:{...selectItem?.item?.list[index].style,[breakpoints]:{...selectItem?.item?.list[index].style[breakpoints],[name]: type === 'number' ? Number(valRes) : val}}}
        const newBannerList = selectItem?.item?.list.map((data:any,idx:any)=>(idx === index ? newItem : data))
        setSelectItem('item','newList',newBannerList);
        // setSelectItem((obj:any)=>({
        //     ...obj,item:{...obj.item,banner:newBannerList}
        // }))
    }

    return(
        <>
            {/* <ContentItemWrap>
                <BasicText fontSize={18} fontWeight={700}>텍스트 입력</BasicText>
                <ItemWrap direction='column' align={'start'}>
                    <textarea rows={6} value={txt} onChange={txtChange}></textarea>
                </ItemWrap>
            </ContentItemWrap> */}
            <ContentItemWrap>
                <BasicText fontSize={18} fontWeight={700}>이미지 선택</BasicText>
                <ItemWrap direction='column' align={'start'}>
                    <label>
                        <input type="radio" name="img-type" value='1' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={banner?.file?.fileType === '1'}/>
                        url링크
                    </label>
                    <input type='text' name='img-url' value={banner?.file?.linkUrl ?? ''} onChange={urlLinkImgChange}></input>
                </ItemWrap>
                <ItemWrap direction='column' align={'start'}>
                    <label>
                        <input type="radio" name="img-type" value='2' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={banner?.file?.fileType === '2'}/>
                        파일선택
                    </label>
                    <input ref={fileRef} name='img-file' type='file' accept='.png,.jpg,.gif' onChange={fileImgChange}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <label>
                        <input type="radio" name="img-type" value='3' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={banner?.file?.fileType === '3'}/>
                        배경색 선택
                    </label>
                    <input type='color' name='background' value={banner?.file?.bgColor ?? '#ffffff'} onChange={(e:any)=>bgColorChange(e)}></input>
                </ItemWrap>
            </ContentItemWrap>
            <ContentItemWrap>
            <BasicText fontSize={18} fontWeight={700}>배경 스타일</BasicText>
                <ItemWrap pw={'30%'}>
                    <BasicText>position-x</BasicText>
                    <input type='text' name='backgroundPositionX' value={style[breakpoints]?.backgroundPositionX ?? ''} placeholder='center' onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>position-y</BasicText>
                    <input type='text' name='backgroundPositionY' value={style[breakpoints]?.backgroundPositionY ?? ''} placeholder='center' onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>repeat</BasicText>
                    <input type='text' name='backgroundRepeat' value={style[breakpoints]?.backgroundRepeat ?? ''} placeholder='no-repeat' onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>size</BasicText>
                    <input type='text' name='backgroundSize' value={style[breakpoints]?.backgroundSize ?? ''} placeholder='contain' onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>radius</BasicText>
                    <input type='text' name='borderRadius' value={style[breakpoints]?.borderRadius ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                </ItemWrap>
            </ContentItemWrap>
            {/* <ContentItemWrap>
                <BasicText fontSize={16}>텍스트 스타일</BasicText>
                <ItemWrap pw={'30%'}>
                    <BasicText>크기</BasicText>
                    <input type='text' name='fontSize' value={style[breakpoints]?.fontSize ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>굵기</BasicText>
                    <input type='text' name='fontWeight' value={style[breakpoints]?.fontWeight ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>색</BasicText>
                    <input type='color' name='color' value={style[breakpoints]?.color ?? '#000'} onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>x 정렬</BasicText>
                    <input type='text' name='justifyContent' value={style[breakpoints]?.justifyContent ?? 'start'} onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>y 정렬</BasicText>
                    <input type='text' name='alignItems' value={style[breakpoints]?.alignItems ?? 'start'} onChange={(e:any)=>styleChange(e)}></input>
                </ItemWrap>
            </ContentItemWrap> */}
        </>
    )
}

const GAP = 18;
export default function SettingBannerPart({selectItem,setSelectItem,breakpoints}:any) {
    const [txt,setTxt] = useState('');
    const [style,setStyle] = useState<any>({});
    const [tab,setTab] = useState(0);
    
    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setSelectItem('item','list',{file:{fileType: '1',linkUrl: '',fileData: '',bgColor: '#ffffff',fileName:''},style:{},text:''})
        }
    },[selectItem?.item?.list])

    useEffect(()=>{
        setTxt(selectItem?.item.text);
    },[selectItem?.item.text]);

    useEffect(()=>{
        setStyle(selectItem.style);
    },[selectItem.style])

    const txtChange = (e:any) =>{
        const val = e.target.value;
        setTxt(val);
        // setSelectItem((obj:any)=>({...obj,item:{...selectItem.item,text:val}}))
    }

    const styleChange = (e:any,type?:string)=>{
        const val = e.target.value;
        let valRes = val;
        if(type === 'number'){
            valRes = Number(inputResNumber(e))
        }
        const name = e.target.name;
        setSelectItem('style',name,valRes);
    }

    // add
    const tabClick = (e:any,newValue:any) =>{
        const txt = e.target.textContent;
        if(txt === '+'){
            setSelectItem('item','list',{file:{fileType: '1',linkUrl: '',fileData: '',bgColor: '#ffffff',fileName:''},style:{},text:''})
            return;
        }
        setTab(newValue)
    }

    const bannerDelClick = (idx:any) =>{
        const newBanner = selectItem?.item?.list.filter((data:any,ind:any)=>ind !== idx);
        setSelectItem('item','newList',newBanner);
    }

    const bannerOptionChange = (e:any,type?:string) =>{
        let val = e.target.value;
        const name = e.target.name;
        if(type === 'boolean'){
            if(val === 'true' || val === 'false'){
                val = val === 'true' ? true : false;
            }
        }
        if(type === 'number'){
            val = inputResNumber(e);
        }
        setSelectItem('bannerOption',name,val);
    }

    return (
        <Wrap>
            <ContentWrap>
                <BasicText fontSize={18} fontWeight={700}>배너</BasicText>
                <Row style={{flexWrap:'wrap'}}>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></>:
                        selectItem?.item?.list.map((banner:any,idx:any)=>{
                            return(
                                <div onClick={(e:any)=>tabClick(e,idx)} style={{width:'20%', color: idx === tab ? 'red':'#000'}} key={`banner-tab-${idx}`}>{idx+1}</div>
                            )
                        })
                    }
                    <div onClick={(e:any)=>tabClick(e,-1)} style={{width:'20%'}}>+</div>
                </Row>
                {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                    <></> :
                    selectItem?.item?.list.map((banner:any,idx:any)=>{
                        return(
                            <CustomTabPanel key={`banner-${idx}`} value={tab} index={idx}>
                                <BannerSelectWrap selectItem={selectItem} setSelectItem={setSelectItem} banner={banner} style={banner.style} breakpoints={breakpoints} index={idx}/>
                                {idx !== 0 &&
                                    <BtnWrap>
                                        <BtnItem onClick={()=>bannerDelClick(idx)}>배너 삭제</BtnItem>
                                    </BtnWrap>
                                }
                            </CustomTabPanel>
                        )
                    })
                }
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>배너 옵션</BasicText>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>autoPlay</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>on</BasicText>
                            <input type='text' name='autoPlay' value={selectItem?.item?.bannerOption?.autoPlay ?? ''} placeholder='false' onChange={(e:any)=>bannerOptionChange(e,'boolean')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>delay</BasicText>
                            <input type='text' name='delay' value={selectItem?.item?.bannerOption?.delay ?? ''} placeholder='1500' onChange={(e:any)=>bannerOptionChange(e,'number')}></input>
                        </ItemWrap>
                        <BasicText fontSize={16}>pagination</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>on</BasicText>
                            <input type='text' name='pagination' value={selectItem?.item?.bannerOption?.pagination ?? ''} placeholder='false' onChange={(e:any)=>bannerOptionChange(e,'boolean')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>active</BasicText>
                            <input type='color' name='paginationColorActive' value={selectItem?.item?.bannerOption?.paginationColorActive ?? '#007aff'} onChange={(e:any)=>bannerOptionChange(e)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>color</BasicText>
                            <input type='color' name='paginationColor' value={selectItem?.item?.bannerOption?.paginationColor ?? '#000000'} onChange={(e:any)=>bannerOptionChange(e)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>color opacity</BasicText>
                            <input type='number' name='paginationColorOpacity' value={selectItem?.item?.bannerOption?.paginationColorOpacity ?? 0.2} onChange={(e:any)=>bannerOptionChange(e)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>스타일</BasicText>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>Padding</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Top</BasicText>
                            <input type='text' name='paddingTop' value={style[breakpoints]?.paddingTop ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Left</BasicText>
                            <input type='text' name='paddingLeft' value={style[breakpoints]?.paddingLeft ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Right</BasicText>
                            <input type='text' name='paddingRight' value={style[breakpoints]?.paddingRight ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Bottom</BasicText>
                            <input type='text' name='paddingBottom' value={style[breakpoints]?.paddingBottom ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${GAP}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"], & input[type="number"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;

const BtnWrap = styled(Center)`
    gap: 15px;
`;

const BtnItem = styled(Center)`
    width: 46%;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
`;