import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useDispatch } from 'react-redux';
import BasicText from '../../basic/BasicText';
import validator from 'validator';
import { apiJoin } from '../../../api/login';

export default function JoinPage() {
    const navigate = useNavigate();
    const [id,setId] = useState('');
    const [name,setName] = useState('');
    const [pw,setPw] = useState('');
    const [pw2,setPw2] = useState('');
    
    const loginAction = async(e:any) =>{
        e.preventDefault();
        if(id === ''){
            alert('이메일을 입력해주세요.');
            return;
        }
        const emailChk = validator.isEmail(id);
        if(!emailChk){
            alert('이메일을 형식을 확인해주세요.');
            return;
        }
        if(name === ''){
            alert('이름을 입력해주세요.');
            return;
        }
        if(pw === '' || pw2 === ''){
            alert('비밀번호를 입력해주세요.');
            return;
        }
        if(pw !== pw2){
            alert('비밀번호를 확인해주세요.');
            return;
        }
        const res = await apiJoin(name,id,pw);
        if(res.result){
            alert('회원가입을 완료했습니다.');
            navigate('/');
        }else{
            alert(res.error);
        }
    }

    return (
        <Wrap>
            <LoginWrap onSubmit={loginAction}>
                <AdminIconItem>SUBOX</AdminIconItem>
                <Center style={{marginBottom:'60px'}}>
                    <BasicText fontSize={28}>회원가입</BasicText>
                </Center>
                <InputItem>
                    <input placeholder='이메일' value={id} onChange={(e:any) => setId(e.target.value)} required/>
                </InputItem>
                <InputItem>
                    <input placeholder='이름' value={name} onChange={(e:any) => setName(e.target.value)} required/>
                </InputItem>
                <InputItem>
                    <input type='password' placeholder='비밀번호' value={pw} onChange={(e:any) => setPw(e.target.value)} required/>
                </InputItem>
                <InputItem>
                    <input type='password' placeholder='비밀번호 체크' value={pw2} onChange={(e:any) => setPw2(e.target.value)} required/>
                </InputItem>
                <BtnWrap type='submit'>회원가입</BtnWrap>
            </LoginWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    width: 100%;
    height: 100vh;
`;

const AdminIconItem = styled(Center)`
    background-color: ${addTheme.color};
    color: #fff;
    font-size: 15px;
    padding: 6px 20px;
    border-radius: 30px;
`;

const LoginWrap = styled.form`
    border-radius: 10px;
    width: 100%;
    max-width: 410px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

const InputItem = styled(Row)`
    &>input{
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: ${addTheme.round}px;
        width: 100%;
        font-size: 18px;
    }
`;

const BtnWrap = styled.button`
    color: #fff;
    background-color: ${addTheme.color};
    width: 100%;
    height: 45px;
    border-radius: ${addTheme.round}px;
    font-size: 18px;
    &:nth-of-type(1){
        margin-top: 30px;
    }
`;