import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import { LIST_ITEM } from './listData';
import _ from 'lodash';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import PlayListItem from '../item/PlayListItem';
import ReactPlayer from 'react-player';

export default function PlayListPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [totalList,setTotalList] = useState<any>([]);
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const [playIndex,setPlayIndex] = useState(0);
    const [playState,setPlayState] = useState(false);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setList([LIST_ITEM]);
        }else{
            setList(selectItem?.item?.list)
        }
    },[selectItem?.item?.list])

    useEffect(()=>{
        if(totalList.length === 0) return;
        setList(totalList[page-1]);
    },[totalList,page])

    // 재생, 멈춤
    const btnAction = (idx:any) =>{
        if(playIndex === idx){
            setPlayState(!playState);
        }else{
            setPlayIndex(idx);
            setPlayState(true);
        }
    }
    return (
        <Wrap>
            <ContentWrap count={selectItem?.item?.listOption?.count ?? 1} breakpoints={breakpoints} style={{...selectItem?.item?.listStyle}}>

                {list.length === 0 ?
                    <></> :
                    <>
                        <ReactPlayer
                            url={list[playIndex]?.audio ?? ''}
                            playing={playState}
                            pip={false}
                            onEnded={() => setPlayState(false)}
                            width={'0'}
                            height={'0'}
                        />
                        {list.map((data:any,index:any)=>{
                            return(
                                <PlayListItem 
                                    key={`list4-${index}`} 
                                    data={data} 
                                    imgStyle={selectItem?.item?.imgStyle}
                                    titleStyle={selectItem?.item?.titleStyle}
                                    contentStyle={selectItem?.item?.contentStyle}
                                    timeStyle={selectItem?.item?.timeStyle}
                                    btnAction={()=>btnAction(index)}
                                    idxState={index === playIndex}
                                    nowPlay={playState}
                                />
                            )
                        })}
                    </>
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)<{count:number,breakpoints:string}>`
    gap: 8px;
    flex-wrap: wrap;
    &>div{
        width: ${({count,breakpoints}) => breakpoints === 'xs' ? `calc(${100 / Math.ceil(count / 2)}% - 6px)` : `calc(${100 / count}% - 6px)` };
    }
`;