import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import SettingImgPart from './SettingImgPart';
import SettingTextPart from './SettingTextPart';
import SettingVideoPart from './SettingVideoPart';
import SettingButtonPart from './SettingButtonPart';
import SettingBannerPart from './SettingBannerPart';
import PlayerSettingPart from '../../player/part/PlayerSettingPart';
import WebzineSettingPart from '../../list/part/WebzineSettingPart';
import GallerySettingPart from '../../list/part/GallerySettingPart';
import TextListSettingPart from '../../list/part/TextListSettingPart';
import FormSettingPart from '../../form/part/FormSettingPart';
import { useDispatch } from 'react-redux';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { globalSelectItemResetCreator, setGlobalSelectItem } from '../../../store/common/globalSelectItem';
import SettingHeaderPart from './SettingHeaderPart';
import SettingFooterPart from './SettingFooterPart';
import useAction from '../../../utils/custom-hooks/common/useAction';
import SettingWrapPart from './SettingWrapPart';
import PlaySettingPart from '../../list/part/PlaySettingPart';
import { apiSetTempSave } from '../../../api/api_temp';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useParams } from 'react-router';
import OpenTempSavePart from './OpenTempSavePart';
import Player02SettingPart from '../../player/part/Player02SettingPart';
import ChartLineSettingPart from '../../chart/part/ChartLineSettingPart';
import ChartAreaSettingPart from '../../chart/part/ChartAreaSettingPart';
import ChartBarSettingPart from '../../chart/part/ChartBarSettingPart';
import ChartPieSettingPart from '../../chart/part/ChartPieSettingPart';
import ChartRadarSettingPart from '../../chart/part/ChartRadarSettingPart';
import CountdownSettingPart from '../../timer/part/CountdownSettingPart';
import CalendarSettingPage from '../../calendar/part/CalendarSettingPage';
import TableSettingPage01 from '../../table/page/TableSettingPage01';
import AccordionSettingPage from '../../accordion/page/AccordionSettingPage';
import MapSettingPage from '../../map/page/MapSettingPage';
import TimelineSettingPage from '../../timeline/page/TimelineSettingPage';
import Slider01SettingPage from '../../slider/page/Slider01SettingPage';

export default function SettingPart({layouts,itemList,setItemList,breakpoints}:any) {
    const selectItem = useGlobalSelectItem();
    const dispatch = useDispatch();
    const {selectItemSave} = useAction();
    const [loading,setLoading] = useState(false);
    const userInfo = useUserInfo();
    const {code} = useParams();
    const [open,setOpen] = useState(false);
    
    useEffect(()=>{
        if(!loading){
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }
    },[selectItem.i]);

    // 취소
    const cancel = () =>{
        dispatch(globalSelectItemResetCreator());
    }
    // 설정 저장
    const save = () =>{
        dispatch(globalSelectItemResetCreator());
        selectItemSave(selectItem,setItemList);
    }

    // 세팅 변경
    const setSelectItem = (type:string,name:string,val:any) =>{
        let resVal:any = {};
        if(type === 'style'){
            resVal = {...selectItem.style,[breakpoints]:{...selectItem.style[breakpoints],[name]:val}}
        }
        if(type === 'item'){
            if(name === 'list'){
                resVal = {...selectItem.item,[name]:[...selectItem?.item[name] ?? [],val]}
            }else if(name === 'newList'){
                resVal = {...selectItem.item,list:val}
            }else{
                resVal = {...selectItem.item,[name]:val}
            }
        }
        
        if(type === 'videoOption' || type === 'bannerOption' || type === 'playerOptionStyle' || type === 'playerOption'
            || type === 'listOption' || type === 'titleStyle' || type === 'subTitleStyle' || type === 'imgStyle' 
            || type === 'contentStyle' || type === 'listStyle' || type === 'timeStyle' || type === 'subTimeStyle' 
            || type === 'count' || type === 'countDone' || type === 'dateStyle'
            || type === 'valueStyle' || type === 'borderStyle'){
            resVal = {...selectItem.item, [type]: {...selectItem.item[type],[name]:val}}
            dispatch(setGlobalSelectItem({
                ...selectItem,
                ['item']: resVal
            }))
            return
        }
        
        dispatch(setGlobalSelectItem({
            ...selectItem,
            [type]: resVal
        }))
    }

    // 템플릿 저장
    const tempSaveAction = async() =>{
        if(code === undefined || code === '1' || code === '2') {
            setOpen(true);
            return
        };
        if(window.confirm('저장하시겠습니까?')){
            const res = await apiSetTempSave(userInfo.user_id,code,{layouts,itemList});
            if(res.result){
                alert('저장 했습니다.');
            }else{
                alert(res.error);
            }
        }
    }
    return (
        <Wrap>
            {selectItem.i === '' ?
                <>
                    <SaveWrap>
                        <BtnItem onClick={tempSaveAction}>템플릿 저장</BtnItem>
                    </SaveWrap>
                </> :
                <>
                    {loading ?
                        <>loading</> :
                        <InnerWrap>
                            {selectItem?.item?.type === 'img' && <SettingImgPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'button' && <SettingButtonPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type?.includes('header') && <SettingHeaderPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type?.includes('footer') && <SettingFooterPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type?.includes('wrap') && <SettingWrapPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'text' && <SettingTextPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'video' && <SettingVideoPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'banner' && <SettingBannerPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints}/>}
                            {selectItem?.item?.type === 'player' && <PlayerSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'player02' && <Player02SettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'list1' && <WebzineSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'list2' && <GallerySettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'list3' && <TextListSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'list4' && <PlaySettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'form' && <FormSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} />}
                            {selectItem?.item?.type === 'chartLine' && <ChartLineSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'chartArea' && <ChartAreaSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'chartBar' && <ChartBarSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'chartPie' && <ChartPieSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'chartRadar' && <ChartRadarSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'countdown' && <CountdownSettingPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'calendar' && <CalendarSettingPage selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {(selectItem?.item?.type === 'table01' || selectItem?.item?.type === 'table02') && <TableSettingPage01 selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'accordion' && <AccordionSettingPage selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'map' && <MapSettingPage selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'timeline' && <TimelineSettingPage selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            {selectItem?.item?.type === 'slider01' && <Slider01SettingPage selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} /> }
                            <BtnWrap>
                                <BtnItem onClick={save}>저장</BtnItem>
                                <BtnItem onClick={cancel}>취소</BtnItem>
                            </BtnWrap>
                        </InnerWrap>
                    }
                </>
            }
            <OpenTempSavePart open={open} setOpen={setOpen} data={{layouts,itemList}}/>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 5px;
    width: 100%;
    position: sticky;
    top: 0;
`;

const InnerWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
`;

const BtnWrap = styled(Row)`
    gap: 15px;
    padding-bottom: 20px;
`;

const BtnItem = styled(Center)`
    width: 46%;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
`;

const SaveWrap = styled(Center)`
    width: 100%;
    margin: 10px 0;
`;