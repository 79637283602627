import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { inputResNumber } from '../../../utils/format/string';
import ItemSelectWrapPart from './ItemSelectWrapPart';
import { LIST_ITEM } from '../page/listData';
import CustomTabPanelPart from './CustomTabPanelPart';

export default function WebzineSettingPart({selectItem,setSelectItem,breakpoints}:any) {
    const [tab,setTab] = useState(0);
    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setSelectItem('item','newList',[LIST_ITEM]);
        }
    },[selectItem?.item?.list])

    const listOptionChange = (e:any,title:string,type?:string) =>{
        let val = e.target.value;
        const name = e.target.name;
        if(type === 'number'){
            val = Number(inputResNumber(e));
        }
        setSelectItem(title,name,val);
    }
    
    // add
    const tabClick = (e:any,newValue:any) =>{
        const txt = e.target.textContent;
        if(txt === '+'){
            setSelectItem('item','list',LIST_ITEM);
            return;
        }
        setTab(newValue)
    }

    const delClick = (idx:any) =>{
        const newList = selectItem?.item?.list.filter((data:any,ind:any)=>ind !== idx);
        setSelectItem('item','newList',newList);
    }
    return (
        <Wrap>
            <ContentWrap>
                <BasicText fontSize={18} fontWeight={700}>list1</BasicText>
                <Row style={{flexWrap:'wrap'}}>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></>:
                        selectItem?.item?.list.map((_:any,idx:any)=>{
                            return(
                                <div onClick={(e:any)=>tabClick(e,idx)} style={{width:'20%', color: idx === tab ? 'red':'#000'}} key={`item-tab-${idx}`}>{idx+1}</div>
                            )
                        })
                    }
                    <div onClick={(e:any)=>tabClick(e,-1)} style={{width:'20%'}}>+</div>
                </Row>
                {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                    <></> :
                    selectItem?.item?.list.map((data:any,idx:any)=>{
                        return(
                            <CustomTabPanelPart key={`list-${idx}`} value={tab} index={idx}>
                                <ItemSelectWrapPart selectItem={selectItem} setSelectItem={setSelectItem} data={data} breakpoints={breakpoints} index={idx}/>
                                {idx !== 0 &&
                                    <BtnWrap>
                                        <BtnItem onClick={()=>delClick(idx)}>삭제</BtnItem>
                                    </BtnWrap>
                                }
                            </CustomTabPanelPart>
                        )
                    })
                }
                <ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>리스트 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingTop</BasicText>
                            <input type='text' name='paddingTop' value={selectItem?.item?.listStyle?.paddingTop ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'listStyle','number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingRight</BasicText>
                            <input type='text' name='paddingRight' value={selectItem?.item?.listStyle?.paddingRight ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'listStyle','number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingLeft</BasicText>
                            <input type='text' name='paddingLeft' value={selectItem?.item?.listStyle?.paddingLeft ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'listStyle','number')}></input>px
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>이미지 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>size</BasicText>
                            <input type='text' name='width' value={selectItem?.item?.imgStyle?.width ?? ''} placeholder='25' onChange={(e:any)=>listOptionChange(e,'imgStyle','number')}></input>%
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>borderRadius</BasicText>
                            <input type='text' name='borderRadius' value={selectItem?.item?.imgStyle?.borderRadius ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'imgStyle','number')}></input>px
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>title 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>size</BasicText>
                            <input type='text' name='fontSize' value={selectItem?.item?.titleStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>listOptionChange(e,'titleStyle','number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>weight</BasicText>
                            <input type='text' name='fontWeight' value={selectItem?.item?.titleStyle?.fontWeight ?? ''} placeholder='700' onChange={(e:any)=>listOptionChange(e,'titleStyle','number')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>color</BasicText>
                            <input type='color' name='color' value={selectItem?.item?.titleStyle?.color ?? '#000000'} onChange={(e:any)=>listOptionChange(e,'titleStyle')}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>content 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>size</BasicText>
                            <input type='text' name='fontSize' value={selectItem?.item?.contentStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>listOptionChange(e,'contentStyle','number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>weight</BasicText>
                            <input type='text' name='fontWeight' value={selectItem?.item?.contentStyle?.fontWeight ?? ''} placeholder='700' onChange={(e:any)=>listOptionChange(e,'contentStyle','number')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>color</BasicText>
                            <input type='color' name='color' value={selectItem?.item?.contentStyle?.color ?? '#636363'} onChange={(e:any)=>listOptionChange(e,'contentStyle')}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>sub 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>size</BasicText>
                            <input type='text' name='fontSize' value={selectItem?.item?.timeStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>listOptionChange(e,'timeStyle','number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>weight</BasicText>
                            <input type='text' name='fontWeight' value={selectItem?.item?.timeStyle?.fontWeight ?? ''} placeholder='700' onChange={(e:any)=>listOptionChange(e,'timeStyle','number')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>color</BasicText>
                            <input type='color' name='color' value={selectItem?.item?.timeStyle?.color ?? '#000000'} onChange={(e:any)=>listOptionChange(e,'timeStyle')}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${addTheme.gap}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;

const BtnWrap = styled(Center)`
    gap: 15px;
`;

const BtnItem = styled(Center)`
    width: 46%;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
`;