import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { inputResNumber } from '../../../utils/format/string';
import PlayerPlayListItem from '../item/PlayerPlayListItem';
import { addTheme } from '../../../style/addTheme';

export default function PlayerSettingPart({selectItem,setSelectItem,breakpoints}:any) {
    const optionChange = (e:any,type?:string,style?:string) =>{
        let val = e.target.value;
        const name = e.target.name;
        if(type === 'boolean'){
            if(val === 'true' || val === 'false'){
                val = val === 'true' ? true : false;
            }
        }
        if(type === 'number'){
            val = Number(inputResNumber(e));
        }
        if(style === 'style'){
            setSelectItem('playerOptionStyle',name,val);
        }else{
            setSelectItem('playerOption',name,val);
        }
    }

    const styleChange = (e:any,type?:string) =>{
        const val = e.target.value;
        let valRes = val;
        if(type === 'number'){
            valRes = Number(inputResNumber(e))
        }
        const name = e.target.name;
        setSelectItem('style',name,valRes);
    }

    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>플레이어</BasicText>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>플레이리스트</BasicText>
                        <PlayerPlayListItem selectItem={selectItem} setSelectItem={setSelectItem} />
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>이미지 옵션</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>이미지</BasicText>
                            <input type='text' name='albumImg' value={selectItem?.item?.playerOption?.albumImg ?? ''} placeholder='false' onChange={(e:any)=>optionChange(e,'boolean')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>size</BasicText>
                            <input type='text' name='width' value={selectItem?.item?.playerOptionStyle?.width ?? ''} placeholder='0' onChange={(e:any)=>optionChange(e,'number','style')}></input>%
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>radius</BasicText>
                            <input type='text' name='borderRadius' value={selectItem?.item?.playerOptionStyle?.borderRadius ?? ''} placeholder='0' onChange={(e:any)=>optionChange(e,'number','style')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingTop</BasicText>
                            <input type='text' name='paddingTop' value={selectItem?.item?.playerOptionStyle?.paddingTop ?? ''} placeholder='0' onChange={(e:any)=>optionChange(e,'number','style')}></input>px
                        </ItemWrap>
                        {/* <ItemWrap pw={'30%'}>
                            <BasicText>paddingBottom</BasicText>
                            <input type='text' name='paddingBottom' value={selectItem?.item?.playerOptionStyle?.paddingBottom ?? ''} placeholder='0' onChange={(e:any)=>optionChange(e,'number','style')}></input>px
                        </ItemWrap> */}
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingRight</BasicText>
                            <input type='text' name='paddingRight' value={selectItem?.item?.playerOptionStyle?.paddingRight ?? ''} placeholder='0' onChange={(e:any)=>optionChange(e,'number','style')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingLeft</BasicText>
                            <input type='text' name='paddingLeft' value={selectItem?.item?.playerOptionStyle?.paddingLeft ?? ''} placeholder='0' onChange={(e:any)=>optionChange(e,'number','style')}></input>px
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>플레이어 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingTop</BasicText>
                            <input type='text' name='paddingTop' value={selectItem?.style[breakpoints]?.paddingTop ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        {/* <ItemWrap pw={'30%'}>
                            <BasicText>paddingBottom</BasicText>
                            <input type='text' name='paddingBottom' value={selectItem?.style[breakpoints]?.paddingBottom ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap> */}
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingRight</BasicText>
                            <input type='text' name='paddingRight' value={selectItem?.style[breakpoints]?.paddingRight ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>paddingLeft</BasicText>
                            <input type='text' name='paddingLeft' value={selectItem?.style[breakpoints]?.paddingLeft ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${addTheme.gap}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;