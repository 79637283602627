import React, { useState } from 'react'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

export default function AccordionListItem({data,selectItem}:any) {
    const [state,setState] = useState(false);
    const clickAction = () =>{
        setState(!state);
    }
    
    return (
        <Wrap>
            <TitleWrap onClick={clickAction} style={{...selectItem?.item?.titleStyle,fontSize:`${selectItem?.item?.titleStyle?.fontSize ?? 16}px`}}>
                <p>{data?.title}</p>
                {!state ?
                    <FaAngleDown color={selectItem?.item?.titleStyle?.iconColor ?? '#000'}/> :
                    <FaAngleUp color={selectItem?.item?.titleStyle?.iconColor ?? '#000'}></FaAngleUp>
                }
            </TitleWrap>
            <ContentWrap state={state} style={{...selectItem?.item?.contentStyle,fontSize:`${selectItem?.item?.contentStyle?.fontSize ?? 16}px`}}>
                <p>{data?.content}</p>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    & p{
        width: 100%;
        line-height: 1.2;
    }
`;
const TitleWrap = styled(RowBetween)`
    align-items: start;
    gap: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid #dcdcdc;
    cursor: pointer;
    align-items: center;
    & svg{
        flex-shrink: 0;
    }
`;
const ContentWrap = styled(TitleWrap)<{state:boolean}>`
    cursor: default;
    background-color: #fafafa;
    display: ${({state}) => state ? 'flex':'none'};
`;