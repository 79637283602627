import { axiosConfigInstance } from "./config"

// 로그인
export const apiLogin = async(user_email:any,user_password:any) =>{
    try {
        const res = await axiosConfigInstance('/api/user/account').post('',{
            method: 'loginAccount',
            params: {
                user_email,user_password
            }
        });
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 회원가입
export const apiJoin = async(user_name:any,user_email:any,user_password:any) =>{
    try {
        const res = await axiosConfigInstance('/api/user/account').post('',{
            method: 'createAccount',
            params: {
                user_name,user_email,user_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}