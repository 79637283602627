import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { fileTypeRes, inputResNumber } from '../../../utils/format/string';
import useAction from '../../../utils/custom-hooks/common/useAction';

const GAP = 18;
export default function SettingVideoPart({selectItem,setSelectItem,breakpoints}:any) {
    const [item,setItem] = useState({
        file: null,
        fileData: '',
        linkUrl: '',
        fileType: '1',
    });
    const [style,setStyle] = useState<any>({});
    const fileRef = useRef<any>(null);
    const {imgGetUrl} = useAction();

    useEffect(()=>{
        setStyle(selectItem.style);
    },[selectItem.style])

    useEffect(()=>{
        let val = '';
        if(item.fileType === '1' ){
            val = item.linkUrl
        }else if(item.fileType === '2'){
            val = item.fileData
        }
        if(val === '') return;
        // setSelectItem((obj:any)=>({...obj,item:{...selectItem.item,video:val}}))
        setSelectItem('item','video',val);
    },[item.fileType,item.linkUrl,item.fileData])

    // 타입 변경
    const fileSelectTypeChange = (e:any) =>{
        const val = e.target.value
        setItem((obj:any)=>({
            ...obj,fileType: val
        }))
    }

    // 링크로 이미지 입력
    const urlLinkImgChange = (e:any) =>{
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '1',
            linkUrl: val, 
        }))
    }

    // 파일 선택
    const fileImgChange = (e:any) =>{
        const file = e.target.files[0];
        if(file === undefined) return;
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'mp4'){
            // if (file.size > 10 * 1024 * 1024) {
            //     alert('파일의 용량이 10MB를 초과하였습니다.')
            //     fileRef.current.value = null;
            // }else{
                const reader = new FileReader();
                reader.onloadend = async() => {
                    const video = await imgGetUrl(reader.result);
                    if(video.file_path === '') return;
                    setItem((obj:any)=>({
                        ...obj,
                        file: file,
                        fileType: '2',
                        fileData: video.file_path, 
                    }))
                };
                reader.readAsDataURL(file);
            // }
        }else{
            alert('파일은 이미지 확장자인 mp4 만 가능합니다.');
            fileRef.current.value = null;
        }
    }

    const styleChange = (e:any,type?:string)=>{
        const val = e.target.value;
        let valRes = val;
        if(type === 'number'){
            valRes = Number(inputResNumber(e))
        }
        const name = e.target.name;
        setSelectItem('style',name,valRes);
    }

    // 비디오 옵션
    const videoOptionChange = (e:any,type?:string) =>{
        let val = e.target.value;
        const name = e.target.name;
        if(type === 'boolean'){
            if(val === 'true' || val === 'false'){
                val = val === 'true' ? true : false;
            }
        }
        if(type === 'number'){
            val = inputResNumber(e);
        }
        // setSelectItem((obj:any)=>({
        //     ...obj,videoOption:{...obj.videoOption,[name]:val}
        // }))
        setSelectItem('videoOption',name,val);
    }
    
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>비디오 선택</BasicText>
                    <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" name="img-type" value='1' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item.fileType === '1'}/>
                            url링크
                        </label>
                        <input type='text' name='img-url' value={item.linkUrl} onChange={urlLinkImgChange}></input>
                    </ItemWrap>
                    <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" name="img-type" value='2' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item.fileType === '2'}/>
                            파일선택
                        </label>
                        <input ref={fileRef} name='img-file' type='file' accept='.mp4' onChange={fileImgChange}></input>
                    </ItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText  fontSize={18} fontWeight={700}>스타일</BasicText>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>Padding</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Top</BasicText>
                            <input type='text' name='paddingTop' value={style[breakpoints]?.paddingTop ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Left</BasicText>
                            <input type='text' name='paddingLeft' value={style[breakpoints]?.paddingLeft ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>Right</BasicText>
                            <input type='text' name='paddingRight' value={style[breakpoints]?.paddingRight ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                        {/* <ItemWrap pw={'30%'}>
                            <BasicText>Bottom</BasicText>
                            <input type='text' name='paddingBottom' value={style[breakpoints]?.paddingBottom ?? ''} onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap> */}
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>radius</BasicText>
                            <input type='text' name='borderRadius' value={style[breakpoints]?.borderRadius ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>controls</BasicText>
                            <input type='text' name='controls' value={selectItem?.item?.videoOption?.controls ?? ''} placeholder='false' onChange={(e:any)=>videoOptionChange(e,'boolean')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>자동재생</BasicText>
                            <input type='text' name='playing' value={selectItem?.item?.videoOption?.playing ?? ''} placeholder='false' onChange={(e:any)=>videoOptionChange(e,'boolean')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>재생속도</BasicText>
                            <input type='text' name='playbackRate' value={selectItem?.item?.videoOption?.playbackRate ?? ''} placeholder='1' onChange={(e:any)=>videoOptionChange(e,'number')}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>반복</BasicText>
                            <input type='text' name='loop' value={selectItem?.item?.videoOption?.loop ?? ''} placeholder='false' onChange={(e:any)=>videoOptionChange(e,'boolean')}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${GAP}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
`;