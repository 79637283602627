import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Center, ContentItemWrap, ContentWrap, ItemWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { inputResNumber } from '../../../utils/format/string';

export default function TableSettingPage01({selectItem,setSelectItem,breakpoints}:any) {
    
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>table</BasicText>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>데이터</BasicText>
                        <ItemWrap>
                            <textarea rows={5} value={selectItem?.item?.table ?? ''} onChange={(e:any)=>setSelectItem('item','table',e.target.value)}></textarea>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>타이틀 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.titleStyle?.color ?? '#000000'} placeholder='false' onChange={(e:any)=>setSelectItem('titleStyle','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>크기</BasicText>
                            <input type='text' value={selectItem?.item?.titleStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>setSelectItem('titleStyle','fontSize',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>굵기</BasicText>
                            <input type='text' value={selectItem?.item?.titleStyle?.fontWeight ?? ''} placeholder='400' onChange={(e:any)=>setSelectItem('titleStyle','fontWeight',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>align</BasicText>
                            <input type='text' value={selectItem?.item?.titleStyle?.textAlign ?? ''} placeholder='left' onChange={(e:any)=>setSelectItem('titleStyle','textAlign',e.target.value)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>값 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.valueStyle?.color ?? '#000000'} placeholder='false' onChange={(e:any)=>setSelectItem('valueStyle','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>크기</BasicText>
                            <input type='text' value={selectItem?.item?.valueStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>setSelectItem('valueStyle','fontSize',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>굵기</BasicText>
                            <input type='text' value={selectItem?.item?.valueStyle?.fontWeight ?? ''} placeholder='400' onChange={(e:any)=>setSelectItem('valueStyle','fontWeight',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>align</BasicText>
                            <input type='text' value={selectItem?.item?.valueStyle?.textAlign ?? ''} placeholder='left' onChange={(e:any)=>setSelectItem('valueStyle','textAlign',e.target.value)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>보더 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.borderStyle?.borderColor ?? '#e0e0e0'} placeholder='false' onChange={(e:any)=>setSelectItem('borderStyle','borderColor',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>스타일</BasicText>
                            <input type='text' value={selectItem?.item?.borderStyle?.borderStyle ?? 'solid'} placeholder='solid' onChange={(e:any)=>setSelectItem('borderStyle','borderStyle',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'} align='start'>
                            <BasicText>크기</BasicText>
                            <Row style={{flexDirection:'column',gap:'5px'}}>
                                <Row style={{gap:'2px'}}>
                                    top: 
                                    <input type='text' value={selectItem?.item?.borderStyle?.borderTopWidth ?? '0'} placeholder='0' onChange={(e:any)=>setSelectItem('borderStyle','borderTopWidth',e.target.value)}></input>
                                </Row>
                                <Row style={{gap:'2px'}}>
                                    right: 
                                    <input type='text' value={selectItem?.item?.borderStyle?.borderRightWidth ?? '0'} placeholder='0' onChange={(e:any)=>setSelectItem('borderStyle','borderRightWidth',e.target.value)}></input>
                                </Row>
                                <Row style={{gap:'2px'}}>
                                    bottom: 
                                    <input type='text' value={selectItem?.item?.borderStyle?.borderBottomWidth ?? '1'} placeholder='0' onChange={(e:any)=>setSelectItem('borderStyle','borderBottomWidth',e.target.value)}></input>
                                </Row>
                                <Row style={{gap:'2px'}}>
                                    left: 
                                    <input type='text' value={selectItem?.item?.borderStyle?.borderLeftWidth ?? '0'} placeholder='0' onChange={(e:any)=>setSelectItem('borderStyle','borderLeftWidth',e.target.value)}></input>
                                </Row>
                            </Row>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
`;