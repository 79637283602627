import { MenuItem, Select } from '@mui/material';
import React from 'react'
import styled from 'styled-components'

export default function SelectFormItem({data,valChange}:any) {
    const change = (e:any) =>{
        const val = e.target.value;
        valChange(data,val);
    }
    return (
        <Wrap>
            {data?.list === undefined || data?.list.length === 0 ?
                <></> :
                <Select 
                    value={data.val === undefined ? data?.list[0].id : data.val} 
                    onChange={change}
                    size="small"
                    sx={{fontSize:'15px'}}
                >
                    {data.list.map((item:any,index:any)=>{
                        return(
                            <MenuItem key={`select-${data.id}-${index}`} value={item.id}>{item.name}</MenuItem>
                        )
                    })}
                </Select>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;
