import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Center, ContentItemWrap, ContentWrap, ItemWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { inputResNumber } from '../../../utils/format/string';

export default function AccordionSettingPage({selectItem,setSelectItem,breakpoints}:any) {
    // 데이터 추가
    const addClick = () =>{
        setSelectItem('item','list',{title:'',content:''});
    }
    // 데이터 삭제
    const delClick = (idx:any) =>{
        const newList = selectItem.item.list.filter((data:any,index:any)=> idx !== index);
        setSelectItem('item','newList',newList);
    }

    // 데이터 값 변경
    const changeAction = (data:any,name:any,val:any,idx:any) =>{
        const newData = {
            ...data,
            [name]: val,
        }
        const newList = selectItem.item.list.map((item:any,index:any)=>index === idx ? newData : item);
        setSelectItem('item','newList',newList);
    }
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>accordion</BasicText>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></> :
                        selectItem?.item?.list.map((data:any,index:any)=>{
                            return(
                                <ContentItemWrap key={`data-${index}`}>
                                    <RowBetween>
                                        <BasicText>{index+1}. 데이터</BasicText>
                                        <BasicText onClick={()=>delClick(index)}>삭제</BasicText>
                                    </RowBetween>
                                    <ItemWrap direction='column' align='start' pw={'30%'}>
                                        <BasicText>질문</BasicText>
                                        <input type='text' value={data?.title} onChange={(e:any)=>changeAction(data,'title',e.target.value,index)}></input>
                                    </ItemWrap>
                                    <ItemWrap direction='column' align='start' pw={'30%'}>
                                        <BasicText>답변</BasicText>
                                        <textarea rows={4} value={data?.content} onChange={(e:any)=>changeAction(data,'content',e.target.value,index)}></textarea>
                                    </ItemWrap>
                                </ContentItemWrap>
                            )
                        })
                    }
                    <Center style={{width: '100%'}}>
                        <Center onClick={addClick} style={{border:'1px solid #eee', padding:'6px 12px', cursor:'pointer'}}>데이터 추가</Center>
                    </Center>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>타이틀 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.titleStyle?.color ?? '#000000'} onChange={(e:any)=>setSelectItem('titleStyle','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>크기</BasicText>
                            <input type='text' value={selectItem?.item?.titleStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>setSelectItem('titleStyle','fontSize',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>굵기</BasicText>
                            <input type='text' value={selectItem?.item?.titleStyle?.fontWeight ?? ''} placeholder='400' onChange={(e:any)=>setSelectItem('titleStyle','fontWeight',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>align</BasicText>
                            <input type='text' value={selectItem?.item?.titleStyle?.textAlign ?? ''} placeholder='left' onChange={(e:any)=>setSelectItem('titleStyle','textAlign',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>배경</BasicText>
                            <input type='color' value={selectItem?.item?.titleStyle?.backgroundColor ?? '#ffffff'} onChange={(e:any)=>setSelectItem('titleStyle','backgroundColor',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>아이콘색</BasicText>
                            <input type='color' value={selectItem?.item?.titleStyle?.iconColor ?? '#000000'} onChange={(e:any)=>setSelectItem('titleStyle','iconColor',e.target.value)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontSize={16}>답변 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.contentStyle?.color ?? '#000000'} placeholder='false' onChange={(e:any)=>setSelectItem('contentStyle','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>크기</BasicText>
                            <input type='text' value={selectItem?.item?.contentStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>setSelectItem('contentStyle','fontSize',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>굵기</BasicText>
                            <input type='text' value={selectItem?.item?.contentStyle?.fontWeight ?? ''} placeholder='400' onChange={(e:any)=>setSelectItem('contentStyle','fontWeight',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>align</BasicText>
                            <input type='text' value={selectItem?.item?.contentStyle?.textAlign ?? ''} placeholder='left' onChange={(e:any)=>setSelectItem('contentStyle','textAlign',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>배경</BasicText>
                            <input type='color' value={selectItem?.item?.contentStyle?.backgroundColor ?? '#fafafa'} onChange={(e:any)=>setSelectItem('contentStyle','backgroundColor',e.target.value)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
`;