export const DUMMY_TABLE = `name|age
철수|25
수철|35
`;
export const DUMMY_TABLE02 = `name|철수|수철
age|25|35
`;

export const DUMMY_TIMELINE = [
    {
        "title": "",
        "content": "2024.05.31\n투자"
    },
    {
        "title": "",
        "content": "2024.06.05\n회사 설립"
    }
]

const RESIZE_HANDLES = ["se"];
export const TEMP_01 = {
    lg: [
        { i: "0", x: 0, y: 0, w: 24, h: 12, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 0, y: 12, w: 12, h: 86, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 16, y: 12, w: 4, h: 68, resizeHandles: RESIZE_HANDLES,},
        { i: "3", x: 15, y: 80, w: 3, h: 10, resizeHandles: RESIZE_HANDLES,},
        { i: "4", x: 18, y: 80, w: 3, h: 10, resizeHandles: RESIZE_HANDLES,},
        { i: "5", x: 0, y: 13, w: 24, h: 10, resizeHandles: RESIZE_HANDLES,},
    ],
    md: [
        { i: "0", x: 0, y: 0, w: 24, h: 12, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 0, y: 12, w: 12, h: 86, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 16, y: 12, w: 4, h: 68, resizeHandles: RESIZE_HANDLES,},
        { i: "3", x: 15, y: 80, w: 3, h: 10, resizeHandles: RESIZE_HANDLES,},
        { i: "4", x: 18, y: 80, w: 3, h: 10, resizeHandles: RESIZE_HANDLES,},
        { i: "5", x: 0, y: 13, w: 24, h: 10, resizeHandles: RESIZE_HANDLES,},
    ],
    xs: [
        { i: "0", x: 0, y: 0, w: 24, h: 12, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 0, y: 12, w: 12, h: 86, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 16, y: 12, w: 4, h: 68, resizeHandles: RESIZE_HANDLES,},
        { i: "3", x: 15, y: 80, w: 3, h: 10, resizeHandles: RESIZE_HANDLES,},
        { i: "4", x: 18, y: 80, w: 3, h: 10, resizeHandles: RESIZE_HANDLES,},
        { i: "5", x: 0, y: 13, w: 24, h: 10, resizeHandles: RESIZE_HANDLES,},
    ]
}

export const HEADER_01_ITEMS = {
    "header-0":{style:{"lg": {"fontSize": 20,"fontWeight": 900,"color": "#ed4aff"},"md": {"fontSize": 18,"fontWeight": 900,"color": "#ed4aff"},"xs": {"fontSize": 16,"fontWeight": 900,"color": "#ed4aff"}},item:{name:'button',text:'노래하는 진아',type:'button'}},
    "header-1":{style:{"lg":{},"md":{},"xs":{}},item:{name:'button',text:'About',type:'button'}},
    "header-2":{style:{"lg":{},"md":{},"xs":{}},item:{name:'button',text:'Music',type:'button'}},
    "header-3":{style:{"lg":{},"md":{},"xs":{}},item:{name:'button',text:'Video',type:'button'}},
    "header-4":{style:{"lg":{},"md":{},"xs":{}},item:{name:'button',text:'Live',type:'button'}},
    "header-5":{style:{"lg":{},"md":{},"xs":{}},item:{name:'button',text:'Contact',type:'button'}},
    "header-6":{style:{"lg":{background: "url(/img/sns/inst.png)",backgroundPosition:'center',backgroundSize:'50%',backgroundRepeat: 'no-repeat'},"md":{background: "url(/img/sns/inst.png)",backgroundPosition:'center',backgroundSize:'50%',backgroundRepeat: 'no-repeat'},"xs":{background: "url(/img/sns/inst.png)",backgroundPosition:'center',backgroundSize:'50%',backgroundRepeat: 'no-repeat'}},item:{name:'button',type:'button'}},
    "header-7":{style:{"lg":{"color": "#ed4aff","fontWeight": 800},"md":{"color": "#ed4aff","fontWeight": 800},"xs":{"color": "#ed4aff","fontWeight": 800}},item:{name:'button',text:'@sing_jinah',type:'button'}},
}

export const HEADER_01_LAYOUT = {
    lg: [
        { i: "header-0", x: 0, y: 0, w: 3, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-1", x: 7, y: 0, w: 2, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-2", x: 9, y: 0, w: 2, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-3", x: 11, y: 0, w: 2, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-4", x: 13, y: 0, w: 2, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-5", x: 15, y: 0, w: 2, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-6", x: 20, y: 0, w: 1, h: 3, resizeHandles: RESIZE_HANDLES,},
        { i: "header-7", x: 21, y: 0, w: 3, h: 3, resizeHandles: RESIZE_HANDLES,},
    ],
    md: [
        { i: "header-0", x: 0, y: 0, w: 2, h: 5, resizeHandles: RESIZE_HANDLES,},
        { i: "header-1", x: 3, y: 5, w: 1, h: 2, resizeHandles: RESIZE_HANDLES,},
        { i: "header-2", x: 4, y: 5, w: 1, h: 2, resizeHandles: RESIZE_HANDLES,},
        { i: "header-3", x: 5, y: 5, w: 1, h: 2, resizeHandles: RESIZE_HANDLES,},
        { i: "header-4", x: 6, y: 5, w: 1, h: 2, resizeHandles: RESIZE_HANDLES,},
        { i: "header-5", x: 7, y: 5, w: 1, h: 2, resizeHandles: RESIZE_HANDLES,},
        { i: "header-6", x: 9, y: 0, w: 1, h: 4, resizeHandles: RESIZE_HANDLES,},
        { i: "header-7", x: 10, y: 0, w: 2, h: 4, resizeHandles: RESIZE_HANDLES,},
    ],
    xs: [
        { i: "header-0", x: 0, y: 0, w: 2, h: 5, resizeHandles: RESIZE_HANDLES,},
        { i: "header-1", x: 0, y: 5, w: 1, h: 4, resizeHandles: RESIZE_HANDLES,},
        { i: "header-2", x: 1, y: 5, w: 1, h: 4, resizeHandles: RESIZE_HANDLES,},
        { i: "header-3", x: 2, y: 5, w: 1, h: 4, resizeHandles: RESIZE_HANDLES,},
        { i: "header-4", x: 3, y: 5, w: 1, h: 4, resizeHandles: RESIZE_HANDLES,},
        { i: "header-5", x: 4, y: 5, w: 1, h: 4, resizeHandles: RESIZE_HANDLES,},
        { i: "header-6", x: 3, y: 0, w: 1, h: 5, resizeHandles: RESIZE_HANDLES,},
        { i: "header-7", x: 4, y: 0, w: 2, h: 5, resizeHandles: RESIZE_HANDLES,},
    ]
}

export const FOOTER_01_ITEMS = {
    "footer-0":{style:{"lg":{background:'#000'},"md":{background:'#000'},"xs":{background:'#000'}},item:{name:'text',type:'text',text:'<p class="ql-align-center"><span style="color: rgb(255, 255, 255); font-size: 15px;">ⓒ2024 by 노래하는진아 and maded by SUBOX</span></p>'}}
}

export const FOOTER_01_LAYOUT = {
    lg: [
        { i: "footer-0", x: 7, y: 0, w: 10, h: 3, resizeHandles: RESIZE_HANDLES,},
    ],
    md: [
        { i: "footer-0", x: 0, y: 0, w: 12, h: 3, resizeHandles: RESIZE_HANDLES,},
    ],
    xs: [
        { i: "footer-0", x: 0, y: 0, w: 6, h: 3, resizeHandles: RESIZE_HANDLES,},
    ]
};

export const WRAP1_LAYOUT = {
    lg: [
        {i:'wrap-0', x:0, y:0, w:12, h:109},
        {i:'wrap-1', x:16, y:7, w:4, h:70},
        {i:'wrap-2', x:14, y:84, w:3, h:8},
        {i:'wrap-3', x:19, y:84, w:3, h:8},
    ],
    md: [
        {i:'wrap-0', x:0, y:0, w:12, h:86},
        {i:'wrap-1', x:3, y:94, w:6, h:84},
        {i:'wrap-2', x:2, y:184, w:3, h:10},
        {i:'wrap-3', x:7, y:184, w:3, h:10},
    ],
    xs: [
        {i:'wrap-0', x:0, y:4, w:6, h:53},
        {i:'wrap-1', x:1, y:61, w:4, h:80},
        {i:'wrap-2', x:1, y:147, w:4, h:10},
        {i:'wrap-3', x:1, y:161, w:4, h:10},
    ]
}

export const WRAP1_ITEMS = {
    'wrap-0': {style:{"lg":{},"md":{},"xs":{}},item:{name:'img',type:'img',img: '/img/cat.jfif'}},
    // 'wrap-0': {style:{"lg":{},"md":{},"xs":{}},item:{name:'img',type:'slider01',img: '/img/cat.jfif'}},
    'wrap-1': {style:{"lg":{},"md":{},"xs":{}},item:{name:'player02',type:'player02',list:[{title:'moon',sub:'tera · 24.10.07',audio:'https://www.youtube.com/watch?v=PPsQ2VeuijI'},{title:'sun',audio:'https://www.youtube.com/watch?v=71QTodhQcZw'},{title:'star',audio:'https://www.youtube.com/watch?v=CANjZfaNCJI'}]}},
    'wrap-2': {style:{"lg":{color:'#ffffff',background:'#000000'},"md":{color:'#ffffff',background:'#000000'},"xs":{color:'#ffffff',background:'#000000'}},item:{name:'button',type:'button',text:'슈박스'}},
    'wrap-3': {style:{"lg":{color:'#ffffff',background:'#000000'},"md":{color:'#ffffff',background:'#000000'},"xs":{color:'#ffffff',background:'#000000'}},item:{name:'button',type:'button',text:'유튜브'}},
}

export const TEMP_01_LAYOUT = {
    lg: [
        { i: "0", x: 0, y: 0, w: 24, h: 12, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 0, y: 12, w: 24, h: 110, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 0, y: 13, w: 24, h: 10, resizeHandles: RESIZE_HANDLES,},
    ],
    md: [
        { i: "0", x: 0, y: 0, w: 12, h: 19, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 8, y: 0, w: 16, h: 210, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 0, y: 19, w: 12, h: 10, resizeHandles: RESIZE_HANDLES,},
    ],
    xs: [
        { i: "0", x: 0, y: 0, w: 6, h: 19, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 8, y: 0, w: 16, h: 180, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 0, y: 19, w: 6, h: 10, resizeHandles: RESIZE_HANDLES,},
    ]
};

export const TEMP_01_ITEM_LIST = {
    "0":{style:{"lg":{background:'#fff'},"md":{background:'#fff'},"xs":{background:'#fff'}},item:{name:'header',type:'header01', layout: HEADER_01_LAYOUT, items: HEADER_01_ITEMS}},
    "1":{style:{"lg":{},"md":{},"xs":{}},item:{name:'wrap',type:'wrap', layout: WRAP1_LAYOUT, items: WRAP1_ITEMS}},
    "2":{style:{"lg":{background:'#000',paddingTop:9},"md":{background:'#000',paddingTop:9},"xs":{background:'#000',paddingTop:9}},item:{name:'footer',type:'footer01', layout: FOOTER_01_LAYOUT, items: FOOTER_01_ITEMS}},
}

export const HEADER_02_LAYOUT = {
    lg: [
        {i:"header-0", h: 17, w: 24, x: 0, y: 0,},
        {i:"header-1", h: 5, w: 2, x: 6, y: 17,},
        {i:"header-2", h: 5, w: 2, x: 11, y: 17,},
        {i:"header-3", h: 5, w: 2, x: 16, y: 17,},
    ],
    md: [
        {i:"header-0", h: 17, w: 12, x: 0, y: 0,},
        {i:"header-1", h: 5, w: 2, x: 2, y: 17,},
        {i:"header-2", h: 5, w: 2, x: 5, y: 17,},
        {i:"header-3", h: 5, w: 2, x: 8, y: 17,},
    ],
    xs: [
        {i:"header-0", h: 17, w: 24, x: 0, y: 0,},
        {i:"header-1", h: 5, w: 2, x: 0, y: 17,},
        {i:"header-2", h: 5, w: 2, x: 2, y: 17,},
        {i:"header-3", h: 5, w: 2, x: 4, y: 17,},
    ]
}

export const HEADER_02_ITEMS = {
    "header-0":{style:{"lg": {background:"#33d2ff"},"md": {},"xs": {}},item:{name:'text',text:'<p class="ql-align-center"><br></p><p class="ql-align-center"><br></p><p class="ql-align-center"><strong style="font-size: 44px; color: rgb(255, 255, 255);"><span class="ql-cursor"></span>YeoNeo 여너</strong></p>',type:'text'}},
    "header-1":{style:{"lg": {},"md": {},"xs": {}},item:{name:'button',text:'Artist',type:'button'}},
    "header-2":{style:{"lg": {},"md": {},"xs": {}},item:{name:'button',text:'Profile',type:'button'}},
    "header-3":{style:{"lg": {},"md": {},"xs": {}},item:{name:'button',text:'Community',type:'button'}},
}

export const WRAP02_LAYOUT = {
    lg: [
        {i:"wrap-0", w: 3, h: 29, x: 10, y: 7,},
        {i:"wrap-1", w: 9, h: 20, x: 7, y: 40,},
        {i:"wrap-2", w: 2, h: 6, x: 9, y: 64,},
        {i:"wrap-3", w: 2, h: 6, x: 12, y: 64,},
    ],
    md: [
        {i:"wrap-0", w: 2, h: 32, x: 5, y: 10,},
        {i:"wrap-1", w: 8, h: 20, x: 2, y: 48,},
        {i:"wrap-2", w: 2, h: 7, x: 4, y: 72,},
        {i:"wrap-3", w: 2, h: 7, x: 6, y: 72,},
    ],
    xs: [
        {i:"wrap-0", w: 2, h: 27, x: 2, y: 8,},
        {i:"wrap-1", w: 6, h: 20, x: 0, y: 40,},
        {i:"wrap-2", w: 2, h: 6, x: 1, y: 62,},
        {i:"wrap-3", w: 2, h: 6, x: 3, y: 62,},
    ]
}

export const WRAP02_ITEMS = {
    "wrap-0":{style:{"lg": {borderRadius:300},"md": {},"xs": {}},item:{name:'img',img:"https://music.dapi.subox.co.kr/sound_album/21141/2204/minsize.jpg",type:'img'}},
    "wrap-1":{style:{"lg": {},"md": {},"xs": {}},item:{name:'text',text:"<p class=\"ql-align-center\"><span style=\"font-size: 18px;\">안녕하세요😊</span></p><p class=\"ql-align-center\"><span style=\"font-size: 18px;\">신나는 이디엠과 때때로 잔잔한 뉴에지를</span></p><p class=\"ql-align-center\"><span style=\"font-size: 18px;\">만드는 </span><strong style=\"font-size: 18px;\">일렉트로닉 뮤지션 여너</strong><span style=\"font-size: 18px;\">입니다.</span></p>",type:'text'}},
    "wrap-2":{style:{"lg": {color:'#81d3fa',background:'#e0f2fe',borderRadius:30,fontSize: 14},"md": {},"xs": {}},item:{name:'button',text:'#작곡',type:'button'}},
    "wrap-3":{style:{"lg": {color:'#81d3fa',background:'#e0f2fe',borderRadius:30,fontSize: 14},"md": {},"xs": {}},item:{name:'button',text:'#프로듀싱',type:'button'}},
}

export const WRAP03_LAYOUT = {
    lg: [
        {i:"wrap-0", w: 16, h: 8, x: 4, y: 6,},
        {i:"wrap-1", w: 16, h: 46, x: 4, y: 23,},
    ],
    md: [
        {i:"wrap-0", w: 10, h: 8, x: 1, y: 7,},
        {i:"wrap-1", w: 10, h: 47, x: 1, y: 18,},
    ],
    xs: [
        {i:"wrap-0", w: 16, h: 8, x: 0, y: 6,},
        {i:"wrap-1", w: 4, h: 90, x: 1, y: 18,},
    ]
}

export const WRAP03_ITEMS = {
    "wrap-0":{style:{"lg": {background: '#f5f5f5'},"md": {},"xs": {}},item:{name:'text',text:'<p><span style="font-size: 20px;"><span class="ql-cursor"></span>💿 곡</span></p>',type:'text'}},
    "wrap-1":{style:{"lg": {},"md": {},"xs": {}},item:{name:'list4',type:'list4',imgStyle:{borderRadius:15,width:80},listOption:{count:4} }},
}
export const WRAP04_LAYOUT = {
    lg: [
        {i:"wrap-0", w: 16, h: 8, x: 4, y: 6,},
        {i:"wrap-1", w: 7, h: 10, x: 4, y: 23,},
        {i:"wrap-2", w: 7, h: 10, x: 13, y: 23,},
    ],
    md: [
        {i:"wrap-0", w: 10, h: 8, x: 1, y: 7,},
        {i:"wrap-1", w: 4, h: 10, x: 1, y: 23,},
        {i:"wrap-2", w: 4, h: 10, x: 7, y: 23,},
    ],
    xs: [
        {i:"wrap-0", w: 6, h: 8, x: 0, y: 6,},
        {i:"wrap-1", w: 4, h: 10, x: 1, y: 23,},
        {i:"wrap-2", w: 4, h: 10, x: 1, y: 36,},
    ]
}
export const WRAP04_ITEMS = {
    "wrap-0":{style:{"lg": {background: '#f5f5f5'},"md": {},"xs": {}},item:{name:'text',text:'<p><span style="font-size: 20px;"><span class="ql-cursor"></span>💚 활동 채널</span></p>',type:'text'}},
    "wrap-1":{style:{"lg": {border:'1px solid #ddd',textAlign: "left", background: 'url(https://music.dapi.subox.co.kr/sound_album/21141/2204/minsize.jpg)', backgroundPositionX: '5px', backgroundPositionY: '5px', backgroundRepeat: 'no-repeat', backgroundSize: '40px', paddingLeft: 64, borderRadius: 6},"md": {},"xs": {}},item:{name:'button',text:'슈박스',type:'button'}},
    "wrap-2":{style:{"lg": {border:'1px solid #ddd',textAlign: "left", background: 'url(https://music.dapi.subox.co.kr/sound_album/21141/2204/minsize.jpg)', backgroundPositionX: '5px', backgroundPositionY: '5px', backgroundRepeat: 'no-repeat', backgroundSize: '40px', paddingLeft: 64, borderRadius: 6},"md": {},"xs": {}},item:{name:'button',text:'YOUTUBE',type:'button'}},
}

export const WRAP05_LAYOUT = {
    lg: [
        {i:"wrap-0", w: 16, h: 8, x: 4, y: 6,},
        {i:"wrap-1", w: 16, h: 38, x: 4, y: 20,},
        {i:"wrap-2", w: 16, h: 80, x: 4, y: 58,},
    ],
    md: [
        {i:"wrap-0", w: 10, h: 8, x: 1, y: 7,},
        {i:"wrap-1", w: 10, h: 38, x: 1, y: 20,},
        {i:"wrap-2", w: 10, h: 80, x: 1, y: 58,},
    ],
    xs: [
        {i:"wrap-0", w: 6, h: 8, x: 0, y: 6,},
        {i:"wrap-1", w: 6, h: 48, x: 0, y: 20,},
        {i:"wrap-2", w: 6, h: 80, x: 0, y: 75,},
    ]
}

export const WRAP05_ITEMS = {
    "wrap-0":{style:{"lg": {background: '#f5f5f5'},"md": {},"xs": {}},item:{name:'text',text:'<p><span style="font-size: 20px;"><span class="ql-cursor"></span>🎵 곡 소개</span></p>',type:'text'}},
    "wrap-1":{style:{"lg": {},"md": {},"xs": {}},item:{name:'text',text:`<p class="ql-align-center"><strong style="font-size: 20px;">We can try again</strong></p><p class="ql-align-center"><br></p><p class="ql-align-center"><span style="font-size: 18px;">신나는 일렉트로닉 음악인 동시에, 다시 시작할 수 있다는 메시지가 담긴 의미있는 곡이기도 합니다.</span></p><p class="ql-align-center"><span style="font-size: 18px;">곡의 제목처럼 '우린' 충분히 다시 시작할 수 있다. 다시 일어설 수 있다'는 메시지를 전달해주고픈 마음에 이곡을 소개해 드리고 싶네요.</span></p>`,type:'text'}},
    "wrap-2":{style:{"lg": {},"md": {},"xs": {}},item:{name:'video',type:'video',video: 'https://www.youtube.com/watch?v=oMECMveHLyw'}},
}

export const TEMP_02_LAYOUT = {
    lg: [
        { i: "0", x: 0, y: 0, w: 24, h: 48, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 0, y: 48, w: 24, h: 85, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 0, y: 133, w: 24, h: 70, resizeHandles: RESIZE_HANDLES,},
        { i: "3", x: 0, y: 203, w: 24, h: 50, resizeHandles: RESIZE_HANDLES,},
        { i: "4", x: 0, y: 253, w: 24, h: 145, resizeHandles: RESIZE_HANDLES,},
        { i: "5", x: 0, y: 386, w: 24, h: 10, resizeHandles: RESIZE_HANDLES,},
    ],
    md: [
        { i: "0", x: 0, y: 0, w: 12, h: 48, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 8, y: 48, w: 12, h: 94, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 0, y: 142, w: 12, h: 70, resizeHandles: RESIZE_HANDLES,},
        { i: "3", x: 0, y: 212, w: 12, h: 50, resizeHandles: RESIZE_HANDLES,},
        { i: "4", x: 0, y: 262, w: 12, h: 156, resizeHandles: RESIZE_HANDLES,},
        { i: "5", x: 0, y: 418, w: 12, h: 10, resizeHandles: RESIZE_HANDLES,},
    ],
    xs: [
        { i: "0", x: 0, y: 0, w: 6, h: 48, resizeHandles: RESIZE_HANDLES,},
        { i: "1", x: 0, y: 48, w: 6, h: 79, resizeHandles: RESIZE_HANDLES,},
        { i: "2", x: 0, y: 127, w: 6, h: 110, resizeHandles: RESIZE_HANDLES,},
        { i: "3", x: 0, y: 237, w: 6, h: 50, resizeHandles: RESIZE_HANDLES,},
        { i: "4", x: 0, y: 287, w: 6, h: 163, resizeHandles: RESIZE_HANDLES,},
        { i: "5", x: 0, y: 450, w: 6, h: 10, resizeHandles: RESIZE_HANDLES,},
    ]
};

export const TEMP_02_ITEM_LIST = {
    "0":{style:{"lg":{},"md":{},"xs":{}},item:{name:'header',type:'header02', layout: HEADER_02_LAYOUT, items: HEADER_02_ITEMS}},
    "1":{style:{"lg":{},"md":{},"xs":{}},item:{name:'wrap',type:'wrap', layout: WRAP02_LAYOUT, items: WRAP02_ITEMS}},
    "2":{style:{"lg":{},"md":{},"xs":{}},item:{name:'wrap',type:'wrap', layout: WRAP03_LAYOUT, items: WRAP03_ITEMS}},
    "3":{style:{"lg":{},"md":{},"xs":{}},item:{name:'wrap',type:'wrap', layout: WRAP04_LAYOUT, items: WRAP04_ITEMS}},
    "4":{style:{"lg":{},"md":{},"xs":{}},item:{name:'wrap',type:'wrap', layout: WRAP05_LAYOUT, items: WRAP05_ITEMS}},
    "5":{style:{"lg":{background:'#000',paddingTop:9},"md":{},"xs":{}},item:{name:'footer',type:'footer01', layout: FOOTER_01_LAYOUT, items: FOOTER_01_ITEMS}},
}

export const CHART_LINE = [{name:'24.10.04',list:[{key:'download',value:20},]},{name:'24.10.05',list:[{key:'download',value:30},]},{name:'24.10.06',list:[{key:'download',value:50},]},{name:'24.10.07',list:[{key:'download',value:80},]},{name:'24.10.08',list:[{key:'download',value:120},]}]
export const CHART_PIE = [{name:'download',list:[{key:'value',value:20},]},{name:'view',list:[{key:'value',value:30},]},{name:'like',list:[{key:'value',value:50},]},]
export const CHART_RADAR = [{name:'download',list:[{key:'value',value:72},]},{name:'view',list:[{key:'value',value:30},]},{name:'like',list:[{key:'value',value:50},]},{name:'star',list:[{key:'value',value:24},]},{name:'join',list:[{key:'value',value:66},]},{name:'out',list:[{key:'value',value:34},]},]
export const ACCORDION = [{title:'Q. 질문이에요',content:'A. 답변이에요'}];
export const MAP = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d197.99266021474486!2d127.17658596961655!3d37.439878943623434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca92fb0c3d64d%3A0xc6525b1ac1cf0c75!2z66Gv642w66as7JWEIOyEseuCqO2FjO2BrOuFuOuwuOumrOygkA!5e0!3m2!1sko!2skr!4v1729674077274!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'