import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Center, ContentItemWrap, ContentWrap, ItemWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import CalendarSettingItem from '../item/CalendarSettingItem';
import { inputResNumber } from '../../../utils/format/string';

export default function CalendarSettingPage({selectItem,setSelectItem,breakpoints}:any) {
    // 추가
    const addClick = () =>{
        setSelectItem('item','list',{start:'',end:'',title:'',style:{backgroundColor:'#3174ad'}});
    }
    // 삭제
    const delClick = (idx:any) =>{
        const newList = selectItem.item.list.filter((data:any,index:any)=> idx !== index);
        setSelectItem('item','newList',newList);
    }

    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>calendar</BasicText>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></> :
                        selectItem?.item?.list.map((data:any,index:any)=>{
                            return(
                                <CalendarSettingItem key={`events-${index}`} data={data} index={index} delClick={()=>delClick(index)} selectItem={selectItem} setSelectItem={setSelectItem} />
                            )
                        })
                    }
                    <Center style={{width: '100%'}}>
                        <Center onClick={addClick} style={{border:'1px solid #eee', padding:'6px 12px', cursor:'pointer'}}>스케줄 추가</Center>
                    </Center>
                    <ContentItemWrap>
                    <BasicText fontSize={16}>날짜 스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.dateStyle?.color ?? '#000000'} placeholder='false' onChange={(e:any)=>setSelectItem('dateStyle','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>크기</BasicText>
                            <input type='text' value={selectItem?.item?.dateStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>setSelectItem('dateStyle','fontSize',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>굵기</BasicText>
                            <input type='text' value={selectItem?.item?.dateStyle?.fontWeight ?? ''} placeholder='400' onChange={(e:any)=>setSelectItem('dateStyle','fontWeight',inputResNumber(e))}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>justify</BasicText>
                            <input type='text' value={selectItem?.item?.dateStyle?.justifyContent ?? ''} placeholder='center' onChange={(e:any)=>setSelectItem('dateStyle','justifyContent',e.target.value)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
`;