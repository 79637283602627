import React from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import { momentFormat } from '../../../utils/format/time';

export default function TextListItem({data,titleStyle,contentStyle,timeStyle}:any) {
    return (
        <Wrap className='no-drag'>
            <TextWrap>
                <TitleItem style={{...titleStyle}}>{data?.title ?? ''}</TitleItem>
                <UserWrap>
                    <UserItem style={{...timeStyle}}>{data?.sub ?? ''}</UserItem>
                </UserWrap>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;

const TextWrap = styled(RowBetween)`
    gap: 6px;
`;
const TitleItem = styled.div`
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    width: 100%;
`;

const UserWrap = styled(Row)`
    flex-shrink: 0;
    width: auto;
`;

const UserItem = styled.div`
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
`;