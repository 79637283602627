import React from 'react'
import { useNavigate } from 'react-router';
import styled from 'styled-components'
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function ButtonItem({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    const navigate = useNavigate();
    const clickEvent = () =>{
        if(selectItem?.item?.url === undefined) return;
        // urlType: 1 내부 / 2 외부
        if(selectItem?.item?.url?.urlType === '1'){
            navigate(selectItem?.item?.url?.innerUrl);
        }else if(selectItem?.item?.url?.urlType === '2'){
            window.open(selectItem?.item?.url?.outUrl);
        }
    }
    
    return (
        <BtnWrap className='no-item-drag' style={{...selectItem?.style[breakpoints ?? 'lg']}} onClick={clickEvent}>
            {selectItem?.item?.module === 'update' ? selectItem?.item?.text ?? selectItem?.item?.name : selectItem?.item?.text}
        </BtnWrap>
    )
}

const BtnWrap = styled.button`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;