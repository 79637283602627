import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function FormSettingItem({allList,data,index,setSelectItem}:any) {
    const [answer,setAnswer] = useState('');

    useEffect(()=>{
        if(data?.list === undefined) return;
        const newList = data?.list.map((item:any)=>item.name).join(',');
        if(answer === ''){
            setAnswer(newList);
        }
    },[data?.list,answer])

    const typeChange = (e:any) =>{
        const val = e.target.value;
        const newItem = {...data,type:val,list:[]};
        setAnswer('');
        const newList = allList.map((item:any)=> item.id === data.id ? newItem : item);
        setSelectItem('item','newList',newList);
    }
    
    const titleChange = (e:any)=>{
        const val = e.target.value;
        const newItem = {...data,title:val};
        const newList = allList.map((item:any)=> item.id === data.id ? newItem : item);
        setSelectItem('item','newList',newList);
    }

    const answerChange = (e:any) =>{
        const val = e.target.value;
        const valArr = val.split(',').filter((arr:any)=>arr);
        setAnswer(val);
        const newArr = valArr.map((item:any,idx:any)=>({id:idx+1,name:item}))
        const newItem = {...data,list:newArr};
        const newList = allList.map((item:any)=> item.id === data.id ? newItem : item);
        setSelectItem('item','newList',newList);
    }

    return (
        <ContentItemWrap>
            <BasicText>{index+1}</BasicText>
            <ItemWrap pw={'30%'}>
                <BasicText>타입</BasicText>
                <select value={data?.type ?? 'text'} onChange={typeChange}>
                    <option>text</option>
                    <option>radio</option>
                    <option>radioList</option>
                    <option>check</option>
                    <option>select</option>
                    <option>img</option>
                </select>
            </ItemWrap>
            <ItemWrap pw='100%'>
                <BasicText>질문</BasicText>
                <input type='text' value={data?.title ?? ''} onChange={titleChange}></input>
            </ItemWrap>
            <ItemWrap pw='100%'>
                <BasicText>답변</BasicText>
                {data?.type === 'text' && <BasicText>텍스트</BasicText>}
                {(data?.type === 'radio' || data?.type === 'radioList' || data?.type === 'check' || data?.type === 'select') &&
                    <input type="text" value={answer} onChange={answerChange}/>
                }
            </ItemWrap>
        </ContentItemWrap>
    )
}

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    flex-wrap: ${({pw}) => pw === '100%' ? 'wrap' : 'nowrap'};
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>select{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;