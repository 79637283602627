import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { fileTypeRes, inputResNumber } from '../../../utils/format/string';
import { addTheme } from '../../../style/addTheme';
import useAction from '../../../utils/custom-hooks/common/useAction';

export default function SettingTextPart({selectItem,setSelectItem,breakpoints}:any) {
    const [item,setItem] = useState({
        fileType: '1',
        linkUrl: '',
        fileData: '',
        bgColor: '#ffffff',
    })

    const [style,setStyle] = useState<any>({});
    const fileRef = useRef<any>(null);
    const {imgGetUrl} = useAction();
    
    useEffect(()=>{
        setStyle(selectItem.style);
    },[selectItem.style]);

    useEffect(()=>{
        let val = '';
        if(item.fileType === '1' ){
            val = item.linkUrl === '' ? '' : `url(${item.linkUrl})`;
        }else if(item.fileType === '2'){
            val = item.fileData === '' ? '' : `url(${item.fileData})`;
        }else if(item.fileType === '3'){
            val = item.bgColor;
        }
        if(val === '') return;
        setSelectItem('style','background',val);
        // setSelectItem((obj:any)=>({
        //     ...obj,style:{...obj.style,[breakpoints]:{...obj.style[breakpoints], background: val}}
        // }))
    },[item.fileType,item.linkUrl,item.fileData, item.bgColor])

    // 타입 변경
    const fileSelectTypeChange = (e:any) =>{
        const val = e.target.value
        setItem((obj:any)=>({
            ...obj,fileType: val
        }))
    }

    // 링크로 이미지 입력
    const urlLinkImgChange = (e:any) =>{
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '1',
            linkUrl: val, 
        }))
    }

    // 파일 선택
    const fileImgChange = (e:any) =>{
        const file = e.target.files[0];
        if(file === undefined) return;
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg' || fileType === 'gif'){
            if (file.size > 10 * 1024 * 1024) {
                alert('파일의 용량이 10MB를 초과하였습니다.')
                fileRef.current.value = null;
            }else{
                const reader = new FileReader();
                reader.onloadend = async() => {
                    const img = await imgGetUrl(reader.result);
                    if(img.file_path === '') return;
                    setItem((obj:any)=>({
                        ...obj,
                        file: file,
                        fileType: '2',
                        fileData: img.file_path, 
                    }))
                };
                reader.readAsDataURL(file);
            }
        }else{
            alert('파일은 이미지 확장자인 png, jpg, gif 만 가능합니다.');
            fileRef.current.value = null;
        }
    }
    
    // 배경색 변경
    const bgColorChange = (e:any) => {
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '3',
            bgColor: val, 
        }))
    }

    // 스타일 변경
    const styleChange = (e:any,type?:string)=>{
        const val = e.target.value;
        let valRes = val;
        if(type === 'number'){
            valRes = Number(inputResNumber(e))
        }
        const name = e.target.name;
        setSelectItem('style',name,valRes);
    }
    
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>텍스트</BasicText>
                    <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" name="img-type" value='1' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item.fileType === '1'}/>
                            url링크
                        </label>
                        <input type='text' name='img-url' value={item.linkUrl} onChange={urlLinkImgChange}></input>
                    </ItemWrap>
                    <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" name="img-type" value='2' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item.fileType === '2'}/>
                            파일선택
                        </label>
                        <input ref={fileRef} name='img-file' type='file' accept='.png,.jpg,.gif' onChange={fileImgChange}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <label>
                            <input type="radio" name="img-type" value='3' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item.fileType === '3'}/>
                            배경색 선택
                        </label>
                        <input type='color' name='background' value={item.bgColor ?? '#ffffff'} onChange={(e:any)=>bgColorChange(e)}></input>
                    </ItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>배경 스타일</BasicText>
                    <ItemWrap pw={'30%'}>
                        <BasicText>position-x</BasicText>
                        <input type='text' name='backgroundPositionX' value={style[breakpoints]?.backgroundPositionX ?? ''} placeholder='center' onChange={(e:any)=>styleChange(e)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>position-y</BasicText>
                        <input type='text' name='backgroundPositionY' value={style[breakpoints]?.backgroundPositionY ?? ''} placeholder='center' onChange={(e:any)=>styleChange(e)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>repeat</BasicText>
                        <input type='text' name='backgroundRepeat' value={style[breakpoints]?.backgroundRepeat ?? ''} placeholder='no-repeat' onChange={(e:any)=>styleChange(e)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>size</BasicText>
                        <input type='text' name='backgroundSize' value={style[breakpoints]?.backgroundSize ?? ''} placeholder='contain' onChange={(e:any)=>styleChange(e)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>radius</BasicText>
                        <input type='text' name='borderRadius' value={style[breakpoints]?.borderRadius ?? ''} placeholder='0' onChange={(e:any)=>styleChange(e,'number')}></input>px
                    </ItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${addTheme.gap}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;