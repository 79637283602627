import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { CHART_LINE } from '../../../data/layout';

export default function ChartLinePage({item,i,parent,breakpoints}:any) {
    const [data,setData] = useState<any>([]);
    const [uniqueKeys,setUniqueKeys] = useState<any>([]);
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    useEffect(()=>{
        const list = selectItem?.item?.list ?? CHART_LINE;
        const newList = list.map((item:any,index:any)=>{
            const itemList = item.list.map((obj:any)=>({[obj.key]:obj.value}))
            const newObj = itemList.reduce((acc:any, curr:any) => {
                return { ...acc, ...curr };
            }, {});
            return {name:item.name,...newObj}
        })
        setData(newList);
        const uniqueKey = list.reduce((acc:any, item:any) => {
            item.list.forEach((subItem:any) => {
                if (!acc.includes(subItem.key)) {
                    acc.push(subItem.key);
                }
            });
            return acc;
        }, []);
        setUniqueKeys(uniqueKey);
    },[selectItem?.item?.list]);
    
    return (
        <Wrap bg={selectItem?.item?.bg ?? "#ffffff"}>
            <ResponsiveContainer width="100%" height='100%'>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" stroke={selectItem?.item?.gridColor ?? '#ccc'}/>
                    <XAxis dataKey="name" stroke={selectItem?.item?.xColor ?? "#000000"}/>
                    <YAxis stroke={selectItem?.item?.yColor ?? "#000000"}/>
                    <Tooltip />
                    <Legend />
                    {uniqueKeys === undefined || uniqueKeys.length === 0 ?
                        <></> :
                        uniqueKeys.map((line:any,idx:any)=>{
                            return(
                                <Line key={`line-${idx}`} type="monotone" dataKey={line} stroke={(selectItem?.item?.lineColor === undefined || selectItem?.item?.lineColor.length === 0) ? "#8884d8": selectItem?.item?.lineColor[idx] } />
                            )
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        </Wrap>
    )
}

const Wrap = styled.div<{bg?:string}>`
    width: 100%;
    height: 100%;
    background-color: ${({bg}) => bg ?? '#ffffff'};
`;