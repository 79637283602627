import React from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import AccordionListItem from '../item/AccordionListItem';

export default function AccordionPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;

    return (
        <Wrap>
            {!(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0) &&
                selectItem?.item?.list.map((data:any,index:any)=>{
                    return(
                        <AccordionListItem key={`ac-${index}`} data={data} selectItem={selectItem}/>
                    )
                })
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;
