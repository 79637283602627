import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { inputResNumber } from '../../../utils/format/string';

export default function ChartLineSettingItemPart({selectItem,setSelectItem,breakpoints,index,data}:any) {
    const listOptionChange = (val:any,name:string) =>{
        const newData = {
            ...data,
            [name]:val,
        }
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newData : item));
        setSelectItem('item','newList',newList);
    }
    
    const dataChange = (val:any,name:string,item:any,idx:any) =>{
        const newData = {
            ...item,
            [name]: val
        }
        const newItem = data?.list.map((it:any,id:any)=>(id===idx ? newData : it));
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? {...item,['list']:newItem} : item));
        setSelectItem('item','newList',newList);
    }

    const addData = () =>{
        const newData = {
            name: data?.name,
            list: [...data.list,{key: selectItem?.item?.type !== 'chartPie' ? '' : 'value',value: ''}]
        }
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newData : item));
        setSelectItem('item','newList',newList);
    }
    
    const delData = () =>{
        const newData = {
            name: data?.name,
            list: data.list.slice(0, -1)
        }
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newData : item));
        setSelectItem('item','newList',newList);
    }

    return (
        <>
            <ContentItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>name</BasicText>
                    <input type='text' name='name' value={data?.name ?? ''} placeholder='name' onChange={(e:any)=>listOptionChange(e.target.value,'name')}></input>
                </ItemWrap>
            </ContentItemWrap>
            <ContentItemWrap>
                <BasicText>data</BasicText>
                {data?.list === undefined || data?.list.length === 0 ?
                    <></> :
                    data?.list.map((item:any,idx:any)=>{
                        return(
                            <React.Fragment key={`data-${idx}`}>
                                <InputWrap >
                                    {selectItem?.item?.type !== 'chartPie' || selectItem?.item?.type !== 'chartRadar' &&
                                        <input value={item.key ?? ''} onChange={(e:any)=>dataChange(e.target.value,'key',item,idx)}></input>
                                    }
                                    <input value={item.value ?? ''} onChange={(e:any)=>dataChange(inputResNumber(e),'value',item,idx)}></input>
                                </InputWrap>
                                {selectItem?.item?.type === 'chartPie' &&
                                    <ItemWrap key={`line-color${idx}`} pw={'30%'}>
                                        <BasicText>파이 컬러</BasicText>
                                        <input type='color' name='color' value={item?.pieColor ?? '#8884d8'} onChange={(e:any)=>dataChange(e.target.value,'pieColor',item,idx)}></input>
                                    </ItemWrap>
                                }
                            </React.Fragment>
                        )
                    })
                }
                {selectItem?.item?.type !== 'chartPie' || selectItem?.item?.type !== 'chartRadar' &&
                    <BtnWrap>
                        <Center onClick={addData}>+</Center>
                        {data?.list === undefined || data?.list.length <= 1 ? <></> :
                            <Center onClick={delData}>-</Center>
                        }
                    </BtnWrap>
                }
            </ContentItemWrap>
            
        </>  
    )
}

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;

const InputWrap = styled(Row)`
    gap: 10px;
    & input:nth-of-type(1){
        width: 50%;
    }
    & input{
        border: 1px solid #333;
        padding: 4px 10px;
        border-radius: 4px;
        width: 50%;
    }
`;

const BtnWrap = styled(Center)`
    width: 100%;
    gap: 15px;
    &>div{
        width: 36px;
        height: 36px;
        border: 1px solid #ddd;
        font-size: 20px;
        cursor: pointer;
    }
`;