import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FromPart from '../part/FromPart';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function FormPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined) return;
        setList(selectItem?.item?.list);
    },[selectItem?.item?.list])

    // const [list,setList] = useState<any>([
    //     {id: 1, title: '값을 입력해주세요.', type:'text', val: ''},
    //     {id: 2, title: '값을 선택해주세요.', type:'radioList', list:[{id:1,name:'자바'},{id:2,name:'자바스크립트'},{id:3,name:'리액트'}], val: 1},
    //     {id: 3, title: '값을 선택해주세요.(중복가능)', type:'check', list:[{id:1,name:'자바'},{id:2,name:'자바스크립트'},{id:3,name:'리액트'}], val: []},
    //     {id: 4, title: '값을 선택해주세여.', type:'radio', list:[{id:1,name:'자바'},{id:2,name:'자바스크리트'}], val: 1},
    //     {id: 5, title: '값을 선택해주세요.', type:'select', list:[{id:1,name:'자바'},{id:2,name:'자바스크리트'}], val: 1},
    //     {id: 6, title: '이미지를 선택해주세요.', type:'img', val: ''},
    // ]);

    // useEffect(()=>{
    //     getList() 
    // },[])

    // const getList = async() =>{
    //     setList((arr:any)=>arr.map((item:any)=>item.id === 1 ? {...item,val:'hihi'} : item))
    // }

    const valChange = (data:any,val:any) =>{
        const newItem = {...data,val:val}
        setList((arr:any)=>arr.map((item:any)=>item.id === data.id ? newItem : item));
    }
    
    return (
        <Wrap className='no-item-drag'>
            {list === undefined || list.length === 0 ?
                <></> :
                list.map((data:any,index:any)=>{
                    return(
                        <FromPart key={`from-${index}`} data={data} valChange={valChange} />
                    )
                })
            }
        </Wrap>
    )
}

const Wrap = styled.div``;