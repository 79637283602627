import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useAction from '../../../utils/custom-hooks/common/useAction';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function TablePage01({item,i,parent,breakpoints}:any) {
    const {textToTable} = useAction();
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [header,setHeader] = useState<any>([]);
    const [values,setValues] = useState<any>([]);
    const border = selectItem?.item?.borderStyle === undefined ? {borderStyle: 'solid', borderBottomWidth: '1px', borderColor: '#e0e0e0'} : {borderColor: selectItem?.item?.borderStyle?.borderColor ?? '#e0e0e0', borderStyle: selectItem?.item?.borderStyle?.borderStyle ?? 'solid', borderTopWidth: `${selectItem?.item?.borderStyle?.borderTopWidth ?? 0}px`, borderRightWidth: `${selectItem?.item?.borderStyle?.borderRightWidth ?? 0}px`, borderBottomWidth: `${selectItem?.item?.borderStyle?.borderBottomWidth ?? 1}px`, borderLeftWidth: `${selectItem?.item?.borderStyle?.borderLeftWidth ?? 0}px`};
    
    useEffect(()=>{
        if(selectItem?.item?.table === undefined || selectItem?.item?.table == '') return;
        const table = textToTable(selectItem?.item?.table);
        setHeader(table.headers);
        setValues(table.data);
    },[selectItem?.item?.table]);
    
    return (
        <Wrap>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {!(header === undefined || header.length === 0) &&
                                header.map((data:any,index:any)=>{
                                    return(
                                        <TableCell key={`table-header-${index}`} sx={{...border,...selectItem?.item?.titleStyle,fontSize:`${selectItem?.item?.titleStyle?.fontSize ?? 16}px`}} >{data}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!(values === undefined || values.length === 0) &&
                            values.map((data:any,index:any)=>{
                                return(
                                    <TableRow key={`table-values-${index}`}>
                                        {header.map((title:any,idx:any)=>{
                                            return(
                                                <TableCell key={`table-values${index}-item-${idx}`} sx={{...border,...selectItem?.item?.valueStyle,fontSize:`${selectItem?.item?.valueStyle?.fontSize ?? 16}px`}} >{data[title]}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;

