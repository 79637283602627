import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import WebzineListItem from '../item/WebzineListItem';
import { LIST_ITEM } from './listData';
import _ from 'lodash';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function WebzineListPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;

    const [totalList,setTotalList] = useState<any>([]);
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setList([LIST_ITEM]);
        }else{
            setList(selectItem?.item?.list)
        }
    },[selectItem?.item?.list])

    useEffect(()=>{
        if(totalList.length === 0) return;
        setList(totalList[page-1]);
    },[totalList,page])
    
    return (
        <Wrap>
            <ContentWrap style={{...selectItem?.item.listStyle}}>
                {list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <WebzineListItem 
                                key={`list1-${index}`} 
                                data={data}
                                titleStyle={selectItem?.item?.titleStyle}
                                imgStyle={selectItem?.item?.imgStyle}
                                contentStyle={selectItem?.item?.contentStyle}
                                listStyle={selectItem?.item?.listStyle}
                                timeStyle={selectItem?.item?.timeStyle}
                            />
                        )
                    })
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;
const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 8px;
    align-items: start;
`;