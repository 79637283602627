import React from 'react'
import styled from 'styled-components'
import { ContentItemWrap, ContentWrap, ItemWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function CountdownSettingPart({selectItem,setSelectItem,breakpoints}:any) {
    const nowTime = new Date();
    const minTime = `${nowTime.getFullYear()}-${nowTime.getMonth()+1}-${nowTime.getDate()}T${nowTime.getHours()<10 ? `0${nowTime.getHours()}` : nowTime.getHours()}:${nowTime.getMinutes() < 10 ? `0${nowTime.getMinutes()}`:nowTime.getMinutes()}`;
    
    const timeChange = (e:any) =>{
        setSelectItem('item','countdown',e.target.value);
    }
    
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>countdown</BasicText>
                    <ContentItemWrap>
                        <BasicText>시간설정</BasicText>
                        <ItemWrap>
                            <input 
                                type='datetime-local' 
                                onChange={timeChange} 
                                value={selectItem?.item?.countdown ?? ''}
                                min={minTime}
                            ></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>카운트 스타일</BasicText>
                        <ItemWrap pw='30%'>
                            <BasicText>색</BasicText>
                            <input type='color' value={selectItem?.item?.count?.color ?? '#000000'} onChange={(e:any)=>setSelectItem('count','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw='30%'>
                            <BasicText>폰트크기</BasicText>
                            <input type='text' value={selectItem?.item?.count?.fontSize ?? '16'} onChange={(e:any)=>setSelectItem('count','fontSize',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw='30%'>
                            <BasicText>align</BasicText>
                            <input type='text' value={selectItem?.item?.count?.alignItems ?? 'center'} onChange={(e:any)=>setSelectItem('count','alignItems',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw='30%'>
                            <BasicText>justify</BasicText>
                            <input type='text' value={selectItem?.item?.count?.justifyContent ?? 'center'} onChange={(e:any)=>setSelectItem('count','justifyContent',e.target.value)}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>완료 스타일</BasicText>
                        <ContentItemWrap>
                            <ItemWrap pw='30%'>
                                <BasicText>완료내용</BasicText>
                                <input type='text' value={selectItem?.item?.done ?? '완료'} onChange={(e:any)=>setSelectItem('item','done',e.target.value)}></input>
                            </ItemWrap>
                            <ItemWrap pw='30%'>
                                <BasicText>색</BasicText>
                                <input type='color' value={selectItem?.item?.countDone?.color ?? '#000000'} onChange={(e:any)=>setSelectItem('countDone','color',e.target.value)}></input>
                            </ItemWrap>
                            <ItemWrap pw='30%'>
                                <BasicText>폰트크기</BasicText>
                                <input type='text' value={selectItem?.item?.countDone?.fontSize ?? '16'} onChange={(e:any)=>setSelectItem('countDone','fontSize',e.target.value)}></input>
                            </ItemWrap>
                            <ItemWrap pw='30%'>
                                <BasicText>align</BasicText>
                                <input type='text' value={selectItem?.item?.countDone?.alignItems ?? 'center'} onChange={(e:any)=>setSelectItem('countDone','alignItems',e.target.value)}></input>
                            </ItemWrap>
                            <ItemWrap pw='30%'>
                                <BasicText>justify</BasicText>
                                <input type='text' value={selectItem?.item?.countDone?.justifyContent ?? 'center'} onChange={(e:any)=>setSelectItem('countDone','justifyContent',e.target.value)}></input>
                            </ItemWrap>
                        </ContentItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    ) 
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
`;