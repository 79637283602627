import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import RectItem from '../../common/item/RectItem';
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import { FaRegCirclePlay } from "react-icons/fa6";
import { addTheme } from '../../../style/addTheme';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { LIST_ITEM } from '../../list/page/listData';
import { FaRegPlayCircle } from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import ReactPlayer from 'react-player';

export default function PlayerPage02({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [list,setList] = useState<any>([]);
    const [playIndex,setPlayIndex] = useState(0);
    const [playState,setPlayState] = useState(false);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setList([LIST_ITEM]);
        }else{
            setList(selectItem?.item?.list)
        }
    },[selectItem?.item?.list]);

    const btnClick = (idx:any) =>{
        if(playIndex === idx){
            setPlayState(!playState);
        }else{
            setPlayIndex(idx);
            setPlayState(true);
        }
    }
    
    return (
        <Wrap>
            <ImgWrap align={selectItem?.item?.imgStyle?.justifyContent}>
                <ImgInnerWrap style={{...selectItem?.item?.imgStyle,width:selectItem?.item?.imgStyle?.width === undefined ? '100%' : `${selectItem?.item?.imgStyle.width}%`}}>
                    <RectItem imgUrl={selectItem?.item?.img === '' || selectItem?.item?.img === undefined ? '/img/cafe/logo2.PNG' : selectItem?.item.img}></RectItem>
                </ImgInnerWrap>
            </ImgWrap>
            {list === undefined || list.length === 0 ?
                <></> :
                <>
                    <ReactPlayer
                        url={list[playIndex]?.audio ?? ''}
                        playing={playState}
                        pip={false}
                        onEnded={() => setPlayState(false)}
                        width={'0'}
                        height={'0'}
                    />
                    <ContentWrap>
                        <ItemWrap>
                            <ItemTitleWrap>
                                <ItemTitleItem1 style={{...selectItem?.item?.titleStyle}}>{list[0].title}</ItemTitleItem1>
                                <ItemTitleItem2 style={{...selectItem?.item?.timeStyle}}>{list[0].sub}</ItemTitleItem2>
                            </ItemTitleWrap>
                            <ItemIconWrap className='no-item-drag'>
                                {playIndex === 0 ? 
                                    playState ? 
                                        <FaRegCirclePause onClick={()=>btnClick(0)} color={addTheme.color} size={27}></FaRegCirclePause> :
                                        <FaRegPlayCircle onClick={()=>btnClick(0)} color={addTheme.color} size={27}></FaRegPlayCircle>
                                    :
                                    <FaRegPlayCircle onClick={()=>btnClick(0)} color={addTheme.color} size={27}></FaRegPlayCircle>
                                }
                            </ItemIconWrap>
                        </ItemWrap>
                        <SubWrap>
                            {list.map((item:any,idx:any)=>{
                                if(idx === 0) return
                                return(
                                    <ItemWrap key={`player02-${idx}`}>
                                        <ItemTitleWrap>
                                            <ItemTitleItem3>{item?.title}</ItemTitleItem3>
                                        </ItemTitleWrap>
                                        <ItemIconWrap className='no-item-drag'>
                                            {playIndex === idx ? 
                                                playState ? 
                                                    <FaRegCirclePause onClick={()=>btnClick(idx)} color={addTheme.color} size={22}></FaRegCirclePause> :
                                                    <FaRegPlayCircle onClick={()=>btnClick(idx)} color={addTheme.color} size={22}></FaRegPlayCircle>
                                                :
                                                <FaRegPlayCircle onClick={()=>btnClick(idx)} color={addTheme.color} size={22}></FaRegPlayCircle>
                                            }
                                        </ItemIconWrap>
                                    </ItemWrap>
                                )
                            })}
                        </SubWrap>
                    </ContentWrap>
                </>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;
// const ImgWrap = styled.div`
//     width: 100%;
//     max-width: 240px;
//     overflow: hidden;
//     border-radius: 6px;
//     margin: 0 auto;
// `;
const ImgWrap = styled(Center)<{align?:string}>`
    justify-content: ${({align}) => align ?? 'center'};
`;
const ImgInnerWrap = styled(Center)`
    overflow: hidden;
`;
const ContentWrap = styled.div`
    margin-top: 12px;
`;

const ItemWrap = styled(RowBetween)``;
const ItemTitleWrap = styled.div``;
const ItemTitleItem1 = styled.p`
    font-weight: 700;
    font-size: 20px;
`;
const ItemTitleItem2 = styled.p`
    font-size: 14px;
    margin-top: 3px;
`;
const ItemTitleItem3 = styled.p`
    font-size: 16px;
`;
const ItemIconWrap = styled(Row)`
    width: auto;
    gap: 6px;
`;

const SubWrap = styled(Row)`
    flex-direction: column;
    margin-top: 12px;
    border-top: 1px solid #e2e2e2;
    padding-top: 12px;
    gap: 10px;
`;