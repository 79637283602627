import React from 'react'
import { Center, ContentItemWrap, ItemWrap, RowBetween } from '../../../style/basic/commonStyle'
import BasicText from '../../basic/BasicText'
import styled from 'styled-components'

export default function CalendarSettingItem({data,selectItem,setSelectItem,index,delClick}:any) {
    const update = (name:any,val:any,type?:any) =>{
        let newData = {}
        if(type === 'style'){
            newData = {
                ...data,
                style:{
                    ...data.style,
                    [name]:val,
                }
            }
        }else{
            newData = {
                ...data,
                [name]:val
            }
        }
        const newList = selectItem.item.list.map((item:any,idx:any)=>idx === index ? newData : item);
        setSelectItem('item','newList',newList);
    }

    return (
        <ContentItemWrap>
            <RowBetween>
                <BasicText>{index+1}. 스케줄</BasicText>
                <DelBtn onClick={delClick}>삭제</DelBtn>
            </RowBetween>
            <ItemWrap pw='30%'>
                <BasicText>시작</BasicText>
                <input type='date' value={data?.start} onChange={(e:any)=>update('start',e.target.value)}></input>
            </ItemWrap>
            <ItemWrap pw='30%'>
                <BasicText>종료</BasicText>
                <input type='date' min={data?.start} value={data?.end} onChange={(e:any)=>update('end',e.target.value)}></input>
            </ItemWrap>
            <ItemWrap pw='30%'>
                <BasicText>타이틀</BasicText>
                <input type='text' value={data?.title} onChange={(e:any)=>update('title',e.target.value)}></input>
            </ItemWrap>
            <ItemWrap pw='30%'>
                <BasicText>색</BasicText>
                <input type='color' value={data?.style?.backgroundColor} onChange={(e:any)=>update('backgroundColor',e.target.value,'style')}></input>
            </ItemWrap>
            <ItemWrap pw='30%'>
                <BasicText>글자색</BasicText>
                <input type='color' value={data?.style?.color ?? '#ffffff'} onChange={(e:any)=>update('color',e.target.value,'style')}></input>
            </ItemWrap>
        </ContentItemWrap>
    )
}

const DelBtn = styled(Center)``;