import React from 'react'
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import HeaderPage01 from '../../header/page/HeaderPage01';
import FooterPage01 from '../../footer/page/FooterPage01';
import { FaArrowsAlt } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { setGlobalSelectItem,globalSelectItemResetCreator } from '../../../store/common/globalSelectItem';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { useGlobalDrag } from '../../../utils/custom-hooks/common/useGlobalDrag';
import WrapPage01 from '../../wrap/page/WrapPage01';
import useAction from '../../../utils/custom-hooks/common/useAction';
import { setGlobalLayout } from '../../../store/common/globalLayout';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function LayoutPart({layouts,setLayouts,itemList,setItemList,type}:any) {
    const dispatch = useDispatch();
    const selectItem = useGlobalSelectItem();
    const addDrag = useGlobalDrag();
    const {selectItemSave} = useAction();
    if(layouts === undefined) return(<></>) ;
    
    // 레이아웃 변경
    const layoutChange = (a:any,b:any) =>{
        const lastA = a[a.length - 1];
        if(lastA?.i === '__dropping-elem__') return
        setLayouts((obj:any)=>({...obj,layouts:b}));
        dispatch(setGlobalLayout({
            layouts:b,
        }))
    }
    // 현재 미디어 쿼리 (ex lg md) 
    const onBreakPointChange = (breakpoint:any) => {
        setLayouts((obj:any)=>({...obj,breakpoints:breakpoint}))
        dispatch(setGlobalLayout({
            breakpoints:breakpoint
        }))
    }

    // 드래그로 추가
    const onDrop = (_layout:any, layoutItem:any, _event:any) => {
        if(addDrag?.item === undefined) return;
        if(!(addDrag.item.type.includes('wrap') || addDrag.item.type.includes('header') || addDrag.item.type.includes('footer'))) return;
        if(selectItem.i !== ''){
            dispatch(globalSelectItemResetCreator());
            if(window.confirm('변경사항을 저장하겠습니까?')){
                // 이전 selectItem의 내용을 setItemList 수정
                selectItemSave(selectItem,setItemList);
            }
        }
        const itemArr = layouts.layouts[layouts.breakpoints];
        const i = itemArr === undefined || itemArr.length === 0 ? '1' : (Number(itemArr[itemArr.length-1].i) + 1).toString();
        
        setItemList((obj:any)=>{
            return {
                ...obj,
                [i]:{item:{...addDrag.item},style:{...addDrag.style}}
            }
        })
        const h = addDrag.item.type.includes('wrap') ? 100 : addDrag.item.type === 'header02' ? 34 : 10
        const newItem = {
            i: i,
            x: layoutItem.x,
            y: layoutItem.y-1,
            w: layoutItem.w+24,
            h: layoutItem.h+h,
        }
        setLayouts((obj:any)=>{
            return {
                ...obj,
                layouts: {
                    ...obj.layouts,
                    [obj.breakpoints]: [...obj.layouts[obj.breakpoints],newItem],
                }
            }
        })
    };

    // header,wrap,footer 선택
    const selectAction = (i:string,el:any) =>{
        if(selectItem.i !== ""){
            if(selectItem.i === i) return;
            if(window.confirm('변경사항을 저장하겠습니까?')){
                // 이전 selectItem의 내용을 setItemList 수정
                selectItemSave(selectItem,setItemList);
            }
        }
        dispatch(setGlobalSelectItem({
            i:i,
            item: el.item,
            style: el.style,
            parent: '',
        }))
    }

    // 삭제
    const delAction = (i:string) =>{
        if(window.confirm('삭제하시겠습니까?')){
            if(selectItem.i === i){
                dispatch(globalSelectItemResetCreator());
            }
            setItemList((obj:any)=>{
                const newObj = obj;
                delete newObj[i];
                return newObj
            })
            setLayouts((obj:any)=>{
                return {
                    ...obj,
                    layouts: {
                        ...obj.layouts,
                        [obj.breakpoints]: obj.layouts[obj.breakpoints].filter((item:any)=>item.i !== i)
                    }
                }
            })
        }
    }
    
    return (
        <Wrap>
            <ResponsiveGridLayout
                className="layout"
                layouts={layouts.layouts}
                breakpoints={addTheme.size}
                cols={{ lg: 24, md:12, xs: 6 }}
                onLayoutChange={layoutChange}
                onBreakpointChange={onBreakPointChange}
                rowHeight={addTheme.wrapRowHeight}
                margin={[0,0]}
                // 드래그 안되게하는 부분
                draggableCancel='.no-drag'
                // 드래그로 아이템 추가
                onDrop={onDrop}
                isDroppable
                // 중간에 추가할수있음(자유롭게)
                // compactType={null}
                // 겹치기(배열순서에 따라 겹쳐지는 순서가 변경)
                // allowOverlap={true}
                isResizable={type === 'view' ? false : true} 
                isDraggable={type === 'view' ? false : true}
            >
                {layouts.layouts[layouts.breakpoints]?.map((el:any) => {
                    return(
                        <ContentItemWrap key={el.i} selectOn={el.i === selectItem.i || addDrag.item !== undefined} style={{zIndex: (el.i === selectItem?.parent) ? 100 : 2}} {...el}>
                            <BtnWrap className={type === 'view' ? '' : 'wrap-btn'}>
                                <FaArrowsAlt color='#000' size={18}></FaArrowsAlt>
                                <IoSettingsSharp color='#000' size={18} className={'no-drag'} onClick={()=>selectAction(el.i,itemList[el.i])} />
                                <MdDelete color='#000' size={20} className={'no-drag'} onClick={()=>delAction(el.i)}/>
                            </BtnWrap>
                            {itemList[el.i]?.item?.type.includes('header') &&
                                <ContentItemInnerWrap ov={el.i == selectItem?.parent} style={el.i === selectItem?.i ? {...selectItem.style[layouts?.breakpoints ?? 'lg']} : {...itemList[el.i]?.style[layouts?.breakpoints ?? 'lg']}}>
                                    <HeaderPage01 type={type} breakPoint={layouts.breakpoints} addDrag={addDrag} parent={el.i} item={itemList[el.i]?.item} layout={itemList[el.i]?.item?.layout} setLayouts={setLayouts} setItemList={setItemList} items={itemList[el.i]?.item?.items} />
                                </ContentItemInnerWrap>
                            }
                            {itemList[el.i]?.item?.type.includes('wrap') && 
                                <ContentItemInnerWrap ov={el.i == selectItem?.parent} style={el.i === selectItem?.i ? {...selectItem.style[layouts?.breakpoints ?? 'lg']} : {...itemList[el.i]?.style[layouts?.breakpoints ?? 'lg']}}>
                                    <WrapPage01 type={type} breakPoint={layouts.breakpoints} addDrag={addDrag} parent={el.i} item={itemList[el.i]?.item} layout={itemList[el.i]?.item?.layout} setLayouts={setLayouts} setItemList={setItemList} items={itemList[el.i]?.item?.items} test={itemList[el.i]} />
                                </ContentItemInnerWrap>
                            }
                            {itemList[el.i]?.item?.type === 'footer01' && 
                                <ContentItemInnerWrap ov={el.i == selectItem?.parent} style={el.i === selectItem?.i ? {...selectItem.style[layouts?.breakpoints ?? 'lg']} : {...itemList[el.i]?.style[layouts?.breakpoints ?? 'lg']}}>
                                    <FooterPage01 type={type} breakPoint={layouts.breakpoints} addDrag={addDrag} parent={el.i} item={itemList[el.i]?.item} layout={itemList[el.i]?.item?.layout} setLayouts={setLayouts} setItemList={setItemList} items={itemList[el.i]?.item?.items} />
                                </ContentItemInnerWrap>
                            }
                        </ContentItemWrap>
                    )
                })}
            </ResponsiveGridLayout>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    & .layout{
        min-height: ${addTheme.wrapRowHeight}px;
    }
    & .layout_inner{
        min-height: ${addTheme.wrapRowHeight}px;
    }
`;

const ContentItemWrap = styled(Center)<{selectOn?:boolean}>`
    position: relative;
    &:hover>.wrap-btn{
        display: flex;
    }
    outline: 1px solid ${({selectOn}) => selectOn ? 'red': 'transparent'};
`;

const ContentItemInnerWrap = styled.div<{ov?:boolean}>`
    width: 100%;
    height: 100%;
    overflow: ${({ov}) => ov ? 'visible' : 'hidden'};
`;

const BtnWrap = styled(Center)`
    display: none;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    z-index: 10;
    &>svg{
        width: 16px;
        height: 16px;
        background-color: #fff;
        cursor: pointer;
        padding: 1px;
    }
`;