import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import TextFormItem from '../item/TextFormItem';
import RadioListFromItem from '../item/RadioListFromItem';
import CheckFromItem from '../item/CheckFromItem';
import SelectFormItem from '../item/SelectFormItem';
import ImgFormItem from '../item/ImgFormItem';

export default function FromPart({data,valChange}:any) {
    
    return (
        <Wrap>
            <TitleWrap>
                <BasicText>Q. {data.title}</BasicText>
            </TitleWrap>
            <ContentWrap>
                {data?.type === 'text' && <TextFormItem data={data} valChange={valChange} />}
                {(data?.type === 'radioList' || data?.type === 'radio') && <RadioListFromItem data={data} valChange={valChange} /> }
                {data?.type === 'check' && <CheckFromItem data={data} valChange={valChange} /> }
                {data?.type === 'select' && <SelectFormItem data={data} valChange={valChange} />}
                {data?.type === 'img' && <ImgFormItem data={data} valChange={valChange} />}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 5px;
`;
const TitleWrap = styled.div``;
const ContentWrap = styled.div``;