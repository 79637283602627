import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { Center } from '../../../style/basic/commonStyle';

export default function VideoItem({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [nowTime,setNowTime] = useState(0);

    useEffect(()=>{
        const time = new Date().getTime();
        if(typeof selectItem?.item?.videoOption?.controls === 'boolean' || typeof selectItem?.item?.videoOption?.playing === 'boolean'){
            setNowTime(time);
        }
    },[selectItem?.item?.videoOption?.controls,selectItem?.item?.videoOption?.playing,selectItem?.item?.video]);
    
    return (
        <Wrap style={{...selectItem?.style[breakpoints ?? 'lg']}}>
            {selectItem?.item?.video === undefined ?
                <Center style={{width:'100%',height:'100%'}}>video</Center> :
                <ReactPlayer
                    key={nowTime}
                    url= {selectItem?.item?.video}
                    width='100%'
                    height='100%'
                    controls={(selectItem?.item?.videoOption?.controls === true || selectItem?.item?.videoOption?.controls === false) && selectItem.item?.videoOption?.controls }
                    playing={(selectItem?.item?.videoOption?.playing === true || selectItem?.item?.videoOption?.playing === false) && selectItem.item?.videoOption?.playing}
                    playbackRate={selectItem?.item?.videoOption?.playbackRate === '' || selectItem?.item?.videoOption?.playbackRate === undefined ? 1 : Number(selectItem?.item?.videoOption?.playbackRate)}
                    loop={(selectItem?.item?.videoOption?.loop === true || selectItem?.item?.videoOption?.loop === false) && selectItem.item?.videoOption?.loop}
                    muted={selectItem?.item?.videoOption?.playing === true ? true : false}
                ></ReactPlayer>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
`;