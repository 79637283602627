import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { Center } from '../../../style/basic/commonStyle';

export default function BannerItem({item,i,parent,breakpoints}:any) {
    const modules = [];
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    if(selectItem?.item?.bannerOption?.autoPlay === true) modules.push(Autoplay);
    if(selectItem?.item?.bannerOption?.pagination === true) modules.push(Pagination);
    
    return (
        <Wrap 
            style={{...selectItem?.style[breakpoints ?? 'lg']}} 
            activeColor={selectItem?.item?.bannerOption?.paginationColorActive} 
            color={selectItem?.item?.bannerOption?.paginationColor}
            opacity={selectItem?.item?.bannerOption?.paginationColorOpacity}
        >
            {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                <Center>
                    banner
                </Center>:
                <Swiper
                    key={`banner-${JSON.stringify(selectItem?.item?.bannerOption)}`}
                    spaceBetween={0}
                    slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper:any) => console.log(swiper)}
                    modules={modules}
                    autoplay={{
                        delay: selectItem?.bannerOption?.delay ?? 1500,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}

                >
                    {selectItem?.item?.list.map((data:any,index:any)=>{
                        return(
                            <SwiperSlide key={`banner-${index}`}>
                                <BannerWrap style={{...data.style[breakpoints ?? 'lg'],}}></BannerWrap>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            }
        </Wrap>
    )
}

const Wrap = styled.div<{activeColor?:string,color?:string,opacity?:number}>`
    width: 100%;
    height: 100%;
    overflow: hidden;
    &>div{
        height: 100%;
    }
    & .swiper-pagination-bullet{
        background: ${({color}) => color ?? '#000'};
        opacity: ${({opacity}) => opacity ?? '0.2'};
    }
    & .swiper-pagination-bullet-active{
        background: ${({activeColor}) => activeColor ?? '#007aff'};
        opacity: 1;
    }
`;

const BannerWrap = styled.div`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
`;