export const addTheme = {
    media:{
        m:'(max-width: 992px)',
        s:'(max-width:768px)',
        ss:'(max-width:580px)',
        sx:'(max-width:480px)',
    },
    maxWidth:'1280px',
    padding:'0 15px',
    headerHeight:58,
    headerMt:14,
    titleFontSize:40,
    mTitleFontSize:28,
    subText:{
        color:'#ababab',
        fontSize:19,
        mFontSize:15,
    },
    color:'#1e293b',
    color_off:'#005d46',
    bottom: 20,
    mt: '70px',
    mFontSize: 15,
    round: 5,
    pb: 20,
    colorToRgba:(color:string,opacity:number)=>{
        if(color === undefined){
            return 'transparent'
        }
        if(color.length < 7){
            return color
        }
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    gap: 18,
    size:{
        lg: 1000, md: 768, xs: 480
    },
    cols:{ lg: 24, md:12, xs: 6 },
    itemCols: { lg: 24, md:12, xs: 6 },
    wrapRowHeight: 5,
    itemRowHeight: 10,
}