import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_SELECT_ITEM= 'SUBOX/SET_SELECT_ITEM';
const SET_SELECT_ITEM_RESET = 'SUBOX/SET_SELECT_ITEM_RESET';

export const globalSelectItemCreator = createAction(SET_SELECT_ITEM);
export const globalSelectItemResetCreator = createAction(SET_SELECT_ITEM_RESET);

export function setGlobalSelectItem(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalSelectItemCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalSelectItemCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface SelectItemState {
  i: string;
  item: any;
  style: any;
  parent: string;
}

const initialState: SelectItemState = {
  i: '',
  item: undefined,
  style: undefined,
  parent: '',
};

export const globalSelectItem = handleActions<SelectItemState, any>({
  [SET_SELECT_ITEM]: (state, action: ReturnType<typeof globalSelectItemCreator>) => ({
    i: action.payload.data.i ?? state.i,
    item: action.payload.data.item ?? state.item,
    style: action.payload.data.style ?? state.style,
    parent: action.payload.data.parent ?? state.parent,
  }),
  [SET_SELECT_ITEM_RESET]: () => ({
    i: initialState.i,
    item: initialState.item,
    style: initialState.style,
    parent: initialState.parent,
  }),
}, initialState);
