import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ContentItemWrap, ContentWrap, ItemWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function MapSettingPage({selectItem,setSelectItem,breakpoints}:any) {
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>map</BasicText>
                    <ContentItemWrap>
                        <BasicText>데이터</BasicText>
                        <ItemWrap align='start' direction='column' pw={'30%'}>
                            <BasicText>구글 html</BasicText>
                            <textarea rows={6} value={selectItem?.item?.map ?? ''} onChange={(e:any)=>setSelectItem('item','map',e.target.value)}></textarea>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
`;