import React from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function Player02SettingItemPart({selectItem,setSelectItem,breakpoints,index,data}:any) {
    const listOptionChange = (val:any,name:string) =>{
        const newData = {
            ...data,
            [name]:val,
        }
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newData : item));
        setSelectItem('item','newList',newList);
    }
    
    return (
        <>
            <ContentItemWrap>
                <ItemWrap direction='column' align={'start'}>
                    <label>
                        오디오 url
                    </label>
                    <input type='text' name='audio' value={data?.audio ?? ''} placeholder='오디오url' onChange={(e:any)=>listOptionChange(e.target.value,'audio')}></input>
                </ItemWrap>
                <ItemWrap pw={'30%'}>
                    <BasicText>title</BasicText>
                    <input type='text' name='title' value={data?.title ?? ''} placeholder='제목' onChange={(e:any)=>listOptionChange(e.target.value,'title')}></input>
                </ItemWrap>
                {index === 0 &&
                    <ItemWrap pw={'30%'}>
                        <BasicText>sub</BasicText>
                        <input type='text' name='sub' value={data?.sub ?? ''} placeholder='서브' onChange={(e:any)=>listOptionChange(e.target.value,'sub')}></input>
                    </ItemWrap>
                }
            </ContentItemWrap>
        </>
    )
}

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;