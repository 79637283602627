import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import useAction from '../../../utils/custom-hooks/common/useAction';
import { fileTypeRes } from '../../../utils/format/string';
import BasicText from '../../basic/BasicText';

export default function ItemSelectWrapPart({selectItem,setSelectItem,breakpoints,index,data,type}:any) {
    const fileRef = useRef<any>(null);
    const audioRef = useRef<any>(null);
    const [item,setItem] = useState<any>({
        fileType: '1',
        linkUrl: '',
        fileData: '',
        fileName: '',
    });
    const [audio,setAudio] = useState<any>({
        fileType: '1',
        linkUrl: '',
        fileData: '',
        fileName: '',
    })
    const {imgGetUrl} = useAction();

    useEffect(()=>{
        let val = '';
        if(item.fileType === '1' ){
            val = item.linkUrl === '' ? '' : `${item.linkUrl}`;
        }else if(item.fileType === '2'){
            val = item.fileData === '' ? '' : `${item.fileData}`;
        }
        if(val === '' || val === undefined) return;
        const newItem = {...data,img:val,fileType:item.fileType};
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newItem : item));
        setSelectItem('item','newList',newList);
    },[item.fileType,item.linkUrl,item.fileData,])

    useEffect(()=>{
        let val = '';
        if(audio.fileType === '1' ){
            val = audio.linkUrl === '' ? '' : `${audio.linkUrl}`;
        }else if(audio.fileType === '2'){
            val = audio.fileData === '' ? '' : `${audio.fileData}`;
        }
        if(val === '' || val === undefined) return;
        const newItem = {...data,audio:val,fileType:audio.fileType};
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newItem : item));
        setSelectItem('item','newList',newList);
    },[audio.fileType,audio.linkUrl,audio.fileData,])

    // 타입 변경
    const fileSelectTypeChange = (e:any) =>{
        const val = e.target.value
        setItem((obj:any)=>({
            ...obj,fileType: val
        }))
    }

    // 링크로 이미지 입력
    const urlLinkImgChange = (e:any) =>{
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '1',
            linkUrl: val, 
        }))
    }

    // 파일 선택
    const fileImgChange = (e:any) =>{
        const file = e.target.files[0];
        if(file === undefined) return;
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg' || fileType === 'gif'){
            if (file.size > 10 * 1024 * 1024) {
                alert('파일의 용량이 10MB를 초과하였습니다.')
                fileRef.current.value = null;
            }else{
                const reader = new FileReader();
                reader.onloadend = async() => {
                    const img = await imgGetUrl(reader.result);
                    if(img.file_path === '') return;
                    setItem((obj:any)=>({
                        ...obj,
                        file: file,
                        fileType: '2',
                        fileData: img.file_path, 
                        fileName: file.name
                    }))
                };
                reader.readAsDataURL(file);
            }
        }else{
            alert('파일은 이미지 확장자인 png, jpg, gif 만 가능합니다.');
            fileRef.current.value = null;
        }
    }

    const listOptionChange = (val:any,name:string) =>{
        const newData = {
            ...data,
            [name]:val,
        }
        const newList = selectItem?.item?.list.map((item:any,idx:any)=>(idx === index ? newData : item));
        setSelectItem('item','newList',newList);
    }

    // 오디오 타입 변경
    const audioSelectTypeChange = (e:any) =>{
        const val = e.target.value
        setAudio((obj:any)=>({
            ...obj,fileType: val
        }))
    }

    // 오디오 링크
    const urlLinkAudioChange = (e:any) =>{
        const val = e.target.value;
        setAudio((obj:any)=>({
            ...obj,
            fileType: '1',
            linkUrl: val, 
        }))
    }

    // 오디오 파일 선택
    // const fileAudioChange = (e:any) =>{
    //     const file = e.target.files[0];
    //     if(file === undefined) return;
    //     const reader = new FileReader();
    //     reader.onloadend = async() => {
    //         const audio = await imgGetUrl(reader.result);
    //         if(audio.file_path === '') return;
    //         setAudio((obj:any)=>({
    //             ...obj,
    //             file: file,
    //             fileType: '2',
    //             fileData: audio.file_path, 
    //             fileName: file.name
    //         }))
    //     };
    //     reader.readAsDataURL(file);
    // }
    
    return(
        <>
            <ContentItemWrap>
                {type === 'text' ? <></> :
                    <>
                        <BasicText>이미지 선택</BasicText>
                        <ItemWrap direction='column' align={'start'}>
                            <label>
                                <input type="radio" name="img-type" value='1' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={data?.fileType === '1'}/>
                                url링크
                            </label>
                            <input type='text' name='img-url' value={item?.linkUrl ?? ''} onChange={urlLinkImgChange}></input>
                        </ItemWrap>
                        <ItemWrap direction='column' align={'start'}>
                            <label>
                                <input type="radio" name="img-type" value='2' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={data?.fileType === '2'}/>
                                파일선택
                            </label>
                            <input ref={fileRef} name='img-file' type='file' accept='.png,.jpg,.gif' onChange={fileImgChange}></input>
                        </ItemWrap>
                    </>
                }
                <ItemWrap pw={'30%'}>
                    <BasicText>title</BasicText>
                    <input type='text' name='title' value={data?.title ?? ''} placeholder='제목' onChange={(e:any)=>listOptionChange(e.target.value,'title')}></input>
                </ItemWrap>
                {type === 'text' || type === 'audio' ? <></> :
                    <ItemWrap pw={'30%'}>
                        <BasicText>content</BasicText>
                        <input type='text' name='content' value={data?.content ?? ''} placeholder='내용' onChange={(e:any)=>listOptionChange(e.target.value,'content')}></input>
                    </ItemWrap>
                }
                <ItemWrap pw={'30%'}>
                    <BasicText>sub</BasicText>
                    <input type='text' name='sub' value={data?.sub ?? ''} placeholder='서브' onChange={(e:any)=>listOptionChange(e.target.value,'sub')}></input>
                </ItemWrap>
                {type === 'audio' && <>
                    <BasicText>오디오 url</BasicText>
                    <ItemWrap direction='column' align={'start'}>
                        {/* <label>
                            <input type="radio" value='1' style={{marginRight:'6px'}} onChange={audioSelectTypeChange} checked={data?.fileType === '1'}/>
                            url링크
                        </label> */}
                        <input type='text' value={audio?.linkUrl ?? ''} onChange={urlLinkAudioChange}></input>
                    </ItemWrap>
                    {/* <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" value='2' style={{marginRight:'6px'}} onChange={audioSelectTypeChange} checked={data?.fileType === '2'}/>
                            파일선택
                        </label>
                        <input ref={audioRef} type='file' accept='audio/*' onChange={fileAudioChange}></input>
                    </ItemWrap> */}
                </>}
            </ContentItemWrap>
        </>
    )
}

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;