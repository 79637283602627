import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface userInfo {
  user_id: number,
  user_code: string,
  user_name: string,
  token: string,
}

export const userState: userInfo = {
  user_id: 0,
  user_code: '',
  user_name: '',
  token: '',
}

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
    user_id: action.payload.data.user_id ?? state.user_id,
    user_code: action.payload.data.user_code ?? state.user_code,
    user_name: action.payload.data.user_name ?? state.user_name,
    token: action.payload.data.token ?? state.token,
  })
,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => (userState),
}, userState);
