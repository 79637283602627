import React from 'react'
import { useDispatch } from 'react-redux'
import { useUserInfo } from './useUserInfo';
import { apiSetImg } from '../../../api/api_temp';


export default function useAction() {
    const dispatch = useDispatch();
    const userInfo = useUserInfo();

    const selectItemSave = (selectItem:any,setItemList:any) =>{
        if(selectItem.parent === ''){
            setItemList((obj:any)=>{
                return {
                    ...obj,
                    [selectItem.i]:{
                        ...obj[selectItem.i],
                        style: {...selectItem.style}
                    }
                }
            })
        }else{
            setItemList((obj:any)=>{    
                return {
                    ...obj,
                    [selectItem.parent]:{
                        ...obj[selectItem.parent],
                        item:{
                            ...obj[selectItem.parent].item,
                            items: {
                                ...obj[selectItem.parent].item.items,
                                [selectItem.i]: {
                                    style:{...selectItem.style},
                                    item:{...selectItem.item,module:undefined},
                                }
                            }
                        }
                    }
                }
            })
        }
    }

    const imgGetUrl = async(img:any) =>{
        if(userInfo.user_id === 0) return;
        if(img === undefined || img === '' || img === null) return;
        const res = await apiSetImg(userInfo.user_id,img);
        
        if(res.result){
            return res.data
        }else{
            return {
                file_name: '',
                file_path: '',
            }
        }
    }

    const textToTable = (txt:string,type?:string) =>{
        let rows = txt.split('\n').filter(data=>data).map(row => row.split('|').map(item => item.trim()).filter(data=>data));
        rows = rows.filter(data=>data.length > 0);
        if(rows.length === 0){
            return {
                headers: [],data: []
            }
        }
        if(type === 'column'){
            return {headers:[],data:rows};
        }else{
            // 첫 번째 줄을 제목으로 설정
            const headers = rows[0].filter(header => header); // 빈 값 제거
            // 나머지 데이터를 객체 배열로 변환
            const data = rows.slice(1).map(row => {
                const obj:any = {};
                headers.forEach((header:any, index:any) => {
                    obj[header] = row[index] ?? ''; // 제목을 키로 사용
                });
                return obj;
            });
            return {headers,data};
        }
    }

    return {
        selectItemSave,
        imgGetUrl,
        textToTable
    }
}
