import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

const Item = ({data,item,valChange}:any) =>{
    const chk = data?.val === undefined ? [] : data?.val?.filter((val:any)=>Number(val) === item.id);
    
    const change = (e:any) =>{
        const val = e.target.value;
        
        if(data?.val === undefined || data?.val.length === 0){
            valChange(data,[val]);
        }else{
            if(chk.length === 0){
                valChange(data,[...data.val,val]);
            }else{
                valChange(data,data.val.filter((id:any)=> id != val));
            }
        }
    }
    return(
        <ItemWrap>
            <input type='checkbox' name={`chk-${data.id}`} value={item.id} checked={chk.length != 0} onChange={change}></input>
            <BasicText>{item.name}</BasicText>
        </ItemWrap>
    )
}

export default function CheckFromItem({data,valChange}:any) {
    return (
        <Wrap>
            <ListWrap>
                {data?.list === undefined || data?.list.length === 0 ?
                    <></> :
                    data.list.map((item:any,index:any)=>{
                        return(
                            <Item key={`chk-${data.id}-${index}`} data={data} item={item} valChange={valChange}></Item>
                        )
                    })
                }
            </ListWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ListWrap = styled(Row)`
    flex-direction: column;
    gap: 8px;
    align-items: start;
`;

const ItemWrap = styled.label`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
`;