import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import AddItem from '../item/AddItem';
import { useDispatch } from 'react-redux';
import { globalDragResetCreator, setGlobalDrag } from '../../../store/common/globalDrag';

const GAP = 10;

export default function AddListPart({data,setLayouts}:any) {
    const dispatch = useDispatch();
    // 추가할 아이템 드래그 시작
    const handleDragStart = (item:any) =>{
        const {style, ...newItem} = item;
        dispatch(setGlobalDrag({
            drag: true,
            item: newItem,
            style: style,
        }))
    }
    // 추가할 아이템 드래그 끝
    const handleDragEnd = () =>{
        dispatch(globalDragResetCreator());
        setLayouts((obj:any)=>({...obj,new:obj.new+1}))
    }
    
    return (
        <Wrap>
            {data === undefined || data.length === 0 ?
                <></> :
                <>
                    <BasicText marginTop={3}>{data?.name}</BasicText>
                    <ContentWrap>
                        {data.list === undefined || data.length === 0 ?
                            <></> :
                            data.list.map((item:any,index:any)=>{
                                return(
                                    <AddItem 
                                        key={`add-item-${index}`} 
                                        data={item} 
                                        w={`calc(50% - ${GAP}px)`}
                                        handleDragStart={()=>handleDragStart(item)}
                                        handleDragEnd={handleDragEnd}
                                    />
                                )
                            })
                        }
                    </ContentWrap>
                </>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 5px;
    width: 100%;
`;
const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    padding: 5px;
    gap: ${GAP}px;
`;
