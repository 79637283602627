import React from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';

export default function AddItem({handleDragStart,handleDragEnd,w,data}:any) {
    return (
        <Wrap w={w} draggable onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            {data.name}
        </Wrap>
    )
}

const Wrap = styled(Center)<{w?:string}>`
    width: ${({w}) => w ?? '100%'};
    position: relative;
    &::after{
        content: '';
        display: block;
        padding-bottom: 100%;
    }
    border: 1px solid #ddd;
    border-radius: 4px;
`;
