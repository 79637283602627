import React from 'react'
import styled from 'styled-components'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import BasicText from '../../basic/BasicText';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function TimelinePage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    return (
        <Wrap position={selectItem?.item?.position}>
            <Timeline position={selectItem?.item?.position ?? "alternate"}>
                {selectItem?.item.list === undefined || selectItem?.item.list.length === 0 ?
                    <></> :
                    selectItem?.item.list.map((data:any,index:any)=>{
                        return(
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{backgroundColor:data?.bgColor ?? '#000000'}} />
                                    {selectItem?.item?.list.length === index+1 ? 
                                        <></> : <TimelineConnector />
                                    }
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div style={{whiteSpace:'pre-wrap',...selectItem?.item?.contentStyle, fontSize: `${selectItem?.item?.contentStyle?.fontSize ?? 16}px` }} dangerouslySetInnerHTML={{__html:data.content}}></div>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                }
            </Timeline>
        </Wrap>
    )
}

const Wrap = styled.div<{position?:any}>``;