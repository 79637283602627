export const LIST = [
    {id: 1, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 2, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 3, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 4, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 5, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 6, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 7, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 8, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 9, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 10, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 11, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 12, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 13, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 14, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 15, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 16, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 17, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 18, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 19, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
    {id: 20, img: 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png', title: '제목이에요', content: '내용이에요내용이에요내용이에요내용이에요내용이에요내용이에요', name: 'console',time:1723178853332},
]

export const LIST_ITEM = {
    title: '제목',
    content: '',
    sub: '내용 · 24.08.09',
    img: '',
    fileType: '',
    audio: '',
    audioType: '',
}