import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import RectItem from '../../common/item/RectItem';
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import { FaRegPlayCircle } from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import { addTheme } from '../../../style/addTheme';

export default function PlayListItem({data,imgStyle,titleStyle,timeStyle,btnAction,idxState,nowPlay}:any) {
    const [playState,setPlayState] = useState(false);

    useEffect(()=>{
        if(idxState){
            setPlayState(nowPlay);
        }else{
            setPlayState(false);
        }
    },[idxState,nowPlay])
    
    return (
        <Wrap>
            <ImgWrap align={imgStyle?.justifyContent}>
                <ImgInnerWrap style={{...imgStyle,width:imgStyle?.width === undefined ? '100%' : `${imgStyle.width}%`}}>
                    <RectItem imgUrl={data.img === '' ? 'https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png' : data.img}></RectItem>
                </ImgInnerWrap>
            </ImgWrap>
            <ContentWrap>
                <TextWrap>
                    <TitleItem style={{...titleStyle}}>{data?.title ?? ''}</TitleItem>
                    <TimeItem style={{...timeStyle}}>{data?.sub ?? ''}</TimeItem>
                </TextWrap>
                <IconWrap className='no-drag no-item-drag'>
                    {playState ?
                        <FaRegCirclePause onClick={btnAction} color={addTheme.color} size={22}></FaRegCirclePause> :
                        <FaRegPlayCircle onClick={btnAction} color={addTheme.color} size={22}></FaRegPlayCircle>
                    }
                </IconWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;
const ImgWrap = styled(Center)<{align?:string}>`
    justify-content: ${({align}) => align ?? 'center'};
`;
const ImgInnerWrap = styled(Center)`
    overflow: hidden;
`;
const ContentWrap = styled(RowBetween)`
    margin-top: 6px;
    gap: 6px;
`;

const TextWrap = styled(Row)`
    width: auto;
    flex-direction: column;
    gap: 2px;
    align-items: start;
`;

const IconWrap = styled(Center)`
    flex-shrink: 0;
`;

const TitleItem = styled.div`
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
`;

const TimeItem = styled.div`
    font-size: 13px;
`;