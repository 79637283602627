import React from 'react'
import styled,{css} from 'styled-components';
import VideoItem from './VideoItem';
import ButtonItem from './ButtonItem';
import BannerItem from './BannerItem';
import PlayerPage from '../../player/page/PlayerPage';
import WebzineListPage from '../../list/page/WebzineListPage';
import GalleryListPage from '../../list/page/GalleryListPage';
import TextListPage from '../../list/page/TextListPage';
import FormPage from '../../form/page/FormPage';
import UpdateTextItem from './UpdateTextItem';
import { Row } from '../../../style/basic/commonStyle';
import { FaArrowsAlt } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdDelete } from 'react-icons/md';
import PlayerPage02 from '../../player/page/PlayerPage02';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { useDispatch } from 'react-redux';
import { globalSelectItemResetCreator, setGlobalSelectItem } from '../../../store/common/globalSelectItem';
import TextItem from './TextItem';
import ImgItem from './ImgItem';
import useAction from '../../../utils/custom-hooks/common/useAction';
import PlayListPage from '../../list/page/PlayListPage';
import ChartLinePage from '../../chart/page/ChartLinePage';
import ChartAreaPage from '../../chart/page/ChartAreaPage';
import ChartBarPage from '../../chart/page/ChartBarPage';
import ChartPiePage from '../../chart/page/ChartPiePage';
import ChartRadarPage from '../../chart/page/ChartRadarPage';
import CountdownPage from '../../timer/page/CountdownPage';
import CalendarPage from '../../calendar/page/CalendarPage';
import TablePage01 from '../../table/page/TablePage01';
import TablePage02 from '../../table/page/TablePage02';
import AccordionPage from '../../accordion/page/AccordionPage';
import MapPage from '../../map/page/MapPage';
import TimelinePage from '../../timeline/page/TimelinePage';
import Slider01Page from '../../slider/page/Slider01Page';

export default function LayoutItem({type,parent,breakPoint,layouts,el,itemList,setItemList,draggableCancel}:any) {
    const selectItem = useGlobalSelectItem();
    const dispatch = useDispatch();
    const {selectItemSave} = useAction();

    // 설정 클릭
    const settingItemSelect = (item:any,i:any) =>{
        if(selectItem.i !== ""){
            if((selectItem.i === i && selectItem.parent === parent)) return;
            if(window.confirm('변경사항을 저장하겠습니까?')){
                // 이전 selectItem의 내용을 setItemList 수정
                selectItemSave(selectItem,setItemList);
            }
        }
        // 수정할 아이템
        dispatch(setGlobalSelectItem({
            i:i,
            item: item.item,
            style: item.style,
            parent: parent ?? '',
        }))
    }
    // 삭제
    const delClick = (data:any) =>{
        if(window.confirm('삭제하시겠습니까?')){
            if(selectItem.i === data.i && selectItem.parent === parent){
                dispatch(globalSelectItemResetCreator());
            }
            setItemList((obj:any)=>{
                // const newObj = Object.assign({}, obj);
                // const {[data.i]:remove,...newItems} = newObj[parent].item.items
                
                return {
                    ...obj,
                    [parent]:{
                        ...obj[parent],
                        item:{
                            ...obj[parent].item,
                            // items: newItems,
                            layout:{
                                ...obj[parent].item.layout,
                                [breakPoint]: obj[parent].item.layout[breakPoint].filter((item:any) => item.i !== data.i)
                            }
                        },
                    }
                }
            })
        }
    }
    
    return (
        <>
            <ContentOptionWrap className={type === 'view' ? '' : 'option-icon-wrap'}>
                <FaArrowsAlt color='#000' size={18}></FaArrowsAlt>
                <IoSettingsSharp color='#000' size={18} className={draggableCancel ?? 'no-drag'} onClick={()=>settingItemSelect(itemList[el.i],el.i)}/>
                <MdDelete color='#000' size={20} className={draggableCancel ?? 'no-drag'} onClick={()=>delClick(el)} />
            </ContentOptionWrap>
            {itemList[el.i]?.item?.type === 'img' ?
                <ImgItem item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint}/>
            : itemList[el.i]?.item?.type === 'text' ?
                (selectItem.i === el.i && selectItem.parent === parent) ?
                    <UpdateTextItem item={itemList[el.i]} i={el.i} parent={parent} layouts={layouts} breakpoints={breakPoint} setItemList={setItemList} /> :
                    <TextItem item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
            : itemList[el.i]?.item?.type === 'video' ?
                <VideoItem item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint}/>
            : itemList[el.i]?.item?.type === 'button' ?
                <ButtonItem item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint}/>
            : itemList[el.i]?.item?.type === 'banner' ?
                <BannerItem item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint}/>
            : itemList[el.i]?.item?.type === 'player' ?
                <ContentItemInnerWrap className='no-drag'>
                    <PlayerPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint}/>
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'list1' ?
                <ContentItemInnerWrap>
                    <WebzineListPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'list2' ?
                <ContentItemInnerWrap>
                    <GalleryListPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'list3' ?
                <ContentItemInnerWrap>
                    <TextListPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'list4' ?
                <ContentItemInnerWrap>
                    <PlayListPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'form' ?
                <ContentItemInnerWrap>
                    <FormPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'player02' ?
                <ContentItemInnerWrap className='no-drag'>
                    <PlayerPage02 item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'chartLine' ?
                <ContentItemInnerWrap>
                    <ChartLinePage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'chartArea' ? 
                <ContentItemInnerWrap>
                    <ChartAreaPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'chartBar' ? 
                <ContentItemInnerWrap>
                    <ChartBarPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'chartPie' ? 
                <ContentItemInnerWrap>
                    <ChartPiePage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'chartRadar' ? 
                <ContentItemInnerWrap>
                    <ChartRadarPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'countdown' ? 
                <ContentItemInnerWrap>
                    <CountdownPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'calendar' ? 
                <ContentItemInnerWrap>
                    <CalendarPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'table01' ? 
                <ContentItemInnerWrap>
                    <TablePage01 item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'table02' ? 
                <ContentItemInnerWrap>
                    <TablePage02 item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'accordion' ? 
                <ContentItemInnerWrap>
                    <AccordionPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'map' ? 
                <ContentItemInnerWrap>
                    <MapPage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'timeline' ? 
                <ContentItemInnerWrap>
                    <TimelinePage item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : itemList[el.i]?.item?.type === 'slider01' ? 
                <ContentItemInnerWrap>
                    <Slider01Page item={itemList[el.i]} i={el.i} parent={parent} breakpoints={breakPoint} />
                </ContentItemInnerWrap>
            : ''
            }
        </>
    )
}

const ContentOptionWrap = styled(Row)`
    position: absolute;
    display: none;
    cursor: pointer;
    justify-content: end;
    z-index: 2;
    width: auto;
    top: 0;
    left: 0;
    /* transform: translate(-50%,-50%); */
    &>svg{
        width: 14px;
        height: 14px;
        padding: 1px;
        background-color: #fff;
    }
`;


const ContentItemUpdateInnerWrap = styled.div`
    width: 100%;
    height: 100%;
`;

const ContentItemInnerWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

