import React from 'react'
import styled from 'styled-components'
import { Center, ContentItemWrap, ContentWrap, ItemWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { inputResNumber } from '../../../utils/format/string';

export default function TimelineSettingPage({selectItem,setSelectItem,breakpoints}:any) {
    // 데이터 추가
    const addClick = () =>{
        setSelectItem('item','list',{title:'',content:''});
    }
    // 데이터 삭제
    const delClick = (idx:any) =>{
        const newList = selectItem.item.list.filter((data:any,index:any)=> idx !== index);
        setSelectItem('item','newList',newList);
    }

    // 데이터 값 변경
    const changeAction = (data:any,name:any,val:any,idx:any) =>{
        const newData = {
            ...data,
            [name]: val,
        }
        const newList = selectItem.item.list.map((item:any,index:any)=>index === idx ? newData : item);
        setSelectItem('item','newList',newList);
    }
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>timeline</BasicText>
                    <ContentItemWrap>
                        <BasicText>데이터</BasicText>
                        {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                            <></> :
                            selectItem?.item?.list.map((data:any,index:any)=>{
                                return(
                                    <ContentItemWrap key={`data-${index}`}>
                                        <RowBetween>
                                            <BasicText>{index+1}. 데이터</BasicText>
                                            <BasicText onClick={()=>delClick(index)}>삭제</BasicText>
                                        </RowBetween>
                                        <ItemWrap direction='column' align='start' pw={'30%'}>
                                            <BasicText>내용</BasicText>
                                            <textarea rows={4} value={data?.content} onChange={(e:any)=>changeAction(data,'content',e.target.value,index)}></textarea>
                                        </ItemWrap>
                                        <ItemWrap>
                                            <BasicText>색</BasicText>
                                            <input type='color' value={data?.bgColor ?? '#000000'} onChange={(e:any)=>changeAction(data,'bgColor',e.target.value,index)}></input>
                                        </ItemWrap>
                                    </ContentItemWrap>
                                )
                            })
                        }
                        <Center style={{width: '100%'}}>
                            <Center onClick={addClick} style={{border:'1px solid #eee', padding:'6px 12px', cursor:'pointer'}}>데이터 추가</Center>
                        </Center>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText>스타일</BasicText>
                        <ItemWrap pw={'30%'}>
                            <BasicText>배치</BasicText>
                            <SelectItem value={selectItem?.item?.position ?? 'alternate'} setValue={(val:any)=>setSelectItem('item','position',val)} none='no'>
                                <MenuItem value='left'>left</MenuItem>
                                <MenuItem value='right'>right</MenuItem>
                                <MenuItem value='alternate'>alternate</MenuItem>
                            </SelectItem>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>글자색</BasicText>
                            <input type='color' value={selectItem?.item?.contentStyle?.color ?? '#000000'} onChange={(e:any)=>setSelectItem('contentStyle','color',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap pw={'30%'}>
                            <BasicText>글자크기</BasicText>
                            <input type='text' value={selectItem?.item?.contentStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>setSelectItem('contentStyle','fontSize',inputResNumber(e))}></input>
                        </ItemWrap>
                    </ContentItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
`;