import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { CHART_PIE } from '../../../data/layout';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, ...rest }:any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* {rest.name} */}
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
  
export default function ChartPiePage({item,i,parent,breakpoints}:any) {
    const [data,setData] = useState<any>([]);
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    useEffect(()=>{
        const list = selectItem?.item?.list ?? CHART_PIE;
        const newList = list.map((item:any,index:any)=>{
            const itemList = item.list.map((obj:any)=>({[obj.key]:Number(obj.value),pieColor:obj.pieColor}))
            const newObj = itemList.reduce((acc:any, curr:any) => {
                return { ...acc, ...curr };
            }, {});
            return {name:item.name,...newObj}
        })
        setData(newList);
    },[selectItem?.item?.list]);
    
    return (
        <Wrap bg={selectItem?.item?.bg ?? "#ffffff"}>
            <ResponsiveContainer width="100%" height='100%'>
                <PieChart>
                    <Pie 
                        dataKey="value"
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius={'95%'}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        innerRadius={selectItem?.item?.innerRadius === undefined ? 0 : selectItem?.item?.innerRadius+'%'}
                        paddingAngle={selectItem?.item?.paddingAngle ?? 0}
                        startAngle={selectItem?.item?.startAngle === undefined || selectItem?.item?.startAngle === '' ? 0 : Number(selectItem?.item?.startAngle)}
                        endAngle={selectItem?.item?.endAngle === undefined || selectItem?.item?.endAngle === '' ? 360 : Number(selectItem?.item?.endAngle)}
                    >
                        {!(data === undefined || data.length === 0) && data.map((item:any, index:any) => {
                            return (
                                <Cell key={`cell-${index}`} fill={item?.pieColor ?? '#8884d8'} />
                            )
                        })}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </Wrap>
    )
}

const Wrap = styled.div<{bg?:string}>`
    width: 100%;
    height: 100%;
    background-color: ${({bg}) => bg ?? '#ffffff'};
`;