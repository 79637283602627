import { axiosConfigInstance } from "./config"

// 템플릿 생성
export const apiSetNewTemplate = async(user_id:any,template_category_code:any,template_name:any) =>{
    try {
        const res = await axiosConfigInstance('/api/template/manage').post('',{
            method: 'createTemplate',
            params: {
                user_id,template_category_code,template_name
            }
        });
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 템플릿 카테고리 리스트 가져오기
export const apiGetTempCateList = async(user_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/template/info').post('',{
            method: 'getTemplateCategoryList',
            params: {
                user_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 유저의 템플릿 리스트 가져오기
export const apiGetTempList = async(user_id:any,target_user_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/template/info').post('',{
            method: 'getUserTemplateList',
            params: {
                user_id,target_user_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 템플릿 정보 가져오기 유저o
export const apiGetTempDetail = async(user_id:any,template_code:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/template/info').post('',{
            method: 'getTemplateInfo',
            params: {
                user_id,template_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 템플릿 정보 가져오기 유저x
export const apiGetTempView = async(template_code:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/view/page').post('',{
            method: 'getViewPageInfo',
            params: {
                template_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 이미지 저장 -> url 가져오기
export const apiSetImg = async(user_id:any,base64_data:any) =>{
    try {
        const res = await axiosConfigInstance('/api/common/utils').post('',{
            method: 'getFileUrlFromBase64',
            params: {
                user_id,base64_data
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }        
    }
}

// 템플릿 저장
export const apiSetTempSave = async(user_id:any,template_code:any,template_data:any) =>{
    try {
        const res = await axiosConfigInstance('/api/template/manage').post('',{
            method: 'saveTemplate',
            params: {
                user_id,template_code,template_data
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}