import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { WidthProvider, Responsive } from 'react-grid-layout';
import { addTheme } from '../../../style/addTheme';
import { Center } from '../../../style/basic/commonStyle';
import LayoutItem from '../../home/item/LayoutItem';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { useGlobalDrag } from '../../../utils/custom-hooks/common/useGlobalDrag';
import { useDispatch } from 'react-redux';
import { globalSelectItemResetCreator } from '../../../store/common/globalSelectItem';
import useAction from '../../../utils/custom-hooks/common/useAction';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function WrapPage01({type,breakPoint,parent,setItemList,layout,items}:any) {
    const ref = useRef<any>(null);
    const selectItem = useGlobalSelectItem();
    const addDrag = useGlobalDrag();
    const dispatch = useDispatch();
    const {selectItemSave} = useAction();

    const getLastKey = () => {
        const keys = Object.keys(items); 
        return items === undefined ? 0 : keys.length;
    };

    // 레이아웃 변경
    const layoutChange = (a:any,b:any) =>{
        const lastA = a[a.length - 1];
        if(lastA?.i === '__dropping-elem__') return;
        setItemList((obj:any)=>{
            return{
                ...obj,
                [parent]:{
                    ...obj[parent],
                    item:{
                        ...obj[parent].item,
                        layout: {
                            ...obj[parent].item.layout,
                            [breakPoint]: b[breakPoint],
                        }
                    }
                }
            }
        })
    }
    
    // 드래그로 추가
    const onDrop = (_layout:any, layoutItem:any, _event:any) => {
        if(addDrag?.item === undefined) return;
        if((addDrag.item.type.includes('wrap') || addDrag.item.type.includes('header')) || addDrag.item.type.includes('footer'))return;
        if(selectItem.i !== ''){
            dispatch(globalSelectItemResetCreator());
            if(window.confirm('변경사항을 저장하겠습니까?')){
                // 이전 selectItem의 내용을 setItemList 수정
                selectItemSave(selectItem,setItemList);
            }
        }
        const last = getLastKey();
        const newI = `wrap-${last}`;
        const newItem = {
            i: newI,
            x: layoutItem.x,
            y: layoutItem.y-1,
            w: layoutItem.w,
            h: layoutItem.h+8,
        }
        setItemList((obj:any)=>{
            return{
                ...obj,
                [parent]: {
                    ...obj[parent],
                    item:{
                        ...obj[parent].item,
                        items: {
                            ...obj[parent].item.items,
                            [newI]: {
                                style:{
                                    lg:{}, md:{}, xs:{}
                                },
                                item: {...addDrag.item},
                            }
                        },
                        layout: {
                            ...obj[parent].item.layout,
                            [breakPoint]: [...obj[parent].item.layout[breakPoint],{...newItem}]
                        }
                    }
                }
            }
        })
    };

    const handleResizeStop = (layout:any, oldItem:any, newItem:any) => {
        // const parentHeight = ref.current.clientHeight;
        // const itemHeight = newItem.h * ROW_HEIGHT; // rowHeight가 30픽셀이라고 가정

        // if (itemHeight > parentHeight) {
        //   // 높이가 부모보다 크면 조정
        //   newItem.h = Math.floor(parentHeight / ROW_HEIGHT); // rowHeight로 나눠서 조정
        // }

        // const newList = layout.map((data:any)=>data.i === newItem.i ? newItem : data);
        // setLayouts((obj:any)=>({...obj,layouts:{...obj.layouts,[obj.breakpoints]:newList}}));
    };
    // console.log({layout,test});
    
    return (
        <Wrap className='no-drag' ref={ref}>
            <ResponsiveGridLayout
                className="layout_inner"
                layouts={layout}
                cols={addTheme.cols}
                rowHeight={addTheme.wrapRowHeight}
                breakpoints={addTheme.size}
                margin={[0,0]}
                onDrop={onDrop}
                isDroppable
                onResizeStop={handleResizeStop}
                draggableCancel='.no-item-drag'
                onLayoutChange={layoutChange}
                // 자유롭지만 다른 엘리먼트 고정 x
                compactType={null}
                preventCollision={true}
                isResizable={type === 'view' ? false : true} 
                isDraggable={type === 'view' ? false : true}
            >
                {layout === undefined ?
                    <></> :
                    layout[breakPoint]?.map((el:any,index:any)=>{
                        return(
                            <ContentItemWrap key={el.i} selectOn={(el.i === selectItem?.i && parent === selectItem?.parent) || addDrag.item !== undefined} {...el}>
                                <LayoutItem
                                    type={type}
                                    parent={parent}
                                    layouts={layout}
                                    breakPoint={breakPoint}
                                    el={el} 
                                    addDrag={addDrag} 
                                    selectItem={selectItem}
                                    itemList={items} 
                                    setItemList={setItemList}
                                    draggableCancel='no-item-drag'
                                />
                            </ContentItemWrap>
                        )
                    })
                }
            </ResponsiveGridLayout>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;

const ContentItemWrap = styled(Center)<{selectOn?:boolean}>`
    position: relative;
    & img{
        width:  100%;
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    &:hover>.option-icon-wrap{
        display: flex;
    }
    outline: 1px solid ${({selectOn}) => selectOn ? 'red': 'transparent'};
    z-index: ${({selectOn}) => selectOn ? 4 : 1};
`;
