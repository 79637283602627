import React from 'react'
import styled from 'styled-components'
import Countdown from 'react-countdown';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { Center } from '../../../style/basic/commonStyle';

export default function CountdownPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    const renderer = ({days, hours, minutes, seconds, completed, }:any) => {
        if (completed) {
            // Render a completed state
            return (
                <CountWrap style={{...selectItem?.item?.countDone,fontSize:`${selectItem?.item?.countDone?.fontSize ?? 16}px`}}>
                    {selectItem?.item?.done ?? '완료'}
                </CountWrap>
            );
        } else {
          // Render a countdown
          return (
                <CountWrap style={{...selectItem?.item?.count,fontSize:`${selectItem?.item?.count?.fontSize ?? 16}px`}}>
                    {days<1 ? '' : `${days}:`}{hours<10 ? `0${hours}`: hours}:{minutes<10 ? `0${minutes}`: minutes}:{seconds<10 ? `0${seconds}`: seconds}
                </CountWrap>
            );
        }
    };
    const currentDate = new Date();
    const fiveDaysLater = new Date(currentDate.getTime() + 5 * 24 * 60 * 60 * 1000);
    
    return (
        <Wrap>
            <Countdown
                date={selectItem?.item?.countdown ?? fiveDaysLater}
                autoStart
                renderer={(data:any)=>renderer(data)}
            ></Countdown>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;
const CountWrap = styled(Center)`
    height: 100%;
`;