import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { CHART_RADAR } from '../../../data/layout';

export default function ChartRadarPage({item,i,parent,breakpoints}:any) {
    const [data,setData] = useState<any>([]);
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    useEffect(()=>{
        const list = selectItem?.item?.list ?? CHART_RADAR;
        const newList = list.map((item:any,index:any)=>{
            const itemList = item.list.map((obj:any)=>({[obj.key]:Number(obj.value),pieColor:obj.pieColor}))
            const newObj = itemList.reduce((acc:any, curr:any) => {
                return { ...acc, ...curr };
            }, {});
            return {name:item.name,...newObj}
        })
        setData(newList);
    },[selectItem?.item?.list]);
    
    return (
        <Wrap bg={selectItem?.item?.bg ?? "#ffffff"}>
            <ResponsiveContainer width="100%" height='100%'>
                <RadarChart cx="50%" cy="50%" outerRadius="75%" data={data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" />
                    <PolarRadiusAxis />
                    <Radar name="Mike" dataKey="value" stroke={selectItem?.item?.radarLineColor ?? "#8884d8"} fill={selectItem?.item?.radarColor ?? "#8884d8"} fillOpacity={0.6} />
                </RadarChart>
            </ResponsiveContainer>
        </Wrap>
    )
}

const Wrap = styled.div<{bg?:string}>`
    width: 100%;
    height: 100%;
    background-color: ${({bg}) => bg ?? '#ffffff'};
`;