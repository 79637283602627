import React, { useEffect, useState } from 'react'
import styled,{css} from 'styled-components'
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { LIST_ITEM } from '../../list/page/listData';
import GalleryListItem from '../../list/item/GalleryListItem';
import { Row } from '../../../style/basic/commonStyle';
import Marquee from 'react-fast-marquee';

export default function Slider01Page({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setList([LIST_ITEM]);
        }else{
            setList(selectItem?.item?.list)
        }
    },[selectItem?.item?.list])
    
    return (
        <Wrap>
            <Marquee
                speed={selectItem?.item?.speed ?? 50}
                direction={selectItem?.item?.direction ?? 'left'}
                gradient={false}
                loop={0}
                pauseOnHover={selectItem?.item?.pauseOnHover === 'true' ? true : selectItem?.item?.pauseOnHover === 'false' ? false : false }
            >
                {list.map((data:any,index:any)=>{
                    return(
                        <ItemWrap key={`slide01-${index}`} style={{...selectItem?.item?.listStyle}}>
                            <GalleryListItem 
                                data={data} 
                                imgStyle={selectItem?.item?.imgStyle}
                                titleStyle={selectItem?.item?.titleStyle}
                                contentStyle={selectItem?.item?.contentStyle}
                                timeStyle={selectItem?.item?.timeStyle}
                            />
                        </ItemWrap>
                    )
                })}
            </Marquee>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`;

const ContentWrap = styled(Row)`
    gap: 8px;
`;

const ItemWrap = styled.div``;