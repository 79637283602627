import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import CustomTabPanelPart from '../../list/part/CustomTabPanelPart';
import ChartLineSettingItemPart from './ChartLineSettingItemPart';
import { CHART_LINE } from '../../../data/layout';

export default function ChartLineSettingPart({selectItem,setSelectItem,breakpoints}:any) {
    const [tab,setTab] = useState(0);
    const [uniqueKeys,setUniqueKeys] = useState<any>([]);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setSelectItem('item','newList',CHART_LINE);
        }
    },[selectItem?.item?.list]);

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0) return;
        const uniqueKey = selectItem?.item?.list.reduce((acc:any, item:any) => {
            item.list.forEach((subItem:any) => {
                if (!acc.includes(subItem.key)) {
                    acc.push(subItem.key);
                }
            });
            return acc;
        }, []);
        setUniqueKeys(uniqueKey);
    },[selectItem?.item?.list]);
    
    // add
    const tabClick = (e:any,newValue:any) =>{
        const txt = e.target.textContent;
        if(txt === '+'){
            setSelectItem('item','list',{name:'',list:[{key:'',value:''}]});
            return;
        }
        setTab(newValue)
    }

    const delClick = (idx:any) =>{
        const newList = selectItem?.item?.list.filter((data:any,ind:any)=>ind !== idx);
        setSelectItem('item','newList',newList);
    }

    const lineColorChange = (val:any,index:any) =>{
        if(selectItem?.item?.lineColor === undefined || selectItem?.item?.lineColor.length === 0){
            setSelectItem('item','lineColor',[val]);
        }else{
            if((selectItem?.item?.lineColor.length - 1) >= index){
                const newList = selectItem?.item?.lineColor.map((data:any,idx:any)=>(idx === index ? val : data));
                setSelectItem('item','lineColor',newList);
            }else{
                const newList = [...selectItem?.item?.lineColor,val];
                setSelectItem('item','lineColor',newList);
            }
        }
    }

    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>line chart</BasicText>
                    <Row style={{flexWrap:'wrap'}}>
                        {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                            <></>:
                            selectItem?.item?.list.map((_:any,idx:any)=>{
                                return(
                                    <div onClick={(e:any)=>tabClick(e,idx)} style={{width:'20%', color: idx === tab ? 'red':'#000'}} key={`item-tab-${idx}`}>{idx+1}</div>
                                )
                            })
                        }
                        <div onClick={(e:any)=>tabClick(e,-1)} style={{width:'20%'}}>+</div>
                    </Row>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></> :
                        selectItem?.item?.list.map((data:any,idx:any)=>{
                            return(
                                <CustomTabPanelPart key={`list-${idx}`} value={tab} index={idx}>
                                    <ChartLineSettingItemPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} index={idx} data={data} />
                                    {idx !== 0 &&
                                        <BtnWrap>
                                            <BtnItem onClick={()=>delClick(idx)}>삭제</BtnItem>
                                        </BtnWrap>
                                    }
                                </CustomTabPanelPart>
                            )
                        })
                    }
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText>차트 스타일</BasicText>
                    {uniqueKeys === undefined || uniqueKeys.length === 0 ? <></> :
                        uniqueKeys.map((item:any,idx:any)=>{
                            return(
                                <ItemWrap key={`line-color${idx}`} pw={'30%'}>
                                    <BasicText>라인색{idx+1}</BasicText>
                                    <input type='color' name='color' value={(selectItem?.item?.lineColor === undefined || selectItem?.item?.lineColor.length === 0) ? "#8884d8": selectItem?.item?.lineColor[idx] } onChange={(e:any)=>lineColorChange(e.target.value,idx)}></input>
                                </ItemWrap>
                            )
                        })
                    }
                    <ItemWrap pw={'30%'}>
                        <BasicText>배경색</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.bg ?? '#ffffff'} onChange={(e:any)=>setSelectItem('item','bg',e.target.value)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>x축색</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.xColor ?? '#000000'} onChange={(e:any)=>setSelectItem('item','xColor',e.target.value)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>y축색</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.yColor ?? '#000000'} onChange={(e:any)=>setSelectItem('item','yColor',e.target.value)}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>눈금선색</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.gridColor ?? '#cccccc'} onChange={(e:any)=>setSelectItem('item','gridColor',e.target.value)}></input>
                    </ItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${addTheme.gap}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;

const BtnWrap = styled(Center)`
    gap: 15px;
`;

const BtnItem = styled(Center)`
    width: 46%;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
`;