import React from 'react'

export default function CustomTabPanelPart(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{width: '100%'}}
        >
            {value === index && children}
        </div>
    );
}
