import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from '../utils/custom-hooks/common/ScrollToTop';
import HomePage from '../components/home/page/HomePage';
import MainPage from '../components/main/page/MainPage';
import LoginPage from '../components/login/page/LoginPage';
import JoinPage from '../components/login/page/JoinPage';
import ViewPage from '../components/view/page/ViewPage';

export default function Router(){
    
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='/join' element={<JoinPage />} />
                <Route path='/main' element={<MainPage />} />
                <Route path='/home/:code?' element={<HomePage />} />
                <Route path='/view/:code?' element={<ViewPage />} />
            </Routes>
        </BrowserRouter>
    )
}