import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import { IoPlayBack, IoPlayForward, IoPlay, IoPause  } from "react-icons/io5";
import BasicText from '../../basic/BasicText';
import { formatTime } from '../../../utils/format/time';
import RectItem from '../../common/item/RectItem';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function PlayerPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;

    const playerRef = useRef<any>(null);
    const [playIndex,setPlayIndex] = useState(0);
    const [playState,setPlayState] = useState(false);
    // const [playList,setPlayList] = useState([
    //     {title: '', url: 'https://www.youtube.com/watch?v=q4PU7RHkrwY', artwork: '', code: 'q4PU7RHkrwY'},
    //     {title: '', url: 'https://www.youtube.com/watch?v=C8stPxCHnBM', artwork: '', code: 'C8stPxCHnBM'},
    //     {title: '까만 밤 까만 맘', url: "https://music.dapi.subox.co.kr/sound_files/23136/23136_1722053784376.mp3.mp3", artwork: "https://music.dapi.subox.co.kr/sound_album/23136/2309/0.png", code: ''},
    //     {title: 'WAVE', url: "https://music.dapi.subox.co.kr/sound_files/29151/29151_1722028767294.mp3.mp3", artwork:'https://music.dapi.subox.co.kr/sound_album/29151/2308/0.png', code: ''},
    // ])
    const [duration, setDuration] = useState(0); // 비디오 총 길이
    const [currentTime, setCurrentTime] = useState(0); // 현재 재생 시간
    const [title, setTitle] = useState('');
    const [showImg,setShowImg] = useState('');
    
    // 이미지
    useEffect(()=>{
        if(selectItem?.item?.playerOption?.albumImg === true){
            imgRes();
        }
    },[playIndex,selectItem?.item?.playerOption?.albumImg])

    // 재생, 멈춤
    const togglePlayPause = () => {
        setPlayState((prev:boolean) => !prev);
    };

    // 다음곡 재생
    const handleNextVideo = (video: string | any[], playIndex: number) => {
        if(playIndex === video.length - 1){
            setPlayIndex(0);
        }else{
            setPlayIndex(playIndex + 1);
        }
    }

    // 이전곡 재생
    const handlePrevVideo = (video: string | any[], playIndex: number) =>{
        if(playIndex === 0){
            setPlayIndex(video.length - 1);
        }else{
            setPlayIndex(playIndex - 1);
        }
    }

    // 총 음악의 길이
    const handleDuration = (duration:any) => {
        setDuration(duration);
    };
    
    // 현재 재생시간
    const handleProgress = (progress:any) => {
        setCurrentTime(progress.playedSeconds);
    };
    
    // 재생 안될때
    const handleError = () =>{
        setDuration(0);
        setCurrentTime(0);
        setTitle('');
    }

    // 재생시간 변경
    const handleSeek = (e:any) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const clickX = e.clientX - rect.left; // 클릭 위치
        const newTime = (clickX / rect.width) * duration; // 새 재생 시간 계산
        setCurrentTime(newTime);
        playerRef.current.seekTo(newTime); 
    };

    // 유튜브 타이틀 
    const handleReady = (e:any) => {
        const player = playerRef.current;
        if(e.props.url.includes('.mp3')) return
        if (player) {
            const currentTitle = player.getInternalPlayer().getVideoData().title;
            setTitle(currentTitle);
        }
    };

    const imgRes = () =>{
        const item = selectItem?.item?.list[playIndex];
        const img = item.artwork === '' ? (item.code === '' ? '' : `https://img.youtube.com/vi/${item.code}/mqdefault.jpg`) : item.artwork;
        setShowImg(img);
    }
    
    return (
        <Wrap>
            {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                <></> :
                <ReactPlayer
                    ref={playerRef}
                    url={selectItem?.item?.list[playIndex].url}
                    playing={playState}
                    // progressInterval={1000}
                    controls
                    onDuration={handleDuration} // 비디오 총 길이
                    onProgress={handleProgress} // 현재 재생 시간
                    pip={true}
                    onEnded={() => {handleNextVideo(selectItem?.item?.list, playIndex)}}
                    onError={handleError}
                    onReady={handleReady}
                    width={'0'}
                    height={'0'}
                />
            }
            <PlayerWrap style={{...selectItem?.style[breakpoints ?? 'lg']}}>
                <AlbumImgWrap w={selectItem?.item?.playerOptionStyle?.width} style={{...selectItem?.item?.playerOptionStyle}}>
                    {selectItem?.item?.playerOption?.albumImg === true && (showImg !== '') ?
                        <RectItem imgUrl={showImg} /> :
                        <></>
                    }
                </AlbumImgWrap>
                <PlayerBtnWrap>
                    <PlayerBtnItem>
                        <IoPlayBack size={22} onClick={()=>handlePrevVideo(selectItem?.item?.list, playIndex)}/>
                    </PlayerBtnItem>
                    <PlayerBtnItem onClick={togglePlayPause}>
                        {playState === false ? 
                            <IoPlay size={22}/> :
                            <IoPause size={22}/>
                        }
                    </PlayerBtnItem>
                    <PlayerBtnItem>
                        <IoPlayForward size={22} onClick={()=>handleNextVideo(selectItem?.item?.list, playIndex)}/>
                    </PlayerBtnItem>
                </PlayerBtnWrap>
                <TitleWrap>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></> :
                        <BasicText align='center' line={1}>{selectItem?.item?.list[playIndex]?.title === '' ? title : selectItem?.item?.list[playIndex]?.title}</BasicText>
                    }
                </TitleWrap>
                <LineWrap onClick={handleSeek}>
                    <Line w={(currentTime / duration) * 100} />
                </LineWrap>
                <TimeWrap>
                    <BasicText fontSize={14} color={'#000'}>{formatTime(currentTime)}</BasicText>
                    <BasicText fontSize={14} color={'#000'}>{formatTime(duration)}</BasicText>
                </TimeWrap>
            </PlayerWrap>
        </Wrap>

    )
}

const Wrap = styled.div`
    width: 100%;
`;
const PlayerWrap = styled(Row)`
    gap: 10px;
    flex-direction: column;
`;
const PlayerBtnWrap = styled(Center)`
    gap: 15px;
`;

const AlbumImgWrap = styled.div<{w?:any}>`
    width: ${({w}) => w === undefined || w === '' ? '40' : w}% !important;
    overflow: hidden;
`;

const PlayerBtnItem = styled(Center)`
    cursor: pointer;
`;
const TimeWrap = styled(RowBetween)``;
const TitleWrap = styled(Center)``;

const LineWrap = styled(Center)`
    width: 100%;
    height: 6px;
    background-color: #d3d3d3;
    border-radius: 5px;
    justify-content: start;
    overflow: hidden;
`;

const Line = styled.div.attrs(({w}:{w:number}) =>({
    style:{
        width: `${w ?? 0}%`,
    }
}))<{w:number}>`
    height: 4px;
    background-color: #494949;
    border-radius: 5px;
    overflow: hidden;
`;

