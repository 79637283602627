import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export default function TextFormItem({data,valChange}:any) {
    const [value,setValue] = useState('');
    const [count,setCount] = useState(1);

    useEffect(()=>{
        if(data.val === '') return;
        if(count === 1){
            setValue(data.val);
            setCount(2);
        }
    },[data.val,count]);

    const change = (e:any) =>{
        const val = e.target.value;
        setValue(val)
        valChange(data,val);
    }

    return (
        <Wrap>
            <textarea value={value} onChange={change}></textarea>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    & textarea{
        width: 100%;
        border: 1px solid #ddd;
        padding: 8px;
    }
`;