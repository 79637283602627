import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { useDispatch } from 'react-redux';
import { globalSelectItemResetCreator, setGlobalSelectItem } from '../../../store/common/globalSelectItem';

const ADD_FONT = ['roboto','righteous']
const ADD_FONT_SIZE = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '34px', '36px', '38px', '40px', '42px', '44px', '46px', '48px', '50px', '52px', '54px', '56px', '58px', '60px', '62px', '64px']
const QuillToolbar = () =>{
    return(
        <div id="toolbar" className='no-drag'>
            <span className="ql-formats">
                <select className="ql-font" defaultValue="arial">
                    <option value="">Sans Serif</option>
                    <option value="serif">Serif</option>
                    <option value="monospace">monospace</option>
                    {ADD_FONT.map((data:any,index:any)=>{
                        return(
                            <option key={`font-${index}`} value={data}>{data}</option>
                        )
                    })}
                </select>
                <select className="ql-size" defaultValue="16px">
                    {/* <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                    <option value="huge">Huge</option> */}
                    {ADD_FONT_SIZE.map((data:any,index:any)=>{
                        return(
                            <option key={`font-size-${index}`} value={data}>{data}</option>
                        )
                    })}
                </select>
                {/* <select className="ql-header">
                    <option value="1">Header 1</option>
                    <option value="2">Header 2</option>
                    <option value="3">Header 3</option>
                    <option value="4">Header 4</option>
                    <option value="5">Header 5</option>
                    <option value="6">Header 6</option>
                </select> */}
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
                <button className="ql-blockquote" />
            </span>
            <span className="ql-formats">
                <select className="ql-color" />
                <select className="ql-background" />
            </span>
            <select className="ql-align" defaultValue="arial">
                <option value=""></option>
                <option value="center"></option>
                <option value="right"></option>
            </select>
            {/* <span className="ql-formats">
                <button className="ql-image" />
                <button className="ql-video" />
            </span> */}
            {/* <span className="ql-formats">
                <button className="ql-clean" />
            </span> */}
        </div>
    )
}
let Font = Quill.import("attributors/style/font");
Font.whitelist = [...Font.whitelist,...ADD_FONT];
Quill.register(Font, true);
let FontSize = Quill.import("attributors/style/size");
FontSize.whitelist = [...ADD_FONT_SIZE];
Quill.register(FontSize,true);

export default function UpdateTextItem({item,i,parent,layouts,setItemList,breakpoints}:any) {
    const [toolbarHeight,setToolbarHeight] = useState(0);
    const elementRef = useRef<any>(null);
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const dispatch = useDispatch();
    
    useEffect(()=>{
        const timer = setTimeout(() => {
            const toolbarEl:any = document.querySelector('#toolbar');
            if(toolbarEl){
                setToolbarHeight(toolbarEl.offsetHeight+1);
            }
        }, 100);
        return () => clearTimeout(timer);
    },[layouts])

    const modules ={
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': ['', 'serif', 'monospace', 'roboto'] }],
            [{ 'size': [  'small',  'normal',  'large',  'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }]
        ],
    }

    // 취소
    const cancel = () =>{
        dispatch(globalSelectItemResetCreator());
    }
    // 저장
    const save = () =>{
        dispatch(globalSelectItemResetCreator());
        setItemList((obj:any)=>{    
            return {
                ...obj,
                [selectItem.parent]:{
                    ...obj[selectItem.parent],
                    item:{
                        ...obj[selectItem.parent].item,
                        items: {
                            ...obj[selectItem.parent].item.items,
                            [selectItem.i]: {...selectItem,module: undefined}
                        }
                    }
                }
            }
        })
    }

    const contentChange = (val:any) =>{
        dispatch(setGlobalSelectItem({
            ...selectItem,
            item: {
                ...selectItem.item,
                text: val,
            }
        }))
    }
    
    return (
        <Wrap className='no-item-drag' ref={elementRef} toolbarHeight={toolbarHeight} style={{...selectItem?.style[breakpoints ?? 'lg']}}>
            <QuillToolbar />
            <ReactQuill 
                modules={{...modules,toolbar:{container:'#toolbar'}}}
                // modules={modules}
                value={selectItem?.item?.text ?? ''}
                onChange={contentChange}
            />
            <BtnWrap>
                <BtnItem onClick={save}>저장</BtnItem>
                <BtnItem onClick={cancel}>취소</BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{toolbarHeight?:number}>`
    width: 100%;
    height: 100%;
    overflow: hidden;
    & .ql-toolbar.ql-snow .ql-formats{
        margin-right: 8px;
    }
    & #toolbar{
        position: absolute;
        top: ${({toolbarHeight}) => -(toolbarHeight ?? 0)}px;
        width: 100%;
        left: 0;
        z-index: 10;
        background-color: #fff;
    }
    & .quill{
        overflow: hidden;
        max-height: 100%;
        height: 100%;
        border: none;
    }
    & .ql-editor{
        padding: 5px;
    }
`;

const BtnWrap = styled(RowBetween)`
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    margin: 0 auto;
    gap: 15px;
`;

const BtnItem = styled(Center)`
    width: 46%;
    height: 42px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #333;
`;
