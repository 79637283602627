import React from 'react'
import styled from 'styled-components';
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';
import { Center } from '../../../style/basic/commonStyle';

export default function TextItem({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    
    return (
        <Wrap>
            {selectItem?.item?.text === undefined || selectItem?.item?.text === '' ?
                <Center style={{width:'100%',height:'100%'}}>text</Center> :
                <p className='ql-editor' dangerouslySetInnerHTML={{__html:selectItem?.item?.text}} style={{width: '100%',height: '100%', overflow: 'hidden',...selectItem?.style[breakpoints ?? 'lg']}}/>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    & .ql-editor{
        padding: 5px;
    }
`;