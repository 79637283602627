import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import { useParams } from 'react-router';
import { apiGetTempView } from '../../../api/api_temp';
import LayoutPart from '../../home/part/LayoutPart';

export default function ViewPage() {
    const {code} = useParams();
    const [itemList,setItemList] = useState<any>();
    const [layouts,setLayouts] = useState<any>({
        breakpoints: 'lg',
        layouts: {},
        new: 1,
    })

    useEffect(()=>{
        if(code === undefined || code === '') {
            alert('잘못된 접근입니다.');
            return
        };
        getDetail();
    },[code])

    // 템플릿 가져오기
    const getDetail = async() =>{
        const res = await apiGetTempView(code);
        if(res.result){
            const tempData = res.data.template_data;
            setLayouts((obj:any)=>({...obj,layouts:tempData.layouts.layouts}));
            setItemList(tempData.itemList);
        }else{
            alert(res.error);
        }
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <LayoutPart
                    layouts={layouts} 
                    setLayouts={setLayouts}
                    itemList={itemList}
                    setItemList={setItemList}
                    type='view'
                />
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;