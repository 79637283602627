import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { fileTypeRes, inputResNumber } from '../../../utils/format/string';
import { LIST_ITEM } from '../../list/page/listData';
import useAction from '../../../utils/custom-hooks/common/useAction';
import Player02SettingItemPart from './Player02SettingItemPart';
import CustomTabPanelPart from '../../list/part/CustomTabPanelPart';

export default function Player02SettingPart({selectItem,setSelectItem,breakpoints}:any) {
    const fileRef = useRef<any>(null);
    const [tab,setTab] = useState(0);
    const {imgGetUrl} = useAction();
    const [item,setItem] = useState<any>({
        fileType: '1',
        linkUrl: '',
        fileData: '',
        fileName: '',
    });

    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setSelectItem('item','newList',[LIST_ITEM]);
        }
    },[selectItem?.item?.list]);

    useEffect(()=>{
        let val = '';
        if(item.fileType === '1' ){
            val = item.linkUrl === '' ? '' : `${item.linkUrl}`;
        }else if(item.fileType === '2'){
            val = item.fileData === '' ? '' : `${item.fileData}`;
        }
        if(val === '' || val === undefined) return;
        setSelectItem('item','img',val);
    },[item.fileType,item.linkUrl,item.fileData,])

    // add
    const tabClick = (e:any,newValue:any) =>{
        const txt = e.target.textContent;
        if(txt === '+'){
            setSelectItem('item','list',LIST_ITEM);
            return;
        }
        setTab(newValue)
    }

    const delClick = (idx:any) =>{
        const newList = selectItem?.item?.list.filter((data:any,ind:any)=>ind !== idx);
        setSelectItem('item','newList',newList);
    }

    const listOptionChange = (e:any,title:string,type?:string) =>{
        let val = e.target.value;
        const name = e.target.name;
        if(type === 'number'){
            val = Number(inputResNumber(e));
        }
        setSelectItem(title,name,val);
    }

    // 이미지 타입 변경
    const fileSelectTypeChange = (e:any) =>{
        const val = e.target.value
        setItem((obj:any)=>({
            ...obj,fileType: val
        }))
    }

    // 이미지 링크로 입력
    const urlLinkImgChange = (e:any) =>{
        const val = e.target.value;
        setItem((obj:any)=>({
            ...obj,
            fileType: '1',
            linkUrl: val, 
        }))
    }

    // 이미지 파일 선택
    const fileImgChange = (e:any) =>{
        const file = e.target.files[0];
        if(file === undefined) return;
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg' || fileType === 'gif'){
            if (file.size > 10 * 1024 * 1024) {
                alert('파일의 용량이 10MB를 초과하였습니다.')
                fileRef.current.value = null;
            }else{
                const reader = new FileReader();
                reader.onloadend = async() => {
                    const img = await imgGetUrl(reader.result);
                    if(img.file_path === '') return;
                    setItem((obj:any)=>({
                        ...obj,
                        file: file,
                        fileType: '2',
                        fileData: img.file_path, 
                        fileName: file.name
                    }))
                };
                reader.readAsDataURL(file);
            }
        }else{
            alert('파일은 이미지 확장자인 png, jpg, gif 만 가능합니다.');
            fileRef.current.value = null;
        }
    }
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>player02</BasicText>
                    <Row style={{flexWrap:'wrap'}}>
                        {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                            <></>:
                            selectItem?.item?.list.map((_:any,idx:any)=>{
                                return(
                                    <div onClick={(e:any)=>tabClick(e,idx)} style={{width:'20%', color: idx === tab ? 'red':'#000'}} key={`item-tab-${idx}`}>{idx === 0 ? '메인' : `서브${idx}`}</div>
                                )
                            })
                        }
                        <div onClick={(e:any)=>tabClick(e,-1)} style={{width:'20%'}}>+</div>
                    </Row>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></> :
                        selectItem?.item?.list.map((data:any,idx:any)=>{
                            return(
                                <CustomTabPanelPart key={`list-${idx}`} value={tab} index={idx}>
                                    <Player02SettingItemPart selectItem={selectItem} setSelectItem={setSelectItem} breakpoints={breakpoints} index={idx} data={data} />                             
                                    {idx !== 0 &&
                                        <BtnWrap>
                                            <BtnItem onClick={()=>delClick(idx)}>삭제</BtnItem>
                                        </BtnWrap>
                                    }
                                </CustomTabPanelPart>
                            )
                        })
                    }
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText>리스트 스타일</BasicText>
                    <ItemWrap pw={'30%'}>
                        <BasicText>paddingTop</BasicText>
                        <input type='text' name='paddingTop' value={selectItem?.item?.listStyle?.paddingTop ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'listStyle','number')}></input>px
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>paddingRight</BasicText>
                        <input type='text' name='paddingRight' value={selectItem?.item?.listStyle?.paddingRight ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'listStyle','number')}></input>px
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>paddingLeft</BasicText>
                        <input type='text' name='paddingLeft' value={selectItem?.item?.listStyle?.paddingLeft ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'listStyle','number')}></input>px
                    </ItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText>이미지 선택</BasicText>
                    <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" name="img-type" value='1' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item?.fileType === '1'}/>
                            url링크
                        </label>
                        <input type='text' name='img-url' value={item?.linkUrl ?? ''} onChange={urlLinkImgChange}></input>
                    </ItemWrap>
                    <ItemWrap direction='column' align={'start'}>
                        <label>
                            <input type="radio" name="img-type" value='2' style={{marginRight:'6px'}} onChange={fileSelectTypeChange} checked={item?.fileType === '2'}/>
                            파일선택
                        </label>
                        <input ref={fileRef} name='img-file' type='file' accept='.png,.jpg,.gif' onChange={fileImgChange}></input>
                    </ItemWrap>
                    <BasicText>이미지 스타일</BasicText>
                    <ItemWrap pw={'30%'}>
                        <BasicText>size</BasicText>
                        <input type='text' name='width' value={selectItem?.item?.imgStyle?.width ?? ''} placeholder='100' onChange={(e:any)=>listOptionChange(e,'imgStyle','number')}></input>%
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>borderRadius</BasicText>
                        <input type='text' name='borderRadius' value={selectItem?.item?.imgStyle?.borderRadius ?? ''} placeholder='0' onChange={(e:any)=>listOptionChange(e,'imgStyle','number')}></input>px
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>align</BasicText>
                        <input type='text' name='justifyContent' value={selectItem?.item?.imgStyle?.justifyContent ?? ''} placeholder='center' onChange={(e:any)=>listOptionChange(e,'imgStyle','string')}></input>
                    </ItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText>메인 title 스타일</BasicText>
                    <ItemWrap pw={'30%'}>
                        <BasicText>size</BasicText>
                        <input type='text' name='fontSize' value={selectItem?.item?.titleStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>listOptionChange(e,'titleStyle','number')}></input>px
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>weight</BasicText>
                        <input type='text' name='fontWeight' value={selectItem?.item?.titleStyle?.fontWeight ?? ''} placeholder='700' onChange={(e:any)=>listOptionChange(e,'titleStyle','number')}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>color</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.titleStyle?.color ?? '#000000'} onChange={(e:any)=>listOptionChange(e,'titleStyle')}></input>
                    </ItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText>메인 sub 스타일</BasicText>
                    <ItemWrap pw={'30%'}>
                        <BasicText>size</BasicText>
                        <input type='text' name='fontSize' value={selectItem?.item?.timeStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>listOptionChange(e,'timeStyle','number')}></input>px
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>weight</BasicText>
                        <input type='text' name='fontWeight' value={selectItem?.item?.timeStyle?.fontWeight ?? ''} placeholder='700' onChange={(e:any)=>listOptionChange(e,'timeStyle','number')}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>color</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.timeStyle?.color ?? '#000000'} onChange={(e:any)=>listOptionChange(e,'timeStyle')}></input>
                    </ItemWrap>
                </ContentItemWrap>
                <ContentItemWrap>
                    <BasicText>서브 title 스타일</BasicText>
                    <ItemWrap pw={'30%'}>
                        <BasicText>size</BasicText>
                        <input type='text' name='fontSize' value={selectItem?.item?.subTitleStyle?.fontSize ?? ''} placeholder='16' onChange={(e:any)=>listOptionChange(e,'subTitleStyle','number')}></input>px
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>weight</BasicText>
                        <input type='text' name='fontWeight' value={selectItem?.item?.subTitleStyle?.fontWeight ?? ''} placeholder='700' onChange={(e:any)=>listOptionChange(e,'subTitleStyle','number')}></input>
                    </ItemWrap>
                    <ItemWrap pw={'30%'}>
                        <BasicText>color</BasicText>
                        <input type='color' name='color' value={selectItem?.item?.subTitleStyle?.color ?? '#000000'} onChange={(e:any)=>listOptionChange(e,'subTitleStyle')}></input>
                    </ItemWrap>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${addTheme.gap}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;

const BtnWrap = styled(Center)`
    gap: 15px;
`;

const BtnItem = styled(Center)`
    width: 46%;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
`;