import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle'
import { FaPlus, FaMinus } from "react-icons/fa";

const ListItem = ({data,index,list,setSelectItem}:any) =>{
    const [url,setUrl] = useState('');
    useEffect(()=>{
        setUrl(data.url);
    },[data.url]);

    const change = (e:any)=>{
        const val = e.target.value;
        let code = ''
        if(val.includes('https://')){
            try {
                const urlObj = new URL(val);
                code = urlObj.searchParams.get('v') ?? '';
            } catch (error) {
                
            }
        }
        const newItem = {title: '', url: val, artwork: '', code: code};
        const newList = list.map((item:any,idx:any)=> idx === index ? newItem : item);
        setSelectItem('item','newList',newList)
        // setSelectItem((obj:any)=>({...obj,playerPlayList:obj.playerPlayList.map((item:any,idx:any)=> idx === index ? newItem : item)}))
    }
    
    const delClick = () =>{
        
    }

    return(
        <ItemWrap>
            <NumWrap>{index+1}</NumWrap>
            <input placeholder='url' value={url} onChange={change}></input>
            {index>0 &&
                <DelWrap onClick={delClick}>
                    <FaMinus color='red' />
                </DelWrap>
            }
        </ItemWrap>
    )
}

export default function PlayerPlayListItem({selectItem,setSelectItem}:any) {
    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            // setSelectItem((obj:any)=>({...obj,playerPlayList: [{title: '', url: '', artwork: '', code: ''}]}))
            setSelectItem('item','list',{title: '', url: '', artwork: '', code: ''})
        }
    },[selectItem?.item?.list]);

    const plusClick = () =>{
        // setSelectItem((obj:any)=>({...obj,playerPlayList: [...obj.playerPlayList,{title: '', url: '', artwork: '', code: ''}]}))
        setSelectItem('item','list',{title: '', url: '', artwork: '', code: ''})
    }
    
    return (
        <Wrap>
            {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                <></> :
                selectItem?.item?.list.map((data:any,index:any)=>{
                    return(
                        <ListItem key={`player-list-${index}`} data={data} index={index} list={selectItem?.item?.list} setSelectItem={setSelectItem} />
                    )
                })
            }
            <PlusWrap>
                <FaPlus onClick={plusClick} />
            </PlusWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
`;

const ItemWrap = styled(Row)`
    gap: 8px;
    position: relative;
    & input{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;
const NumWrap = styled(Center)`
    flex-shrink: 0;
    width: 10%;
`;

const PlusWrap = styled(Center)`
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 8px;
`;
const DelWrap = styled(Center)`
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 6px;
`;