import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import FormSettingItem from '../item/FormSettingItem';
import { FaPlus, FaMinus } from "react-icons/fa";

export default function FormSettingPart({selectItem,setSelectItem,breakpoints}:any) {
    
    useEffect(()=>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0){
            setSelectItem('item','list',{id:1,type: 'text', title: '', list: [], val: undefined});
        }
    },[selectItem?.item?.list]);

    const plusClick = () =>{
        const last = selectItem?.item?.list[selectItem?.item?.list.length-1].id + 1;
        const item = {id: last, type: 'text', title: '', list: []}
        setSelectItem('item','list',item);
    }
    const minusClick = () =>{
        if(selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0) return;
        const last = selectItem?.item?.list[selectItem?.item?.list.length-1].id;
        const newList = selectItem?.item?.list.filter((data:any)=> data.id !== last);
        setSelectItem('item','newList',newList);
    }
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <BasicText fontSize={18} fontWeight={700}>Form</BasicText>
                    {selectItem?.item?.list === undefined || selectItem?.item?.list.length === 0 ?
                        <></> :
                        selectItem?.item?.list.map((data:any,index:any)=>{
                            return(
                                <FormSettingItem key={`formSetting-item-${index}`} allList={selectItem?.item?.list} data={data} index={index} setSelectItem={setSelectItem} />
                            )
                        })
                    }
                    <Center style={{width: '100%', gap: '6px'}}>
                        <PlusWrap onClick={plusClick} >
                            <FaPlus />
                        </PlusWrap>
                        {selectItem?.item?.list?.length > 1 &&
                            <PlusWrap>
                                <FaMinus onClick={minusClick} />
                            </PlusWrap>
                        }
                    </Center>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    width: 100%;
`;

const ContentWrap = styled(Row)`
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: ${addTheme.gap}px;
`;

const ContentItemWrap = styled(Row)`
    /* width: 100%; */
    flex-direction: column;
    gap: 15px;
    align-items: start;
    padding: 5px;
`;

const ItemWrap = styled(Row)<{direction?:string,align?:string,pw?:string}>`
    flex-direction: ${({direction}) => direction ?? 'row'};
    align-items: ${({align}) => align ?? 'center'};
    gap: 8px;
    & input[type="text"] {
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
    &>p{
        flex-shrink: 0;
        width: ${({pw}) => pw ?? 'auto'};
    }
    &>textarea{
        border: 1px solid #333;
        padding: 4px 12px;
        border-radius: 4px;
        width: 100%;
    }
`;

const PlusWrap = styled(Center)`
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 8px;
`;