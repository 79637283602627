import React from 'react'
import styled from 'styled-components'
import { useGlobalSelectItem } from '../../../utils/custom-hooks/common/useGlobalSelectItem';

export default function MapPage({item,i,parent,breakpoints}:any) {
    const globalSelectItem = useGlobalSelectItem();
    const selectItem = globalSelectItem.i === '' ? item : (globalSelectItem.i === i && globalSelectItem.parent === parent) ? globalSelectItem : item;
    const map = selectItem?.item?.map ?? '';
    const mapChk = map.includes('<iframe src="https://www.google.com/maps');
    const newMap = mapChk ? map.replace(/\s*width=['"][^'"]*['"]/, '').replace(/\s*height=['"][^'"]*['"]/, '') : '';
    
    return (
        <Wrap dangerouslySetInnerHTML={{ __html: newMap }}></Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    & iframe{
        width: 100%;
        height: 100%;
    }
`;

