import axios from 'axios';

export const axiosConfigInstance = (path: string,token?:string,type?:any) => axios.create({
    // baseURL: `https://music.dapi.subox.co.kr${path}`,
    // baseURL: `http://192.168.11.11:1231${path}`,
    baseURL: `https://atoboard.subox.co.kr${path}`,
    timeout: 50000,
    headers: {
        'authorization':token,
        ...type
    }
});

export const IMG_URL = '';
// export const IMG_URL = 'https://music.subox.co.kr/platform';

// "homepage": "https://music.subox.co.kr/platform",